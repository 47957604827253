import {
    CREATE_UAS_POST_REQUEST,
    CREATE_UAS_POST_SUCCESS,
    CREATE_UAS_POST_FAILURE,
    CREATE_UAS_POST_RESET,
    UPDATE_UAS_POST_REQUEST,
    UPDATE_UAS_POST_SUCCESS,
    UPDATE_UAS_POST_FAILURE,
    UPDATE_UAS_POST_RESET,
    DELETE_UAS_POST_REQUEST,
    DELETE_UAS_POST_SUCCESS,
    DELETE_UAS_POST_FAILURE,
    DELETE_UAS_POST_RESET,
    FETCH_ALL_UAS_POSTS_RESET,
    FETCH_ALL_UAS_POSTS_REQUEST,
    FETCH_ALL_UAS_POSTS_FAILURE,
    FETCH_ALL_UAS_POSTS_SUCCESS,
    CREATE_UAS_COMMENT_REQUEST,
    CREATE_UAS_COMMENT_SUCCESS,
    CREATE_UAS_COMMENT_FAILURE,
    CREATE_UAS_COMMENT_RESET,
    UPDATE_UAS_COMMENT_REQUEST,
    UPDATE_UAS_COMMENT_SUCCESS,
    UPDATE_UAS_COMMENT_FAILURE,
    UPDATE_UAS_COMMENT_RESET,
    DELETE_UAS_COMMENT_REQUEST,
    DELETE_UAS_COMMENT_SUCCESS,
    DELETE_UAS_COMMENT_FAILURE,
    DELETE_UAS_COMMENT_RESET,
    CREATE_UAS_REPLY_REQUEST,
    CREATE_UAS_REPLY_SUCCESS,
    CREATE_UAS_REPLY_FAILURE,
    CREATE_UAS_REPLY_RESET,
    UPDATE_UAS_REPLY_REQUEST,
    UPDATE_UAS_REPLY_SUCCESS,
    UPDATE_UAS_REPLY_FAILURE,
    UPDATE_UAS_REPLY_RESET,
    DELETE_UAS_REPLY_REQUEST,
    DELETE_UAS_REPLY_SUCCESS,
    DELETE_UAS_REPLY_FAILURE,
    DELETE_UAS_REPLY_RESET,
    CREATE_UAS_LIKEPOST_REQUEST,
    CREATE_UAS_LIKEPOST_SUCCESS,
    CREATE_UAS_LIKEPOST_FAILURE,
    CREATE_UAS_LIKEPOST_RESET,
    CREATE_UAS_SAVEPOST_REQUEST,
    CREATE_UAS_SAVEPOST_SUCCESS,
    CREATE_UAS_SAVEPOST_FAILURE,
    CREATE_UAS_SAVEPOST_RESET,
    CREATE_UAS_LIKECOMMENT_REQUEST,
    CREATE_UAS_LIKECOMMENT_SUCCESS,
    CREATE_UAS_LIKECOMMENT_FAILURE,
    CREATE_UAS_LIKECOMMENT_RESET,
    FETCH_UAS_LIKEDPOST_RESET,
    FETCH_UAS_LIKEDPOST_REQUEST,
    FETCH_UAS_LIKEDPOST_FAILURE,
    FETCH_UAS_LIKEDPOST_SUCCESS,
  } from "./UasType"

  const initialCreateUasPostState = {
    createcommunityuaspost : [],
    loading: false,
  };

  export const createUasPostReducer = (
    state = initialCreateUasPostState,
    action
  ) => {
    switch (action.type) {
        case CREATE_UAS_POST_RESET:
            return {
                loading: false,
                createcommunityuaspost: [],
            };
        case CREATE_UAS_POST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_UAS_POST_SUCCESS:
            return {
                loading: false,
                createcommunityuaspost: [action.payload],
            };
        case CREATE_UAS_POST_FAILURE:
            return {
                loading: false,
                createcommunityuaspost: [],
                error: action.payload,
            };
        default:
            return state;
    }
  };

  const initialUpdateCommunityUasPostState = {
    updatecommunityuaspost: [],
    loading: false,
  };

  export const updateCommunityUasPostReducer = (
    state = initialUpdateCommunityUasPostState,
    action
  ) => {
    switch (action.type) {
        case UPDATE_UAS_POST_RESET:
            return {
                loading: false,
                updatecommunityuaspost: [],
            };
        case UPDATE_UAS_POST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_UAS_POST_SUCCESS:
            return {
                loading: false,
                updatecommunityuaspost: [action.payload],
            };
        case UPDATE_UAS_POST_FAILURE:
            return {
                loading: false,
                updatecommunityuaspost: [],
                error: action.payload
            };
        default:
            return state;
    }
  };

  const initialDeleteCommunityUasPostState = {
    deletecommunityuaspost: [],
    loading: false,
  };

  export const deleteCommunityUasPostReducer = (
    state = initialDeleteCommunityUasPostState,
    action
  ) => {
    switch (action.type) {
        case DELETE_UAS_POST_RESET:
            return {
                loading: false,
                deletecommunityuaspost: [],
            };
        case DELETE_UAS_POST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_UAS_POST_SUCCESS:
            return {
                loading: false,
                deletecommunityuaspost: [action.payload],
            };
        case DELETE_UAS_POST_FAILURE:
            return {
                loading: false,
                deletecommunityuaspost: [],
                error: action.payload,
            };
        default:
            return state;
    }
  };

  const initialCreateUasCommentState = {
    createcommunityuascomment: [],
    loading: false,
  };

  export const createCommunityUasCommentReducer = (
    state = initialCreateUasCommentState,
    action
  ) => {
    switch (action.type) {
        case CREATE_UAS_COMMENT_RESET:
            return {
                loading: false,
                createcommunityuascomment: [],
            };
        case CREATE_UAS_COMMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_UAS_COMMENT_SUCCESS:
            return {
                loading: false,
                createcommunityuascomment: [action.payload],
            };
        case CREATE_UAS_COMMENT_FAILURE:
            return {
                loading: false,
                createcommunityuascomment: [],
                error: action.payload,
            };
        default:
            return state;
    }
  };

  const initialUpdateUasCommentState = {
    updatecommunityuascomment: [],
    loading: false,
  };

  export const updateCommunityUasCommentReducer = (
    state = initialUpdateUasCommentState,
    action
  ) => {
    switch (action.type) {
        case UPDATE_UAS_COMMENT_RESET:
            return {
                loading: false,
                updatecommunityuascomment: [],
            };
        case UPDATE_UAS_COMMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_UAS_COMMENT_SUCCESS:
            return {
                loading: false,
                updatecommunityuascomment: [action.payload],
            };
        case UPDATE_UAS_COMMENT_FAILURE:
            return {
                loading: false,
                updatecommunityuascomment: [],
                error: action.payload,
            };
        default:
            return state;
    }
  };

  const initialDeleteUasCommentState = {
    deletecommunityuascomment: [],
    loading: false,
  };

  export const deleteCommunityUasCommentReducer = (
    state = initialDeleteUasCommentState,
    action
  ) => {
    switch (action.type) {
        case DELETE_UAS_COMMENT_RESET:
            return {
                loading: false,
                deletecommunityuascomment: [],
            };
        case DELETE_UAS_COMMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_UAS_COMMENT_SUCCESS:
            return {
                loading: false,
                deletecommunityuascomment: [action.payload],
            };
        case DELETE_UAS_COMMENT_FAILURE:
            return {
                loading: false,
                deletecommunityuascomment: [],
                error: action.payload,
            };
        default:
            return state;
    }
  };

  const initialCreateUasReplyState = {
    createcommunityuasreply: [],
    loading: false,
  };
  
  export const createCommunityUasReplyReducer = (
    state = initialCreateUasReplyState,
    action
  ) => {
    switch (action.type) {
      case CREATE_UAS_REPLY_RESET:
        return {
          loading: false,
          createcommunityuasreply: [],
        };
      case CREATE_UAS_REPLY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case CREATE_UAS_REPLY_SUCCESS:
        return {
          loading: false,
          createcommunityuasreply: [action.payload],
        };
      case CREATE_UAS_REPLY_FAILURE:
        return {
          loading: false,
          createcommunityuasreply: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };

  const initialUpdateCommunityUasReplyState = {
    updatecommunityuasreply: [],
    loading: false,
  };
  
  export const updateCommunityUasReplyReducer = (
    state = initialUpdateCommunityUasReplyState,
    action
  ) => {
    switch (action.type) {
      case UPDATE_UAS_REPLY_RESET:
        return {
          loading: false,
          updatecommunityuasreply: [],
        };
      case UPDATE_UAS_REPLY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_UAS_REPLY_SUCCESS:
        return {
          loading: false,
          updatecommunityuasreply: [action.payload],
        };
      case UPDATE_UAS_REPLY_FAILURE:
        return {
          loading: false,
          updatecommunityuasreply: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };

  const initialDeleteCommunityUasReplyState = {
    deletecommunityuasreply: [],
    loading: false,
  };
  
  export const deleteCommunityUasReplyReducer = (
    state = initialDeleteCommunityUasReplyState,
    action
  ) => {
    switch (action.type) {
      case DELETE_UAS_REPLY_RESET:
        return {
          loading: false,
          deletecommunityuasreply: [],
        };
      case DELETE_UAS_REPLY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case DELETE_UAS_REPLY_SUCCESS:
        return {
          loading: false,
          deletecommunityuasreply: [action.payload],
        };
      case DELETE_UAS_REPLY_FAILURE:
        return {
          loading: false,
          deletecommunityuasreply: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };

  const initialCreateUasLikePostState = {
    createcommunityuaslikepost: [],
    loading: false,
  };

  export const createUasLikePostReducer = (
    state = initialCreateUasLikePostState,
    action
  ) => {
    switch (action.type) {
      case CREATE_UAS_LIKEPOST_RESET:
        return {
          loading: false,
          createcommunityuaslikepost: [],
        };
      case CREATE_UAS_LIKEPOST_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case CREATE_UAS_LIKEPOST_SUCCESS:
        return {
          loading: false,
          createcommunityuaslikepost: [action.payload],
        };
      case CREATE_UAS_LIKEPOST_FAILURE:
        return {
          loading: false,
          createcommunityuaslikepost: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };

  const initialCreateUasSavePostState = {
    createcommunitysavepost: [],
    loading: false,
  };
  
  export const createCommunityUasSavePostReducer = (
    state = initialCreateUasSavePostState,
    action
  ) => {
    switch (action.type) {
      case CREATE_UAS_SAVEPOST_RESET:
        return {
          loading: false,
          createcommunityuassavepost: [],
        };
      case CREATE_UAS_SAVEPOST_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case CREATE_UAS_SAVEPOST_SUCCESS:
        return {
          loading: false,
          createcommunityuassavepost: [action.payload],
        };
      case CREATE_UAS_SAVEPOST_FAILURE:
        return {
          loading: false,
          createcommunityuassavepost: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };