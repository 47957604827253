import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import AuthLayout from 'layouts/Auth'

const  AuthRoute = ({component:Component, authenticated, admin, ...rest}) => {
    
    return (
        <Route {...rest}
            render = {(props) => {
                
                if (authenticated === true ){
                    if (admin === true ){
                        
                        return <Redirect to = "/admin/dashboard"/> 
                    } else {
                        
                        // return <Redirect to = "/client/dashboard"/>
                         return <Redirect to = "/client/community-free-members"/>
                    }
                } else {
                    return <AuthLayout><Component {...props}/></AuthLayout>
                }
            }
            }
        />
)}
const mapStateToProps = (state) => ({
    authenticated: state.loginAuth.authenticated,
    admin: state.loginAuth.admin
})

export default connect(mapStateToProps)(AuthRoute)
