import React, { useEffect, useState } from 'react';
import { useStripe, PaymentElement, useElements } from '@stripe/react-stripe-js';
import BillingDetailsForm from './BillingDetailsForm';
import { createCustomer, createCustomerReset, createSubscription, createSubscriptionReset } from 'redux/Payments/PaymentsActions';
import { connect, useDispatch } from 'react-redux';

import { signupUser,signupUserReset } from "redux/UserAuthentication/RegisterActions";
import { loginUser } from "redux/UserAuthentication/LoginActions";

import Swal from 'sweetalert2';

import {
    Card,
    Container,
    Row,
    Col,
    CardBody,
    CardHeader,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input
} from "reactstrap";

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchDetailsSubscription } from 'redux/Payments/PaymentsActions';
import {useForm} from 'react-hook-form';

const HomeSubscriptionForm = (props) => {
    const history = useHistory()
    const [plan , setPlan] = useState('premedTrackerProPlanMonthly35')

    const options = {
        layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: false,
            spacedAccordionItems: true,
            
        },
        terms : {
            card:"never"
        },
        fields :{
            billingDetails :{
                name:"never"
            }
        }
    }

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    

    const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();

    const messageRequired = "This field is required.";

    useEffect(() => {

        register("inputUsername", { required: messageRequired
        });

        register("inputPassword", { 
            required: messageRequired,
            pattern: {
                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                // value : "^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$",
                    message: "Minimum eight characters, at least one letter, one number and one special character."
                } ,
        
        });

        register("inputEmail", { 
            required: messageRequired, 
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address."
            } 
        });  

    }, [])


    useEffect(() => {

        if(props.signupUser.loading){
            Swal.fire({
              allowOutsideClick : false,
              didOpen: () => {
                Swal.showLoading()
                
              }
            });
        
          }
          else{
            if(props.signupUser.signupuser.length && typeof props.signupUser.error === "undefined"){
                Swal.fire({
                    allowOutsideClick : false,
                    didOpen: () => {
                      Swal.showLoading()
                      
                    }
                });
                // Swal.fire({
                //     title: 'Success!',
                //     text: 'Registered successfully!',
                //     icon : 'success',
                //     timer: 3000
                    
                //     }
                // ).then(function (result) {

                    

                    

                    
                // });
                dispatch(loginUser({email : getValues('inputEmail'), password : getValues('inputPassword')}));
                dispatch(signupUserReset());
            }
            else if(typeof props.signupUser.error !== "undefined"){
        
                if(props.signupUser.error.hasOwnProperty('email') || props.signupUser.error.hasOwnProperty('handle')){
                    Swal.fire({
                    title:'Failed!',
                    text:'Email is already in use!',
                    icon :'error',
                    timer: 3000
                    
                    }) 
                }
                else if(props.signupUser.error.hasOwnProperty('general')){
                    Swal.fire({
                    title:'Failed!',
                    text:'Something went wrong, Please try again!',
                    icon :'error',
                    timer: 3000
                    
                    }) 
                }
                else{
                    Swal.fire({
                    title:'Failed!',
                    text:'Something went wrong, Please try again!',
                    icon :'error',
                    timer: 3000
                    
                    })
                }   

                setLoading(false)
                dispatch(signupUserReset());       
            }
        }

    }, [props.signupUser])
    


    const handleError = (error) => {
        setLoading(false);
        
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
  
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
  
        return [year, month, day].join('-');
    }
    
    const [cardDetails , setCardDetails] = useState({});
    const onSubmit = async (data) => {
        
        // event.preventDefault();
        
        setLoading(true);
        
        if (!stripe) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // setLoading(true);

        let addressElement = elements.getElement('address');

        addressElement.getValue()
            .then(function(result) {
                if (result.complete) {
                    // Allow user to proceed to the next step
                    // Optionally, use value to store the address details
                    let details = result.value;
                    // details.email = props.userDetails.email;

                    const formData = new FormData();
                    formData.append('name', details.name)
                    formData.append('addedUniversity', "")
                    formData.append('university', "")
                    formData.append('email', data.inputEmail)
                    formData.append('password', data.inputPassword)
                    formData.append('confirmPassword', data.inputPassword)
                    formData.append('handle', data.inputUsername)
                    formData.append('tpcEntryDate', formatDate(new Date()))
                    formData.append('subscriptions', [])
                    formData.append('tutorHours', 5)
                    formData.append('phoneNumber', details.phone)
                    formData.append('admin', false)
                    
                    details.email = data.inputEmail;
                    details.type = "subscription"

                    setCardDetails(details)
                    dispatch(signupUser(formData));
                    // dispatch(createCustomer(details))
                    
                }
            }
        )

        

        // // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();

        if (submitError) {
            handleError(submitError);
            setLoading(false);
            return;
        }
        
    }
    

    useEffect(() => {
        if(props.createCustomer.createcustomer.length){
            
            dispatch(createSubscription(
                    {
                        customerId : props.createCustomer.createcustomer[0].customerId , 
                        priceId : plan,
                        email : getValues('inputEmail'),
                        type: "subscription"
                    }
                )
            )
        }
    }, [props.createCustomer])

    useEffect(async () => {

        if(props.createSubscription.createsubscription.length){
            dispatch(createCustomerReset())

            // // Trigger form validation and wallet collection
            const {error: submitError} = await elements.submit();

            if (submitError) {
                handleError(submitError);
                setLoading(false);
                return;
            }

            const {type, clientSecret, subsId} = await props.createSubscription.createsubscription[0];
            const confirmIntent = type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

            // Confirm the Intent using the details collected by the Payment Element
            const {error} = await confirmIntent({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: 'https://www.premedtracker.io',
                },
                redirect : "if_required"
            });

            if (error) {
                // This point is only reached if there's an immediate error when confirming the Intent.
                // Show the error to your customer (for example, "payment details incomplete").
                handleError(error);
                setLoading(false);
                Swal.fire({
                    title:'Failed!',
                    text: error.message,
                    icon :'error',
                    timer: 3000
                    
                    }
                ).then((result) => {
                    
                    dispatch(createSubscriptionReset());
                    
                })

            } else {
                
                // Your customer is redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer is redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
                Swal.fire({
                    title:'Success!',
                    text:'Successfully subscribe to PRO!',
                    icon :'success',
                    timer: 3000
                    
                    }
                ).then((result) => {

                    

                    dispatch(fetchDetailsSubscription({ subsId : subsId }))

                    dispatch(createSubscriptionReset());

                    if (props.authenticated === true ){


                        let planSelected = "monthly";
            
                        if(plan == "premedTrackerProPlanYearly385"){
                            planSelected = "yearly"
                        }
                        else if(plan == "premedTrackerProPlanLifetime"){
                            planSelected = "lifetime"
                        }
            
                        history.push({pathname : '/client/congrats-' + planSelected, state : {plan : planSelected }})
            
                    }

                })
            }
        }

    }, [props.createSubscription])
    
    useEffect(() => {
        console.log("fucuk u")
        if (props.authenticated === true && loading == true){
            console.log(cardDetails)
            dispatch(createCustomer(cardDetails))

            // Swal.fire({
            //     allowOutsideClick : false,
            //     didOpen: () => {
            //       Swal.showLoading()
                  
            //     }
            // });
        }

    }, [props.authenticated])
    
   
    const handleSelectPlan = (e,plan) => {
        e.preventDefault();
        setPlan(plan)

    }
    return (
        
        <form onSubmit={handleSubmit(onSubmit)}>
            <p className='h1 text-dark text-center'>Subscription Form</p>
            <FormGroup className='text-left'>
                <label
                    className="form-control-label"
                >
                Email
                </label>
                <Input
                    placeholder="Email for account creation"
                    className="form-control-alternative"
                    id="inputEmail"
                    name="inputEmail"

                    style={errors.inputEmail ? { border: '2px solid red' } : {}}

                    onChange={e => {setValue("inputEmail", e.target.value); setValue("inputUsername", e.target.value)} }
                    type="email"
                />
                
              
                { errors.inputEmail && <label className="text-danger text-sm">{errors.inputEmail.message}</label> }
            </FormGroup>
            <FormGroup className='text-left'>
                <label
                    className="form-control-label"
                >
                Password
                </label>
                <Input
                    placeholder="Password"
                    className="form-control-alternative"
                    id="inputPassword"
                    name="inputPassword"

                    style={errors.inputPassword ? { border: '2px solid red' } : {}}

                    onChange={e => setValue("inputPassword", e.target.value) }
                    type="password"
                />

                { errors.inputPassword && <label className="text-danger text-sm">{errors.inputPassword.message}</label> }
            </FormGroup>

            <p className='h2 text-dark'>Plans</p>
            
            <Row>
                <Col md="12 mb-3">
                    <button className={plan == "premedTrackerProPlanMonthly35" ? 'btn btn-info w-100' : 'btn btn-secondary w-100' } onClick={e => handleSelectPlan(e,'premedTrackerProPlanMonthly35')}>Monthly Pro Plan: $35/month</button>
                </Col>
                <Col md="12 mb-3">
                    <button className={plan == "premedTrackerProPlanYearly385" ? 'btn btn-warning w-100' : 'btn btn-secondary w-100' } onClick={e => handleSelectPlan(e,'premedTrackerProPlanYearly385')}>Yearly Pro Plan: $385/year - 1 Month Free!</button>
                </Col>
                {/* <Col md="12">
                    <button className={plan == "premedTrackerProPlanLifetime" ? 'btn btn-danger w-100' : 'btn btn-secondary w-100' } onClick={e => handleSelectPlan(e,'premedTrackerProPlanLifetime')}>Lifetime Plan: $297 (One-time payment)</button>
                </Col> */}
            </Row>
            <br/>
            
            <p className='h2 text-dark'>Billing</p>
            <BillingDetailsForm/>
            <br/>
            <p className='h2 text-dark'>Payment</p>
            <PaymentElement options={options}  />
            <br/>
            <button className='btn btn-primary' disabled={!stripe || loading}>Subscribe</button>
            
            
        </form>
                        
    )
}

const mapStateToProps = state => {
    return {
        createCustomer : state.createCustomer,
        createSubscription : state.createSubscription,
        // userDetails : state.loginAuth.userDetails,
        signupUser : state.signupUser,
        authenticated: state.loginAuth.authenticated,

    }
}

export default connect(mapStateToProps)(HomeSubscriptionForm)