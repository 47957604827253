import {
    CREATE_UAS_POST_REQUEST,
    CREATE_UAS_POST_SUCCESS,
    CREATE_UAS_POST_FAILURE,
    CREATE_UAS_POST_RESET,
    UPDATE_UAS_POST_REQUEST,
    UPDATE_UAS_POST_SUCCESS,
    UPDATE_UAS_POST_FAILURE,
    UPDATE_UAS_POST_RESET,
    DELETE_UAS_POST_REQUEST,
    DELETE_UAS_POST_SUCCESS,
    DELETE_UAS_POST_FAILURE,
    DELETE_UAS_POST_RESET,
    FETCH_ALL_UAS_POSTS_RESET,
    FETCH_ALL_UAS_POSTS_REQUEST,
    FETCH_ALL_UAS_POSTS_FAILURE,
    FETCH_ALL_UAS_POSTS_SUCCESS,
    CREATE_UAS_COMMENT_REQUEST,
    CREATE_UAS_COMMENT_SUCCESS,
    CREATE_UAS_COMMENT_FAILURE,
    CREATE_UAS_COMMENT_RESET,
    UPDATE_UAS_COMMENT_REQUEST,
    UPDATE_UAS_COMMENT_SUCCESS,
    UPDATE_UAS_COMMENT_FAILURE,
    UPDATE_UAS_COMMENT_RESET,
    DELETE_UAS_COMMENT_REQUEST,
    DELETE_UAS_COMMENT_SUCCESS,
    DELETE_UAS_COMMENT_FAILURE,
    DELETE_UAS_COMMENT_RESET,
    CREATE_UAS_REPLY_REQUEST,
    CREATE_UAS_REPLY_SUCCESS,
    CREATE_UAS_REPLY_FAILURE,
    CREATE_UAS_REPLY_RESET,
    UPDATE_UAS_REPLY_REQUEST,
    UPDATE_UAS_REPLY_SUCCESS,
    UPDATE_UAS_REPLY_FAILURE,
    UPDATE_UAS_REPLY_RESET,
    DELETE_UAS_REPLY_REQUEST,
    DELETE_UAS_REPLY_SUCCESS,
    DELETE_UAS_REPLY_FAILURE,
    DELETE_UAS_REPLY_RESET,
    CREATE_UAS_LIKEPOST_REQUEST,
    CREATE_UAS_LIKEPOST_SUCCESS,
    CREATE_UAS_LIKEPOST_FAILURE,
    CREATE_UAS_LIKEPOST_RESET,
    CREATE_UAS_SAVEPOST_REQUEST,
    CREATE_UAS_SAVEPOST_SUCCESS,
    CREATE_UAS_SAVEPOST_FAILURE,
    CREATE_UAS_SAVEPOST_RESET,
    CREATE_UAS_LIKECOMMENT_REQUEST,
    CREATE_UAS_LIKECOMMENT_SUCCESS,
    CREATE_UAS_LIKECOMMENT_FAILURE,
    CREATE_UAS_LIKECOMMENT_RESET,
    FETCH_UAS_LIKEDPOST_RESET,
    FETCH_UAS_LIKEDPOST_REQUEST,
    FETCH_UAS_LIKEDPOST_FAILURE,
    FETCH_UAS_LIKEDPOST_SUCCESS,
  } from "./UasType"

  import axios from "axios";

  export const createCommunityUasPostReset = () => {
    return {
        type: CREATE_UAS_POST_RESET,
    };
  };

  const createCommunityUasPostRequest = () => {
    return {
      type: CREATE_UAS_POST_REQUEST,
    };
  };

  const createCommunityUasPostSuccess = (success) => {
    return {
      type: CREATE_UAS_POST_SUCCESS,
      payload: success,
    };
  };

  const createCommunityUasPostFailure = (error) => {
    return {
      type: CREATE_UAS_POST_FAILURE,
      payload: error,
    };
  };

  export const createCommunityUasPost = (data) => {
    return (dispatch) => {
      dispatch(createCommunityUasPostRequest());
      axios
      .post("/createCommunityUasPost", data)
      .then((response) => {
        const createCommunityUasPost = response.data;
        dispatch(createCommunityUasPostSuccess(createCommunityUasPost));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(createCommunityUasPostFailure(errorMsg));
      });
    };
  };

  export const updateCommunityUasPostReset = () => {
    return {
      type: UPDATE_UAS_POST_RESET,
    };
  };
  
  const updateCommunityUasPostRequest = () => {
    return {
      type: UPDATE_UAS_POST_REQUEST,
    };
  };
  
  const updateCommunityUasPostSuccess = (success) => {
    return {
      type: UPDATE_UAS_POST_SUCCESS,
      payload: success,
    };
  };
  
  const updateCommunityUasPostFailure = (error) => {
    return {
      type: UPDATE_UAS_POST_FAILURE,
      payload: error,
    };
  };
  
  export const updateCommunityUasPost = (data) => {
    return (dispatch) => {
      dispatch(updateCommunityUasPostRequest());
      axios
        .post("/updateCommunityUasPost", data)
        .then((response) => {
          const updateCommunityUasPost = response.data;
          dispatch(
            updateCommunityUasPostSuccess(updateCommunityUasPost)
          );
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(updateCommunityUasPostFailure(errorMsg));
        });
    };
  };
  
  export const deleteCommunityUasPostReset = () => {
    return {
      type: DELETE_UAS_POST_RESET,
    };
  };
  
  const deleteCommunityUasPostRequest = () => {
    return {
      type: DELETE_UAS_POST_REQUEST,
    };
  };
  
  const deleteCommunityUasPostSuccess = (success) => {
    return {
      type: DELETE_UAS_POST_SUCCESS,
      payload: success,
    };
  };
  
  const deleteCommunityUasPostFailure = (error) => {
    return {
      type: DELETE_UAS_POST_FAILURE,
      payload: error,
    };
  };
  
  export const deleteCommunityUasPost = (data) => {
    return (dispatch) => {
      dispatch(deleteCommunityUasPostRequest());
      axios
        .post("/deleteCommunityUasPost", data)
        .then((response) => {
          const deleteCommunityUasPost = response.data;
          dispatch(
            deleteCommunityUasPostSuccess(deleteCommunityUasPost)
          );
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(deleteCommunityUasPostFailure(errorMsg));
        });
    };
  };
  
  export const createCommunityUasLikePostReset = () => {
    return {
      type: CREATE_UAS_LIKEPOST_RESET,
    };
  };
  
  const createCommunityUasLikePostRequest = () => {
    return {
      type: CREATE_UAS_LIKEPOST_REQUEST,
    };
  };
  
  const createCommunityUasLikePostSuccess = (success) => {
    return {
      type: CREATE_UAS_LIKEPOST_SUCCESS,
      payload: success,
    };
  };
  
  const createCommunityUasLikePostFailure = (error) => {
    return {
      type: CREATE_UAS_LIKEPOST_FAILURE,
      payload: error,
    };
  };
  
  export const createCommunityUasLikePost = (data) => {
    return (dispatch) => {
      dispatch(createCommunityUasLikePostRequest());
      axios
        .post("/createCommunityUasLikePost", data)
        .then((response) => {
          const createCommunityUasLikePost = response.data;
          dispatch(
            createCommunityUasLikePostSuccess(
              createCommunityUasLikePost
            )
          );
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(createCommunityUasLikePostFailure(errorMsg));
        });
    };
  };
  
  export const createCommunityUasCommentReset = () => {
    return {
      type: CREATE_UAS_COMMENT_RESET,
    };
  };
  
  const createCommunityUasCommentRequest = () => {
    return {
      type: CREATE_UAS_COMMENT_REQUEST,
    };
  };
  
  const createCommunityUasCommentSuccess = (success) => {
    return {
      type: CREATE_UAS_COMMENT_SUCCESS,
      payload: success,
    };
  };
  
  const createCommunityUasCommentFailure = (error) => {
    return {
      type: CREATE_UAS_COMMENT_FAILURE,
      payload: error,
    };
  };
  
  export const createCommunityUasComment = (data) => {
    return (dispatch) => {
      dispatch(createCommunityUasCommentRequest());
  
      axios
        .post("/createCommunityUasComment", data)
        .then((response) => {
          const createCommunityUasComment = response.data;
          dispatch(
            createCommunityUasCommentSuccess(
              createCommunityUasComment
            )
          );
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(createCommunityUasCommentFailure(errorMsg));
        });
    };
  };
  
  export const updateCommunityUasCommentReset = () => {
    return {
      type: UPDATE_UAS_COMMENT_RESET,
    };
  };
  
  const updateCommunityUasCommentRequest = () => {
    return {
      type: UPDATE_UAS_COMMENT_REQUEST,
    };
  };
  
  const updateCommunityUasCommentSuccess = (success) => {
    return {
      type: UPDATE_UAS_COMMENT_SUCCESS,
      payload: success,
    };
  };
  
  const updateCommunityUasCommentFailure = (error) => {
    return {
      type: UPDATE_UAS_COMMENT_FAILURE,
      payload: error,
    };
  };
  
  export const updateCommunityUasComment = (data) => {
    return (dispatch) => {
      dispatch(updateCommunityUasCommentRequest());
  
      axios
        .post("/updateCommunityUasComment", data)
        .then((response) => {
          const updateCommunityUasComment = response.data;
          dispatch(
            updateCommunityUasCommentSuccess(
              updateCommunityUasComment
            )
          );
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(updateCommunityUasCommentFailure(errorMsg));
        });
    };
  };
  
  export const deleteCommunityUasCommentReset = () => {
    return {
      type: DELETE_UAS_COMMENT_RESET,
    };
  };
  
  const deleteCommunityUasCommentRequest = () => {
    return {
      type: DELETE_UAS_COMMENT_REQUEST,
    };
  };
  
  const deleteCommunityUasCommentSuccess = (success) => {
    return {
      type: DELETE_UAS_COMMENT_SUCCESS,
      payload: success,
    };
  };
  
  const deleteCommunityUasCommentFailure = (error) => {
    return {
      type: DELETE_UAS_COMMENT_FAILURE,
      payload: error,
    };
  };
  
  export const deleteCommunityUasComment = (data) => {
    return (dispatch) => {
      dispatch(deleteCommunityUasCommentRequest());
      axios
        .post("/deleteCommunityUasComment", data)
        .then((response) => {
          const deleteCommunityUasComment = response.data;
          dispatch(
            deleteCommunityUasCommentSuccess(
              deleteCommunityUasComment
            )
          );
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(deleteCommunityUasCommentFailure(errorMsg));
        });
    };
  };

  const createCommunityUasReplyReset = () => {
    return {
      type: CREATE_UAS_REPLY_RESET,
    };
  };
  
  const createCommunityUasReplyRequest = () => {
    return {
      type: CREATE_UAS_REPLY_REQUEST,
    };
  };
  
  const createCommunityUasReplySuccess = (success) => {
    return {
      type: CREATE_UAS_REPLY_SUCCESS,
      payload: success,
    };
  };
  
  const createCommunityUasReplyFailure = (error) => {
    return {
      type: CREATE_UAS_REPLY_FAILURE,
      payload: error,
    };
  };
  
  export const createCommunityUasReply = (data) => {
    return (dispatch) => {
      dispatch(createCommunityUasReplyRequest());
      axios
        .post("/createCommunityUasReply", data)
        .then((response) => {
          const createCommunityUasReply = response.data;
          dispatch(
            createCommunityUasReplySuccess(createCommunityUasReply)
          );
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(createCommunityUasReplyFailure(errorMsg));
        });
    };
  };
  export const updateCommunityUasReplyReset = () => {
    return {
      type: UPDATE_UAS_REPLY_RESET,
    };
  };
  
  const updateCommunityUasReplyRequest = () => {
    return {
      type: UPDATE_UAS_REPLY_REQUEST,
    };
  };
  
  const updateCommunityUasReplySuccess = (success) => {
    return {
      type: UPDATE_UAS_REPLY_SUCCESS,
      payload: success,
    };
  };
  
  const updateCommunityUasReplyFailure = (error) => {
    return {
      type: UPDATE_UAS_REPLY_FAILURE,
      payload: error,
    };
  };
  
  export const updateCommunityUasReply = (data) => {
    return (dispatch) => {
      dispatch(updateCommunityUasReplyRequest());
  
      axios
        .post("/updateCommunityUasReply", data)
        .then((response) => {
          const updateCommunityUasReply = response.data;
          dispatch(
            updateCommunityUasReplySuccess(updateCommunityUasReply)
          );
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(updateCommunityUasReplyFailure(errorMsg));
        });
    };
  };
  
  export const deleteCommunityUasReplyReset = () => {
    return {
      type: DELETE_UAS_REPLY_RESET,
    };
  };
  
  const deleteCommunityUasReplyRequest = () => {
    return {
      type: DELETE_UAS_REPLY_REQUEST,
    };
  };
  
  const deleteCommunityUasReplySuccess = (success) => {
    return {
      type: DELETE_UAS_REPLY_SUCCESS,
      payload: success,
    };
  };
  
  const deleteCommunityUasReplyFailure = (error) => {
    return {
      type: DELETE_UAS_REPLY_FAILURE,
      payload: error,
    };
  };
  
  export const deleteCommunityUasReply = (data) => {
    return (dispatch) => {
      dispatch(deleteCommunityUasReplyRequest());
      axios
        .post("/deleteCommunityUasReply", data)
        .then((response) => {
          const deleteCommunityUasReply = response.data;
          dispatch(
            deleteCommunityUasReplySuccess(deleteCommunityUasReply)
          );
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(deleteCommunityUasReplyFailure(errorMsg));
        });
    };
  };

  export const createCommunityUasSavePostReset = () => {
    return {
      type: CREATE_UAS_SAVEPOST_RESET,
    };
  };
  
  const createCommunityUasSavePostRequest = () => {
    return {
      type: CREATE_UAS_SAVEPOST_REQUEST,
    };
  };
  
  const createCommunityUasSavePostSuccess = (success) => {
    return {
      type: CREATE_UAS_SAVEPOST_SUCCESS,
      payload: success,
    };
  };
  
  const createCommunityUasSavePostFailure = (error) => {
    return {
      type: CREATE_UAS_SAVEPOST_FAILURE,
      payload: error,
    };
  };
  
  export const createCommunityUasSavePost = (data) => {
    return (dispatch) => {
      dispatch(createCommunityUasSavePostRequest());
      axios
        .post("/createCommunityUasSavePost", data)
        .then((response) => {
          const createCommunityUasSavePost = response.data;
          dispatch(createCommunityUasSavePostSuccess(createCommunityUasSavePost));
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(createCommunityUasSavePostFailure(errorMsg));
        });
    };
  };
