import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { Card, CardHeader, CardBody, Row } from 'reactstrap';
import { fetchCourse } from 'redux/Courses/CoursesActions';

const GpaTrend = (props) => {
    const dispatch = useDispatch();

    const [labels, setLabels] = useState([]);
    const [bcpmGpa, setBcpmGpa] = useState([]);
    const [aoGpa, setAoGpa] = useState([]);
    const [cumulativeGpa, setCumulativeGpa] = useState([]);

    useEffect(() => {
        const data = {
            route: "",
            userId: props.userDetails.userId,
            handle: props.userDetails.handle
        };
        dispatch(fetchCourse(data));
    }, [props.userDetails, dispatch]);

    useEffect(() => {
        if (props.fetchCourses.courses.length) {
            const { grades, terms } = getGradesByCoursesAndSection(props.fetchCourses.courses);

            setLabels(terms);
            setBcpmGpa(grades.bcpmGpa);
            setAoGpa(grades.aoGpa);
            setCumulativeGpa(grades.cumulativeGpa);
        }
    }, [props.fetchCourses.courses]);

    const getGradesByCoursesAndSection = (courses) => {
        const sections = ["freshman", "sophomore", "junior", "senior"];
        const terms = ["Fall", "Spring", "Winter", "Summer"];
        let labels = [];
        let grades = {
            bcpmGpa: [],
            aoGpa: [],
            cumulativeGpa: []
        };

        sections.forEach(section => {
            terms.forEach(term => {
                let termCourses = courses.filter(c => c.section === section && c.term === term);
                if ((term === "Fall" || term === "Spring") || (termCourses.length > 0)) {
                    labels.push(`${capitalize(section)} ${term}`);
                    let convertedCourses = convertCourses(termCourses);
                    let science = convertedCourses.filter(c => c.bcpm === true);
                    let other = convertedCourses.filter(c => c.bcpm === false);

                    let termScience = getTotalQualityPoints(science) / getTotalCredits(science) || null;
                    let termOther = getTotalQualityPoints(other) / getTotalCredits(other) || null;
                    let termTotal = getTotalQualityPoints(convertedCourses) / getTotalCredits(convertedCourses) || null;

                    grades.bcpmGpa.push(termScience != null ? parseFloat(termScience.toFixed(2)) : null);
                    grades.aoGpa.push(termOther != null ? parseFloat(termOther.toFixed(2)) : null);
                    grades.cumulativeGpa.push(termTotal != null ? parseFloat(termTotal.toFixed(2)) : null);
                }
            });
        });

        return { grades, terms: labels };
    };

    const capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const convertGPA = (grade) => {
        const conversion = {
            'A+': 4.0, 'A': 4.0, 'A-': 3.7, 'AB': 3.5, 'B+': 3.3, 'B': 3.0,
            'B-': 2.7, 'BC': 2.5, 'C+': 2.3, 'C': 2.0, 'C-': 1.7, 'CD': 1.5,
            'D+': 1.3, 'D': 1.0, 'D-': 0.7, 'DE': 0.5, 'F': 0.0
        };
        return conversion[grade];
    };

    const convertCredits = (credit) => {
        switch (credit) {
            case 0.5: return 0.3;
            case 1.0: return 0.7;
            case 1.5: return 1.0;
            case 2.0: return 1.3;
            case 2.5: return 1.7;
            case 3.0: return 2.0;
            case 3.5: return 2.3;
            case 4.0: return 2.7;
            case 4.5: return 3.0;
            case 5.0: return 3.3;
            case 6.0: return 4.0;
            case 7.0: return 4.7;
            case 8.0: return 5.3;
            case 9.0: return 6.0;
            case 10.0: return 6.7;
            case 12.0: return 8.0;
            case 15.0: return 10.0;
            case 20.0: return 13.3;
            default: return credit;
        }
    };

    const getTotalQualityPoints = (courses) => {
        return courses.reduce((accum, curr) => accum + curr.qualityPoints, 0);
    };

    const getTotalCredits = (courses) => {
        return courses.reduce((accum, curr) => accum + curr.credits, 0);
    };

    const convertCourses = (courses) => {
        const PFTW = ["Pass", "Fail", "Transfer", "Withdraw"];
        const coursesLessPFT = courses.filter(course => !PFTW.includes(course.grade));
        return coursesLessPFT.map(course => {
            const credits = course.quarter ? convertCredits(parseFloat(course.credits)) : parseFloat(course.credits);
            return {
                bcpm: course.bcpm,
                qualityPoints: convertGPA(course.grade) * credits,
                credits: credits,
                term: course.term
            };
        });
    };

    return (
        <>
            <Card className="shadow">
                <CardHeader className='bg-transparent'>
                    <Row className="align-items-center">
                        <div className="col">
                            <h6 className="text-uppercase text-muted ls-1 mb-1">GPA Trends</h6>
                            <h2 className="mb-0">Scores</h2>
                        </div>
                    </Row>
                </CardHeader>
                <CardBody>
                    <div className="chart" style={{ position: "relative", height: "600px" }}>
                        {console.log(bcpmGpa)}
                        <Line
                            data={{
                                labels: labels,
                                datasets: [
                                    {
                                        type: 'line',
                                        label: 'SCIENCE GPA',
                                        data: bcpmGpa,
                                        borderColor: '#3498db',
                                        backgroundColor: '#3498db',
                                        pointBackgroundColor: '#3498db',
                                        pointStyle: 'circle',
                                        pointRadius: 5,
                                        fill: false,
                                    },
                                    {
                                        type: 'line',
                                        label: 'ALL OTHER GPA',
                                        data: aoGpa,
                                        borderColor: '#07bc0c',
                                        backgroundColor: '#07bc0c',
                                        pointBackgroundColor: '#07bc0c',
                                        pointStyle: 'circle',
                                        pointRadius: 5,
                                        fill: false,
                                    },
                                    {
                                        type: 'line',
                                        label: 'CUMULATIVE GPA',
                                        data: cumulativeGpa,
                                        borderColor: '#e74c3c',
                                        backgroundColor: '#e74c3c',
                                        pointBackgroundColor: '#e74c3c',
                                        pointStyle: 'circle',
                                        pointRadius: 5,
                                        fill: false,
                                    }
                                ]
                            }}
                            options={{
                                maintainAspectRatio: false,
                                legend: {
                                    display: true,
                                },
                                responsive: true,
                                scales: {
                                    xAxes: [{
                                        display: true,
                                        type: 'category',
                                        labels: labels,
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'SEMESTER'
                                        }
                                    }],
                                    yAxes: [{
                                        display: true,
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'GPA'
                                        },
                                        ticks: {
                                            min: 0,
                                            max: 4,
                                            stepSize: 0.25,
                                            callback: function(value) {
                                                if (value === 0) {
                                                    return '0';
                                                }
                                                return value.toFixed(2); // Keep values as they are, just a demonstration
                                            }
                                        }
                                    }]
                                },
                                
                            }}
                        />
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

const mapStateToProps = state => {
    return {
        fetchCourses: state.fetchCourses,
        userDetails: state.loginAuth.userDetails,
    };
};

export default connect(mapStateToProps)(GpaTrend);
