import {
    SEND_EMAIL_NOTIF_REQUEST,
    SEND_EMAIL_NOTIF_SUCCESS,
    SEND_EMAIL_NOTIF_FAILURE,
    SEND_EMAIL_NOTIF_RESET
    } from "./EmailType"

    import axios from "axios";

    const sendEmailNotificationRequest = () => {
        return {
            type: SEND_EMAIL_NOTIF_REQUEST,
        }
    }
    
    const sendEmailNotificationSuccess = (success) => {
        return {
            type: SEND_EMAIL_NOTIF_SUCCESS,
            payload: success
        }
    }
    
    const sendEmailNotificationFailure = (error) => {
        return {
            type: SEND_EMAIL_NOTIF_FAILURE,
            payload : error
        }
    }

    export const sendEmailNotification = (data) => {
        return (dispatch) => {
            dispatch(sendEmailNotificationRequest());
            axios.post('/sendEmail' , data)
            .then(response => {
                const res = response.data
                dispatch(sendEmailNotificationSuccess(res)) 
            })
            .catch(error => {
                const errorMsg = error.message
                dispatch(sendEmailNotificationFailure(errorMsg))
            })  
        }
    }