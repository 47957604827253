import {
  FETCH_AVAILABLE_SCHEDULE_FAILURE,
  FETCH_AVAILABLE_SCHEDULE_REQUEST,
  FETCH_AVAILABLE_SCHEDULE_RESET,
  FETCH_AVAILABLE_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAILURE,
  FETCH_SCHEDULE_REQUEST,
  FETCH_SCHEDULE_RESET,
  FETCH_SCHEDULE_SUCCESS,
  CREATE_REQUEST_TUTOR_FAILURE,
  CREATE_REQUEST_TUTOR_REQUEST,
  CREATE_REQUEST_TUTOR_RESET,
  CREATE_REQUEST_TUTOR_SUCCESS,
  FETCH_REQUEST_TUTOR_FAILURE,
  FETCH_REQUEST_TUTOR_REQUEST,
  FETCH_REQUEST_TUTOR_RESET,
  FETCH_REQUEST_TUTOR_SUCCESS,
  FETCH_STUDENT_SCHEDULE_FAILURE,
  FETCH_STUDENT_SCHEDULE_REQUEST,
  FETCH_STUDENT_SCHEDULE_RESET,
  FETCH_STUDENT_SCHEDULE_SUCCESS,
  CANCEL_REQUEST_TUTOR_FAILURE,
  CANCEL_REQUEST_TUTOR_REQUEST,
  CANCEL_REQUEST_TUTOR_RESET,
  CANCEL_REQUEST_TUTOR_SUCCESS,
  FETCH_REQUEST_TUTOR_HISTORY_FAILURE,
  FETCH_REQUEST_TUTOR_HISTORY_REQUEST,
  FETCH_REQUEST_TUTOR_HISTORY_RESET,
  FETCH_REQUEST_TUTOR_HISTORY_SUCCESS,
  FETCH_COACHES_FAILURE,
  FETCH_COACHES_REQUEST,
  FETCH_COACHES_RESET,
  FETCH_COACHES_SUCCESS,
  CREATE_REQUEST_SCHEDTUTOR_FAILURE,
  CREATE_REQUEST_SCHEDTUTOR_REQUEST,
  CREATE_REQUEST_SCHEDTUTOR_RESET,
  CREATE_REQUEST_SCHEDTUTOR_SUCCESS,
} from "./ScheduleTutorType";

import axios from "axios";

export const fetchAvailableScheduleReset = () => {
  return {
    type: FETCH_AVAILABLE_SCHEDULE_RESET,
  };
};

const fetchAvailableScheduleRequest = () => {
  return {
    type: FETCH_AVAILABLE_SCHEDULE_REQUEST,
  };
};

const fetchAvailableScheduleSuccess = (success) => {
  return {
    type: FETCH_AVAILABLE_SCHEDULE_SUCCESS,
    payload: success,
  };
};

const fetchAvailableScheduleFailure = (error) => {
  return {
    type: FETCH_AVAILABLE_SCHEDULE_FAILURE,
    payload: error,
  };
};

export const fetchAvailableSchedule = (data) => {

  return (dispatch) => {
    dispatch(fetchAvailableScheduleRequest());

    axios
      .post("/fetchAvailableSchedule", data)
      .then((response) => {
        const fetchAvailableSchedule = response.data;
        dispatch(fetchAvailableScheduleSuccess(fetchAvailableSchedule));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchAvailableScheduleFailure(errorMsg));
      });
  };
};

export const fetchScheduleReset = () => {
    return {
        type: FETCH_SCHEDULE_RESET,
    }
}

const fetchScheduleRequest = () => {
    return {
        type: FETCH_SCHEDULE_REQUEST,
    }
}

const fetchScheduleSuccess = success => {
    return {
        type: FETCH_SCHEDULE_SUCCESS,
        payload: success
    }
}

const fetchScheduleFailure = error => {
    return {
        type: FETCH_SCHEDULE_FAILURE,
        payload : error
    }
}


export const fetchSchedule = (data) => {
    return (dispatch) => {

        dispatch(fetchScheduleRequest());
        axios.post('/fetchSchedule',data)
        .then(response => {
            const fetchSchedule = response.data
            dispatch(fetchScheduleSuccess(fetchSchedule)) 
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchScheduleFailure(errorMsg))
        })
        
    }

}

export const createRequestTutorReset = () => {
  return {
    type: CREATE_REQUEST_TUTOR_RESET,
  };
};

const createRequestTutorRequest = () => {
  return {
    type: CREATE_REQUEST_TUTOR_REQUEST,
  };
};

const createRequestTutorSuccess = (success) => {
  return {
    type: CREATE_REQUEST_TUTOR_SUCCESS,
    payload: success,
  };
};

const createRequestTutorFailure = (error) => {
  return {
    type: CREATE_REQUEST_TUTOR_FAILURE,
    payload: error,
  };
};

export const createRequestTutor = (data) => {
  return (dispatch) => {
    dispatch(createRequestTutorRequest());

    axios
      .post("/createRequestTutor", data)
      .then((response) => {
        const createRequestTutor = response.data;
        dispatch(createRequestTutorSuccess(createRequestTutor));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(createRequestTutorFailure(errorMsg));
      });
  };
};

export const fetchRequestTutorReset = () => {
  return {
    type: FETCH_REQUEST_TUTOR_RESET,
  };
};

const fetchRequestTutorRequest = () => {
  return {
    type: FETCH_REQUEST_TUTOR_REQUEST,
  };
};

const fetchRequestTutorSuccess = (success) => {
  return {
    type: FETCH_REQUEST_TUTOR_SUCCESS,
    payload: success,
  };
};

const fetchRequestTutorFailure = (error) => {
  return {
    type: FETCH_REQUEST_TUTOR_FAILURE,
    payload: error,
  };
};

export const fetchRequestTutor = (data) => {
  return (dispatch) => {
    dispatch(fetchRequestTutorRequest());

    axios
      .post("/fetchRequestTutor", data)
      .then((response) => {
        const fetchRequestTutor = response.data;
        dispatch(fetchRequestTutorSuccess(fetchRequestTutor));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchRequestTutorFailure(errorMsg));
      });
  };
};

export const fetchStudentScheduleReset = () => {
  return {
    type: FETCH_STUDENT_SCHEDULE_RESET,
  };
};

const fetchStudentScheduleRequest = () => {
  return {
    type: FETCH_STUDENT_SCHEDULE_REQUEST,
  };
};

const fetchStudentScheduleSuccess = (success) => {
  return {
    type: FETCH_STUDENT_SCHEDULE_SUCCESS,
    payload: success,
  };
};

const fetchStudentScheduleFailure = (error) => {
  return {
    type: FETCH_STUDENT_SCHEDULE_FAILURE,
    payload: error,
  };
};

export const fetchStudentSchedule = (data) => {
  return (dispatch) => {
    dispatch(fetchStudentScheduleRequest());

    axios
      .post("/fetchStudentSchedule", data)
      .then((response) => {
        const fetchStudentSchedule = response.data;
        dispatch(fetchStudentScheduleSuccess(fetchStudentSchedule));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchStudentScheduleFailure(errorMsg));
      });
  };
};

export const cancelRequestTutorReset = () => {
  return {
    type: CANCEL_REQUEST_TUTOR_RESET,
  };
};

const cancelRequestTutorRequest = () => {
  return {
    type: CANCEL_REQUEST_TUTOR_REQUEST,
  };
};

const cancelRequestTutorSuccess = (success) => {
  return {
    type: CANCEL_REQUEST_TUTOR_SUCCESS,
    payload: success,
  };
};

const cancelRequestTutorFailure = (error) => {
  return {
    type: CANCEL_REQUEST_TUTOR_FAILURE,
    payload: error,
  };
};

export const cancelRequestTutor = (data) => {
  return (dispatch) => {
    dispatch(cancelRequestTutorRequest());

    axios
      .post("/cancelRequestTutor", data)
      .then((response) => {
        const cancelRequestTutor = response.data;
        dispatch(cancelRequestTutorSuccess(cancelRequestTutor));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(cancelRequestTutorFailure(errorMsg));
      });
  };
};

export const fetchRequestTutorHistoryReset = () => {
  return {
    type: FETCH_REQUEST_TUTOR_HISTORY_RESET,
  };
};

const fetchRequestTutorHistoryRequest = () => {
  return {
    type: FETCH_REQUEST_TUTOR_HISTORY_REQUEST,
  };
};

const fetchRequestTutorHistorySuccess = (success) => {
  return {
    type: FETCH_REQUEST_TUTOR_HISTORY_SUCCESS,
    payload: success,
  };
};

const fetchRequestTutorHistoryFailure = (error) => {
  return {
    type: FETCH_REQUEST_TUTOR_HISTORY_FAILURE,
    payload: error,
  };
};

export const fetchRequestTutorHistory = (data) => {
  return (dispatch) => {
    dispatch(fetchRequestTutorHistoryRequest());

    axios
      .post("/fetchRequestTutorHistory", data)
      .then((response) => {
        const fetchRequestTutorHistory = response.data;
        dispatch(fetchRequestTutorHistorySuccess(fetchRequestTutorHistory));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchRequestTutorHistoryFailure(errorMsg));
      });
  };
};

export const fetchCoachesReset = () => {
  return {
    type: FETCH_COACHES_RESET,
  };
};

const fetchCoachesRequest = () => {
  return {
    type: FETCH_COACHES_REQUEST,
  };
};

const fetchCoachesSuccess = (success) => {
  return {
    type: FETCH_COACHES_SUCCESS,
    payload: success,
  };
};

const fetchCoachesFailure = (error) => {
  return {
    type: FETCH_COACHES_FAILURE,
    payload: error,
  };
};

export const fetchCoaches = (data) => {
  return (dispatch) => {
    dispatch(fetchCoachesRequest());

    axios
      .post("/fetchCoaches", data)
      .then((response) => {
        const fetchCoaches = response.data;
        dispatch(fetchCoachesSuccess(fetchCoaches));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchCoachesFailure(errorMsg));
      });
  };
};

export const createRequestSchedTutorReset = () => {
  return {
    type: CREATE_REQUEST_SCHEDTUTOR_RESET,
  };
};

const createRequestSchedTutorRequest = () => {
  return {
    type: CREATE_REQUEST_SCHEDTUTOR_REQUEST,
  };
};

const createRequestSchedTutorSuccess = (success) => {
  return {
    type: CREATE_REQUEST_SCHEDTUTOR_SUCCESS,
    payload: success,
  };
};

const createRequestSchedTutorFailure = (error) => {
  return {
    type: CREATE_REQUEST_SCHEDTUTOR_FAILURE,
    payload: error,
  };
};

export const createRequestSchedTutor = (data) => {
  return (dispatch) => {
    dispatch(createRequestSchedTutorRequest());

    axios
      .post("/createRequestSchedTutor", data)
      .then((response) => {
        const createRequestSchedTutor = response.data;
        dispatch(createRequestSchedTutorSuccess(createRequestSchedTutor));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(createRequestSchedTutorFailure(errorMsg));
      });
  };
};
