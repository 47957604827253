import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    CardImg,
    TabContent, TabPane, Nav, NavItem, NavLink, Table, Media
    
} from "reactstrap";

import { EditorState, convertToRaw, ContentState, convertFromHTML, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { buyTutoringHours } from "redux/Payments/PaymentsActions";
import BuyTpcCourseModal from "components/Modal/BuyTpcCourseModal";
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Swal from 'sweetalert2'
import { updateMarkedCompleteLesson, updateMarkedCompleteLessonReset, fetchMarkedCompleteLesson } from 'redux/ManageTpcCourses/ManageTpcCoursesActions';
import { useDispatch,connect } from 'react-redux';


const TpcCoursesLessons = (props) => {
    const [paid, setPaid] = useState(props.courseData.payment)


    //   const result = []
    //     tpcPaymentRecord.map((pay,i) => (
    //         cid == pay[i].collectionDocId ? result.push(pay[i]) : ""
    //      ))
    //     return result          

    const embedContainerStyle = {
        position: 'relative',
        paddingBottom: '56.25%',
        height: 0,
        overflow: 'hidden',
        maxWidth: '100%',
        borderRadius: "5px", 
    };

    const iframeStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    };

    const [selectedLesson, setSelectedLesson] = useState({})

    const handleSelectLesson = (e, lesson, categoryId, status) => {
        console.log(lesson)
        e.preventDefault();

        lesson.tpcCourseId = props.courseData.tpcCourseId
        lesson.categoryId = categoryId
        lesson.status = status
        setSelectedLesson(lesson)

        const element = document.getElementById('video-section');
    
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start', // Align to the top of the element
          });
        }

    }

    const modalBuyTpcCourseLessonModal = props.buyTutoringHoursModalStatus.status;
    const [buyLesson, setBuyLesson] =  useState([])
    const toggleBuyTpcCourseLessonModal = (e, lesson, categoryId) => {
        setBuyLesson(lesson)
        dispatch(buyTutoringHours(!props.buyTutoringHoursModalStatus.status));
    };

    const handleContinueCourse = (e) => {
        e.preventDefault()
        const element = document.getElementById('video-section');
    
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start', // Align to the top of the element
          });
        }
    };

    const [lessonsTaken, setLessonsTaken] = useState([]);
    
    useEffect(() => {

        dispatch(fetchMarkedCompleteLesson({handle: props.userDetails.handle}))

        
        
        
    }, [])
    
    const dispatch = useDispatch()

    const handleMarkAsComplete = (e) => {
        e.preventDefault()
        
        Swal.showLoading()
        
        dispatch(updateMarkedCompleteLesson({handle : props.userDetails.handle, markedLesson : selectedLesson}))
    }
    
    useEffect(() => {
    
  
        if(props.fetchMarkedCompleteLesson.markedcompletelesson.length && typeof props.fetchMarkedCompleteLesson.error === "undefined"){
            Swal.showLoading()
            setLessonsTaken(props.fetchMarkedCompleteLesson.markedcompletelesson)
            
            
        }
      
      
    }, [props.fetchMarkedCompleteLesson])

    const handleNextLesson = (e) => {
        e.preventDefault()
        

        const categoryData = props.courseData.category

        let visibility = "draft";

        for (let categoryIndex = selectedLesson.categoryId; categoryIndex <= categoryData.length; categoryIndex++) {
            

            for (let lessonIndex = categoryIndex ==selectedLesson.categoryId ? selectedLesson.id + 1 : 1 ; lessonIndex <= categoryData[categoryIndex-1].lessons.length; lessonIndex++) {
                
                
                

                if(categoryData[categoryIndex - 1].lessons[lessonIndex - 1].visibility == "published"){
                    
                    let lessonNext = categoryData[categoryIndex - 1].lessons[lessonIndex - 1]

                    lessonNext.tpcCourseId = props.courseData.tpcCourseId
                    lessonNext.categoryId = categoryIndex
                    lessonNext.status = typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.["category" + categoryIndex]?.["lesson" + lessonIndex]?.status !== "undefined" ? "completed" : "not completed"

                    visibility = "visibility"
                    setSelectedLesson(lessonNext)

                    const element = document.getElementById('video-section');
    
                    if (element) {
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start', // Align to the top of the element
                    });
                    }

                    break;
                }
            }   

        }

        if(visibility == "draft"){
            Swal.fire(
                {
                    title: 'Congratulations!',
                    text: 'You have reached the end of the lessons.',
                    icon : 'success',
                    timer: 3000
                }
            )
        }
        
        
    }
    
    useEffect(() => {
        if(lessonsTaken.length != 0){

            // typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.["category" + category.id]?.["lesson" + lesson.id]?.status !== "undefined"

            // if (typeof props.courseData.category[lessonsTaken[0][props.courseData]]?.lessons[0]?)

            // props.courseData.category.forEach(category => {
            //     if(category.lessons){
            //         category.lessons.forEach(lesson => {
                
            //         });
            //     }
            //     else{
                    // let initialSelectedLesson = props.courseData.category[0].lessons[0]
                    // initialSelectedLesson.tpcCourseId = props.courseData.tpcCourseId
                    // initialSelectedLesson.categoryId = props.courseData.category[0].id
                    // setSelectedLesson(initialSelectedLesson)
            //     }
            // });
            
            if(typeof lessonsTaken[0][props.courseData.tpcCourseId] != "undefined"){
                const categoryArray = props.courseData.category
                for (let categoryIndex = categoryArray.length; categoryIndex >= 1; categoryIndex--) {
                    if(typeof lessonsTaken[0][props.courseData.tpcCourseId]["category" + categoryIndex] !== "undefined"){
                        for (let lessonIndex = categoryArray[categoryIndex-1].lessons.length; lessonIndex >= 1; lessonIndex--) {
                            
                            
                            if(typeof lessonsTaken[0][props.courseData.tpcCourseId]["category" + categoryIndex]["lesson" + lessonIndex] !== "undefined"){
                                if(categoryArray[categoryIndex-1].lessons[lessonIndex-1].visibility == "published"){
                                    let initialSelectedLesson = props.courseData.category[categoryIndex-1].lessons[lessonIndex-1]
                                    initialSelectedLesson.tpcCourseId = props.courseData.tpcCourseId
                                    initialSelectedLesson.categoryId = props.courseData.category[categoryIndex-1].id
                                    initialSelectedLesson.status = "completed"
                                    setSelectedLesson(initialSelectedLesson)
                                    break;
                                }
                                
                            }
                        }
                        
                    }
                }
            
            }
            else{
                const categoryData = props.courseData.category

                categoryData.map(value => {

                    if(value.lessons.length){
                        
                        if(value.lessons.filter(lesson => lesson.visibility == "published").length){
                            let initialSelectedLesson = value.lessons.filter(lesson => lesson.visibility == "published")[0]
                            initialSelectedLesson.tpcCourseId = props.courseData.tpcCourseId
                            initialSelectedLesson.categoryId = value.id
                            initialSelectedLesson.status = "not completed"
                            setSelectedLesson(initialSelectedLesson)
                        }
                    }
                    
                })


                

                
            }
            
            
            
        }
        else{

            const categoryData = props.courseData.category

            categoryData.map(value => {

                if(value.lessons.length){
                    
                    if(value.lessons.filter(lesson => lesson.visibility == "published").length){
                        let initialSelectedLesson = value.lessons.filter(lesson => lesson.visibility == "published")[0]
                        initialSelectedLesson.tpcCourseId = props.courseData.tpcCourseId
                        initialSelectedLesson.categoryId = value.id
                        initialSelectedLesson.status = "not completed"
                        setSelectedLesson(initialSelectedLesson)
                    }
                }
                
            })


           
            
        }

        Swal.close()

    }, [lessonsTaken])
    

    useEffect(() => {
    
  
        if(props.updateMarkedCompleteLesson.markedcompletelesson.length && typeof props.updateMarkedCompleteLesson.error === "undefined"){
          
          Swal.fire({
              title: 'Congrats!',
              text: 'Proceed to the next lesson.',
              icon : 'success',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(updateMarkedCompleteLessonReset())
            dispatch(fetchMarkedCompleteLesson({handle: props.userDetails.handle}))
          })
          
        }
      
        else if(typeof props.updateMarkedCompleteLesson.error !== "undefined"){
       
          Swal.fire({
              title:'Failed!',
              text:'Failed to mark as complete lesson!',
              icon :'error',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(updateMarkedCompleteLessonReset())
            dispatch(fetchMarkedCompleteLesson({handle: props.userDetails.handle}))

          })    
                
        }
      
    }, [props.updateMarkedCompleteLesson])

    return (
    <>
        <Row className='mb-3'>
            {/* <Col md="12" className=' mb-3' id="video-section">
                
                <Card className="shadow rounded ">
                    <CardHeader className="border-0 bg-info rounded">
                        <div className='row pl-4'>
                            <div className='col-12 d-flex align-items-center'>
                                <h1 className="display-4 text-white mb-0 text-uppercase  font-weight-bolder">{selectedLesson.title}</h1>
                            </div>
                            <div className='col-5 text-right d-flex align-content-center justify-content-end'>
                                <button className='btn btn-default rounded text-lg text-white pl-lg-6 pr-lg-6'>Continue Course</button>
                            </div>
                        </div>
                    </CardHeader>
                    
                </Card>
            </Col> */}
            <Col md="12 " >
                
                {selectedLesson.video &&
                    <div style={embedContainerStyle} className='embed-container mb-3 shadow'>
                        <iframe src={"//player.vimeo.com/video/"+selectedLesson.video.split('/').slice(-2, -1).pop()+"?h="+selectedLesson.video.split('/').pop().split('?').shift()+"&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;"}    frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen className="embed-responsive-item" picture-in-picture style={iframeStyle}></iframe>
                        {/* <iframe src={"//player.vimeo.com/video/"+props.courseData.category[0].lessons[0].video.split('/').slice(-2, -1).pop()+"?h="+props.courseData.category[0].lessons[0].video.split('/').pop().split('?').shift()+"&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;"}    frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen className="embed-responsive-item" picture-in-picture style={iframeStyle}></iframe> */}
                    </div>
                }
                
            </Col>
            <Col md="12">
                <h1 className="text-darker mb-0  font-weight-bolder lh-130">{selectedLesson.title}</h1>
            </Col>

            <Col md="12" className='mt-3'>
                
                <Card className="shadow rounded">
                    <CardHeader className="border-0 bg-info rounded pt-3 pb-3">
                        
                        {
                            selectedLesson.status != "completed" ?
                                <div className='row pl-2'>
                                    <div className='col-7 d-flex align-items-center'>
                                        <h3 className="text-darker mb-0 text-uppercase font-italic font-weight-bolder">Finished this lesson?</h3>
                                    </div>
                                    <div className='col-5 text-right d-flex align-content-center justify-content-end'>
                                        <button onClick={e => handleMarkAsComplete(e)} className='text-nowrap btn btn-default btn-sm rounded text-sm text-white pl-xl-7 pr-xl-7 pl-lg-6 pr-lg-6 pl-md-4 pr-md-4 pl-sm-5 pr-sm-5'> Mark As Complete</button>
                                    </div>
                                </div>
                            :

                            <div className='row pl-2'>
                                <div className='col-7 d-flex align-items-center'>
                                    <h3 className="text-darker mb-0 text-uppercase font-italic font-weight-bolder">Ready To Move On ?</h3>
                                </div>
                                <div className='col-5 text-right d-flex align-content-center justify-content-end'>
                                    <button onClick={e => handleNextLesson(e)} className='text-nowrap btn btn-default btn-sm rounded text-sm text-white pl-xl-7 pr-xl-7 pl-lg-6 pr-lg-6 pl-md-4 pr-md-4 pl-sm-5 pr-sm-5'>Next Lesson</button>
                                </div>
                            </div>

                        }
                    </CardHeader>
                    
                </Card>
            </Col>
            
            <Col md="12 mt-3">
                <Card className="shadow">
                    {/* <CardHeader className="border-0 p-0">
                        
                        
                    </CardHeader> */}
                    <CardBody>
                        <h2 className='text-darker font-weight-bolder'>About </h2>
                        <ul>
                            {selectedLesson.body ?
                                convertToHTML(convertFromRaw(JSON.parse(selectedLesson.body))) !== "<p></p>" ?
                                <>
                                <Editor
                                        className="form-control-alternative"
                                        editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(selectedLesson.body)))}
                                        toolbarHidden={true}
                                        readOnly
                                        toolbar={{
                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                            
                                        }}
                                    />
                                </> : "" : ""
                            }
                        </ul>
                    </CardBody>
                </Card>
            </Col>
                            
            <Col md="12 mt-3">
                <Card className="shadow">
                    {/* <CardHeader className="border-0 p-0">
                        
                        
                    </CardHeader> */}
                    <CardBody>
                        <h2 className=' text-darker font-weight-bolder'>Resources</h2>
                        
                        <ul>
                            
                            {typeof selectedLesson.files != "undefined" && selectedLesson.files.length != 0 ? selectedLesson.files.map((value) => { return <li key={value.rawFileName}>
                                <a href={"https://storage.googleapis.com/tpcdashboard.appspot.com/files-tpc-courses-lessons/" + value.filenameWithUuid} download={value.rawFileName} className='text-info'>{value.rawFileName}</a></li>
                            }) : ""}
                            
                        </ul>

                    </CardBody>
                </Card>
            </Col>

            <Col md="12 mt-3 ">
                <Card className="shadow ">
                    {/* <CardHeader className="border-0 p-0">
                        
                        
                    </CardHeader> */}
                    <CardBody>
                        {props.courseData.instructorHeadshot ? <div className='float-lg-left m-3 d-flex justify-content-center align-items-center'><div className="card-profile-div-sm card-profile-div-md card-profile-div-lg ">
                            <div className="card-profile-image  ">
                            
                                <div className="profile-picture-sm profile-letter-md profile-picture-md profile-picture-lg" style={{backgroundImage: `url(https://storage.googleapis.com/tpcdashboard.appspot.com/img-tpc-courses/` + props.courseData.instructorHeadshot+`)`}}></div>
                            
                            </div>
                        </div> </div>:
                        <div className="card-profile-image float-lg-left mr-md-5 mb-sm-4 d-flex justify-content-sm-center">
                            {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                            
                            <div className="rounded-circle profile-letter-sm-primary profile-picture-md profile-picture-lg profile-letter-lg-primary">{props.courseData.instructorName.trim()[0]}</div>
                            {/* </a> */}
                        </div>}
                        <div style={{minHeight:"180px"}} className='d-flex align-items-center'>
                            <div>
                                <h1 className='text-darker font-weight-bolder mb-0'>{props.courseData.instructorName}</h1>
                                <h3 className='text-info font-weight-bolder ls-15'>INSTRUCTOR</h3>
                                <p className='text-md  lh-150'>{props.courseData.instructorBio}</p>
                            </div>
                        </div>

                    </CardBody>
                </Card>
            </Col>
            
            
            
            <Col md="12" className='mt-5 '>
                <h2 className=" text-darker mb-0 font-weight-bolder">Lesson Overview</h2>
                
            </Col>
            {
                typeof props.courseData.category != "undefined" ? props.courseData.category.map((category, index) => {
                    return <Col md="12" className='mt-3 w-100' key={index}>
                        <Table className="align-items-center table-light rounded shadow ">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" style={{ whiteSpace: 'normal' }}><h3 className='text-darker mb-0 font-italic'><i className="fas fa-play-circle"></i>&nbsp;{category.text}</h3></th>
                                </tr>
                            </thead> 
                            <tbody>
                                {
                                    category.lessons.length != 0 ? category.lessons.some(lesson => lesson.visibility == "published")  ? category.lessons.map((lesson, index) => {
                                        
                                    return lesson.visibility == "published" && <tr>
                                        <th scope="row" key={index+"lesson"} style={{ whiteSpace: 'normal' }}>
                                            <div className='row'>
                                                <div className='col-8 d-flex   align-items-center'>
                                                    {/* <p className="mb-0 text-darker text-sm text-truncate w-100" > */}
                                                    &nbsp;&nbsp;&nbsp;
                                                    {
                                                        typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.["category" + category.id]?.["lesson" + lesson.id]?.status !== "undefined" ? <label className='mb-0 text-sm  text-success'><i className='fas fa-check'></i>&nbsp;&nbsp;&nbsp;{lesson.title}</label> : <label className='mb-0 text-sm text-darker'><i className="fas fa-play-circle"></i>&nbsp;&nbsp;&nbsp;{lesson.title}</label>
                                                    }
                                                    
                                                    {/* </p> */}
                                                </div>
                                                <div className='col-4 text-right'>
                                                    {
                                                        typeof  lesson.availability !== "undefined" ? 
                                                            lesson.availability == "lock" ? 
                                                               props.courseData.payment !== undefined ?
                                                                <button className='btn btn-default btn-sm' onClick={e => handleSelectLesson(e, lesson, category.id, typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.["category" + category.id]?.["lesson" + lesson.id]?.status !== "undefined" ? "completed" : "not completed")}>
                                                                    {typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.["category" + category.id]?.["lesson" + lesson.id]?.status !== "undefined" ? 
                                                                        "Revisit lesson" : "Go to lesson" 
                                                                    }
                                                                </button>
                                                            : 
                                                            <button className='btn btn-default btn-sm' onClick={(e) => toggleBuyTpcCourseLessonModal(e, lesson, category.id)} >
                                                                <i className="fas fa-lock"></i> Buy Lesson
                                                                
                                                            </button> 
                                                            : 
                                                            <button className='btn btn-default btn-sm' onClick={e => handleSelectLesson(e, lesson, category.id, typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.["category" + category.id]?.["lesson" + lesson.id]?.status !== "undefined" ? "completed" : "not completed")}>
                                                                {typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.["category" + category.id]?.["lesson" + lesson.id]?.status !== "undefined" ? 
                                                                    "Revisit lesson" : "Go to lesson" 
                                                                }
                                                            </button>
                                                           
                                                        : ""

                                                    }
                                                  
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                }) : <tr><th style={{ whiteSpace: 'normal' }}><div className='row'>
                                <div className='col-12 d-flex   align-items-center'><p className="mb-0 text-darker font-weight-bold">&nbsp;&nbsp;&nbsp;<label className="mb-0">No lesson listed.</label></p></div></div></th></tr> : <tr><th style={{ whiteSpace: 'normal' }}><div className='row'>
                                <div className='col-12 d-flex   align-items-center'><p className="mb-0 text-darker font-weight-bold">&nbsp;&nbsp;&nbsp;<label className="mb-0">No lesson listed.</label></p></div></div></th></tr>}
                            </tbody>
                        </Table>
                    </Col>         
                }) : ""
            }

            <Col md="12" className='mt-2 mb-5'>
                
                <Card className="shadow rounded">
                    <CardHeader className="border-0 bg-info rounded pt-3 pb-3">
                        <div className='row pl-2 '>
                            <div className='col-7 d-flex align-items-center'>
                                <h3 className="text-darker mb-0 text-uppercase font-italic font-weight-bolder">Ready to start ?</h3>
                            </div>
                            <div className='col-5 text-right d-flex align-content-center justify-content-end'>
                                <button className='text-nowrap btn btn-default btn-sm rounded text-sm text-white pl-xl-7 pr-xl-7 pl-lg-6 pr-lg-6 pl-md-4 pr-md-4 pl-sm-5 pr-sm-5' onClick={e => handleContinueCourse(e)}>Continue Course</button>
                            </div>
                        </div>
                    </CardHeader>
                    
                </Card>
            </Col>

        </Row>

        <BuyTpcCourseModal
            course={props.courseData}
            modalBuyTpcCourseLessonModal={modalBuyTpcCourseLessonModal}
            toggleBuyTpcCourseLessonModal={toggleBuyTpcCourseLessonModal}
        />
        

    </>
  )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        updateMarkedCompleteLesson : state.updateMarkedCompleteLesson,
        fetchMarkedCompleteLesson : state.fetchMarkedCompleteLesson,
        buyTutoringHoursModalStatus: state.buyTutoringHoursModalStatus,
        
    }
      
}

export default connect(mapStateToProps)(TpcCoursesLessons)
