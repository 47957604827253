import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Container, Row, Col, Progress } from "reactstrap";
import { connect } from 'react-redux';
import { premedReqs } from 'util/DataList';
const PremedRequirementsPercentage = (props) => {

    const [percentageReq, setPercentageReq] = useState(0)
    useEffect(() => {
        if(props.fetchCourses.courses.length){
            let percentageReqVal = [];

            props.fetchCourses.courses.map((value, index) => {
                if(value.reqs.length){
                    value.reqs.map((reqsValue, reqsIndex) => {
                        if(reqsValue != "default" && reqsValue != "Class not a Premed Requirement"){
                            // setRequirements((prevRequirements) => ({
                            //     ...prevRequirements,
                            //     [reqsValue]: true
                            // }));
                            
                            percentageReqVal.push(reqsValue);

                        }
                    })
                }
               
            });

           
            percentageReqVal = [...new Set(percentageReqVal)]
            
            setPercentageReq(((percentageReqVal.length/(premedReqs.length-1)) * 100).toFixed(2))
        }
    }, [props.fetchCourses])

  return (
    <>
        <Card className="card-stats mb-1 mb-xl-0 w-100">
            <CardBody>
            <Row>
                <div className="col">
                    
                    <div className="progress-wrapper pt-0">
                    <div className="progress-info">
                    <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                    >
                        Premed Requirements
                    </CardTitle>
                        <div className="progress-percentage mb--1">
                        <span>{percentageReq}%</span>
                        </div>
                    </div>
                    <Progress max="100" value={percentageReq} color="success" />
                    </div>
                </div>
                
            </Row>
            
            </CardBody>
        </Card>
    </>
  )
}

const mapStateToProps = state => {
    return {
        fetchCourses : state.fetchCourses,
        userDetails : state.loginAuth.userDetails,
    }
  }

export default connect(mapStateToProps)(PremedRequirementsPercentage)