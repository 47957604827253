export const FETCH_AVAILABLE_SCHEDULE_REQUEST =
  "FETCH_AVAILABLE_SCHEDULE_REQUEST";
export const FETCH_AVAILABLE_SCHEDULE_SUCCESS =
  "FETCH_AVAILABLE_SCHEDULE_SUCCESS";
export const FETCH_AVAILABLE_SCHEDULE_FAILURE =
  "FETCH_AVAILABLE_SCHEDULE_FAILURE";
export const FETCH_AVAILABLE_SCHEDULE_RESET = "FETCH_AVAILABLE_SCHEDULE_RESET";


export const FETCH_SCHEDULE_REQUEST = 'FETCH_SCHEDULE_REQUEST';
export const FETCH_SCHEDULE_SUCCESS = 'FETCH_SCHEDULE_SUCCESS';
export const FETCH_SCHEDULE_FAILURE = 'FETCH_SCHEDULE_FAILURE';
export const FETCH_SCHEDULE_RESET = 'FETCH_SCHEDULE_RESET';

export const CREATE_REQUEST_TUTOR_REQUEST = "CREATE_REQUEST_TUTOR_REQUEST";
export const CREATE_REQUEST_TUTOR_SUCCESS = "CREATE_REQUEST_TUTOR_SUCCESS";
export const CREATE_REQUEST_TUTOR_FAILURE = "CREATE_REQUEST_TUTOR_FAILURE";
export const CREATE_REQUEST_TUTOR_RESET = "CREATE_REQUEST_TUTOR_RESET";

export const FETCH_REQUEST_TUTOR_REQUEST = "FETCH_REQUEST_TUTOR_REQUEST";
export const FETCH_REQUEST_TUTOR_SUCCESS = "FETCH_REQUEST_TUTOR_SUCCESS";
export const FETCH_REQUEST_TUTOR_FAILURE = "FETCH_REQUEST_TUTOR_FAILURE";
export const FETCH_REQUEST_TUTOR_RESET = "FETCH_REQUEST_TUTOR_RESET";

export const FETCH_STUDENT_SCHEDULE_REQUEST = "FETCH_STUDENT_SCHEDULE_REQUEST";
export const FETCH_STUDENT_SCHEDULE_SUCCESS = "FETCH_STUDENT_SCHEDULE_SUCCESS";
export const FETCH_STUDENT_SCHEDULE_FAILURE = "FETCH_STUDENT_SCHEDULE_FAILURE";
export const FETCH_STUDENT_SCHEDULE_RESET = "FETCH_STUDENT_SCHEDULE_RESET";


export const FETCH_REQUEST_TUTOR_HISTORY_REQUEST =
  "FETCH_REQUEST_TUTOR_HISTORY_REQUEST";
export const FETCH_REQUEST_TUTOR_HISTORY_SUCCESS =
  "FETCH_REQUEST_TUTOR_HISTORY_SUCCESS";
export const FETCH_REQUEST_TUTOR_HISTORY_FAILURE =
  "FETCH_REQUEST_TUTOR_HISTORY_FAILURE";
export const FETCH_REQUEST_TUTOR_HISTORY_RESET =
  "FETCH_REQUEST_TUTOR_HISTORY_RESET";

export const CANCEL_REQUEST_TUTOR_REQUEST = "CANCEL_REQUEST_TUTOR_REQUEST";
export const CANCEL_REQUEST_TUTOR_SUCCESS = "CANCEL_REQUEST_TUTOR_SUCCESS";
export const CANCEL_REQUEST_TUTOR_FAILURE = "CANCEL_REQUEST_TUTOR_FAILURE";
export const CANCEL_REQUEST_TUTOR_RESET = "CANCEL_REQUEST_TUTOR_RESET";

export const FETCH_COACHES_REQUEST = "FETCH_COACHES_REQUEST";
export const FETCH_COACHES_SUCCESS = "FETCH_COACHES_SUCCESS";
export const FETCH_COACHES_FAILURE = "FETCH_COACHES_FAILURE";
export const FETCH_COACHES_RESET = "FETCH_COACHES_RESET";

//New Process
export const CREATE_REQUEST_SCHEDTUTOR_REQUEST =
  "CREATE_REQUEST_SCHEDTUTOR_REQUEST";
export const CREATE_REQUEST_SCHEDTUTOR_SUCCESS =
  "CREATE_REQUEST_SCHEDTUTOR_SUCCESS";
export const CREATE_REQUEST_SCHEDTUTOR_FAILURE =
  "CREATE_REQUEST_SCHEDTUTOR_FAILURE";
export const CREATE_REQUEST_SCHEDTUTOR_RESET =
  "CREATE_REQUEST_SCHEDTUTOR_RESET";
