export const CREATE_UAS_POST_REQUEST = "CREATE_UAS_POST_REQUEST";
export const CREATE_UAS_POST_SUCCESS = "CREATE_UAS_POST_SUCCESS";
export const CREATE_UAS_POST_FAILURE = "CREATE_UAS_POST_FAILURE";
export const CREATE_UAS_POST_RESET = "CREATE_UAS_POST_RESET";

export const UPDATE_UAS_POST_REQUEST = "UPDATE_UAS_POST_REQUEST";
export const UPDATE_UAS_POST_SUCCESS = "UPDATE_UAS_POST_SUCCESS";
export const UPDATE_UAS_POST_FAILURE = "UPDATE_UAS_POST_FAILURE";
export const UPDATE_UAS_POST_RESET = "UPDATE_UAS_POST_RESET";

export const DELETE_UAS_POST_REQUEST = "DELETE_UAS_POST_REQUEST";
export const DELETE_UAS_POST_SUCCESS = "DELETE_UAS_POST_SUCCESS";
export const DELETE_UAS_POST_FAILURE = "DELETE_UAS_POST_FAILURE";
export const DELETE_UAS_POST_RESET = "DELETE_UAS_POST_RESET";

export const FETCH_ALL_UAS_POSTS_RESET =
  "FETCH_ALL_UAS_POSTS_RESET";
export const FETCH_ALL_UAS_POSTS_REQUEST =
  "FETCH_ALL_UAS_POSTS_REQUEST";
export const FETCH_ALL_UAS_POSTS_FAILURE =
  "FETCH_ALL_UAS_POSTS_FAILURE";
export const FETCH_ALL_UAS_POSTS_SUCCESS =
  "FETCH_ALL_UAS_POSTS_SUCCESS";

export const CREATE_UAS_COMMENT_REQUEST =
  "CREATE_UAS_COMMENT_REQUEST";
export const CREATE_UAS_COMMENT_SUCCESS =
  "CREATE_UAS_COMMENT_SUCCESS";
export const CREATE_UAS_COMMENT_FAILURE =
  "CREATE_UAS_COMMENT_FAILURE";
export const CREATE_UAS_COMMENT_RESET = "CREATE_UAS_COMMENT_RESET";

export const UPDATE_UAS_COMMENT_REQUEST =
  "UPDATE_UAS_COMMENT_REQUEST";
export const UPDATE_UAS_COMMENT_SUCCESS =
  "UPDATE_UAS_COMMENT_SUCCESS";
export const UPDATE_UAS_COMMENT_FAILURE =
  "UPDATE_UAS_COMMENT_FAILURE";
export const UPDATE_UAS_COMMENT_RESET = "UPDATE_UAS_COMMENT_RESET";

export const DELETE_UAS_COMMENT_REQUEST =
  "DELETE_UAS_COMMENT_REQUEST";
export const DELETE_UAS_COMMENT_SUCCESS =
  "DELETE_UAS_COMMENT_SUCCESS";
export const DELETE_UAS_COMMENT_FAILURE =
  "DELETE_UAS_COMMENT_FAILURE";
export const DELETE_UAS_COMMENT_RESET = "DELETE_UAS_COMMENT_RESET";

export const CREATE_UAS_REPLY_REQUEST = "CREATE_UAS_REPLY_REQUEST";
export const CREATE_UAS_REPLY_SUCCESS = "CREATE_UAS_REPLY_SUCCESS";
export const CREATE_UAS_REPLY_FAILURE = "CREATE_UAS_REPLY_FAILURE";
export const CREATE_UAS_REPLY_RESET = "CREATE_UAS_REPLY_RESET";

export const UPDATE_UAS_REPLY_REQUEST = "UPDATE_UAS_REPLY_REQUEST";
export const UPDATE_UAS_REPLY_SUCCESS = "UPDATE_UAS_REPLY_SUCCESS";
export const UPDATE_UAS_REPLY_FAILURE = "UPDATE_UAS_REPLY_FAILURE";
export const UPDATE_UAS_REPLY_RESET = "UPDATE_UAS_REPLY_RESET";

export const DELETE_UAS_REPLY_REQUEST = "DELETE_UAS_REPLY_REQUEST";
export const DELETE_UAS_REPLY_SUCCESS = "DELETE_UAS_REPLY_SUCCESS";
export const DELETE_UAS_REPLY_FAILURE = "DELETE_UAS_REPLY_FAILURE";
export const DELETE_UAS_REPLY_RESET = "DELETE_UAS_REPLY_RESET";

export const CREATE_UAS_LIKEPOST_REQUEST =
  "CREATE_UAS_LIKEPOST_REQUEST";
export const CREATE_UAS_LIKEPOST_SUCCESS =
  "CREATE_UAS_LIKEPOST_SUCCESS";
export const CREATE_UAS_LIKEPOST_FAILURE =
  "CREATE_UAS_LIKEPOST_FAILURE";
export const CREATE_UAS_LIKEPOST_RESET =
  "CREATE_UAS_LIKEPOST_RESET";

export const CREATE_UAS_SAVEPOST_REQUEST =
  "CREATE_UAS_SAVEPOST_REQUEST";
export const CREATE_UAS_SAVEPOST_SUCCESS =
  "CREATE_UAS_SAVEPOST_SUCCESS";
export const CREATE_UAS_SAVEPOST_FAILURE =
  "CREATE_UAS_SAVEPOST_FAILURE";
export const CREATE_UAS_SAVEPOST_RESET =
  "CREATE_UAS_SAVEPOST_RESET";

export const CREATE_UAS_LIKECOMMENT_REQUEST =
  "CREATE_UAS_LIKECOMMENT_REQUEST";
export const CREATE_UAS_LIKECOMMENT_SUCCESS =
  "CREATE_UAS_LIKECOMMENT_SUCCESS";
export const CREATE_UAS_LIKECOMMENT_FAILURE =
  "CREATE_UAS_LIKECOMMENT_FAILURE";
export const CREATE_UAS_LIKECOMMENT_RESET =
  "CREATE_UAS_LIKECOMMENT_RESET";

export const FETCH_UAS_LIKEDPOST_RESET =
  "FETCH_UAS_LIKEDPOST_RESET";
export const FETCH_UAS_LIKEDPOST_REQUEST =
  "FETCH_UAS_LIKEDPOST_REQUEST";
export const FETCH_UAS_LIKEDPOST_FAILURE =
  "FETCH_UAS_LIKEDPOST_FAILURE";
export const FETCH_UAS_LIKEDPOST_SUCCESS =
  "FETCH_UAS_LIKEDPOST_SUCCESS";
