import {
    CREATE_TIMELINE_SCHEDULE_REQUEST, 
    CREATE_TIMELINE_SCHEDULE_SUCCESS, 
    CREATE_TIMELINE_SCHEDULE_FAILURE, 
    CREATE_TIMELINE_SCHEDULE_RESET,
    FETCH_TIMELINE_SCHEDULE_FAILURE,
    FETCH_TIMELINE_SCHEDULE_REQUEST,
    FETCH_TIMELINE_SCHEDULE_SUCCESS,

} from './TimelineTypes'
import axios from 'axios'
export const createTimelineScheduleReset = () => {
    return {
        type: CREATE_TIMELINE_SCHEDULE_RESET,
    }
}

const createTimelineScheduleRequest = () => {
    return {
        type: CREATE_TIMELINE_SCHEDULE_REQUEST,
    }
}

const createTimelineScheduleSuccess = success => {
    return {
        type: CREATE_TIMELINE_SCHEDULE_SUCCESS,
        payload: success
    }
}

const createTimelineScheduleFailure = error => {
    return {
        type: CREATE_TIMELINE_SCHEDULE_FAILURE,
        payload : error
    }
}

export const createTimelineSchedule = (data) => {

    return (dispatch) => {

        dispatch(createTimelineScheduleRequest());
        
        
        
        axios.post('/postTimelineSchedule', data)
        .then(response => {
            const timelineIssue = response.data
            dispatch(createTimelineScheduleSuccess(timelineIssue))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createTimelineScheduleFailure(errorMsg))
        })
        
    }

}


const fetchTimelineScheduleRequest = () => {
    return {
        type: FETCH_TIMELINE_SCHEDULE_REQUEST,
    }
}

const fetchTimelineScheduleSuccess = success => {
    return {
        type: FETCH_TIMELINE_SCHEDULE_SUCCESS,
        payload: success
    }
}

const fetchTimelineScheduleFailure = error => {
    return {
        type: FETCH_TIMELINE_SCHEDULE_FAILURE,
        payload : error
    }
}

export const fetchTimelineSchedule = (data) => {
    
    return (dispatch) => {
        
        dispatch(fetchTimelineScheduleRequest());
        
        
        axios.post('/getTimelineSchedules' , data)
        .then(response => {
            const timelineSchedule = response.data
            dispatch(fetchTimelineScheduleSuccess(timelineSchedule))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchTimelineScheduleFailure(errorMsg))
        })
        
    }

}
