import React from 'react'
import { Card, CardBody, CardTitle, Container, Row, Col, Progress } from "reactstrap";
import { connect,useDispatch } from 'react-redux';

import { useEffect, useState } from "react";
import {buyTutoringHours} from "redux/Payments/PaymentsActions"
import BuyTutoringHoursModal from 'components/Modal/BuyTutoringHoursModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { studentsLifetimePlan } from 'util/DataList';
const TutoringHoursAvailable = (props) => {

  const history = useHistory()
  const dispatch = useDispatch();
  
  const [subscribe, setSubscribe] = useState('lock');
  const [remainingHours, setRemainingHours] = useState(0)

  useEffect(() => {
    setRemainingHours(props.userDetails.tutorHours)
  }, [])
  
  useEffect(() => {

    if(props.updateTutoringHoursAvailable.updatetutoringhoursavailable.length){
      setRemainingHours(props.updateTutoringHoursAvailable.updatetutoringhoursavailable[0].tutorHours)
    }

  }, [props.updateTutoringHoursAvailable])
  
  const fetchSubscriptionDetails = () => {
    
      if(props.userDetails.ultimateAdvisingStudent == true || props.userDetails.ultimateAdvisingStudent == "true"  || studentsLifetimePlan.includes(props.userDetails.email)){

        setSubscribe('unlock')

      }
      else{   
        if(props.fetchDetailsSubscription.fetchdetailssubscription.length){

        const subsDetails = props.fetchDetailsSubscription.fetchdetailssubscription[0]

        
        
            if(subsDetails.status == "active" || subsDetails.status == "trialing"){
                setSubscribe('unlock')
            }
            else{

                const date = new Date();
                const secondsSinceEpoch = Math.floor(date.getTime() / 1000);
                
                if(subsDetails.cancel_at_period_end){
                    if(secondsSinceEpoch > subsDetails.canceled_at){
                        setSubscribe('lock')
                    }
                    else{
                        setSubscribe('unlock')
                    }
                }
                else{
                    setSubscribe('lock')
                }
            
            }

        

        }
        else{
            setSubscribe('lock')
        }
      }
    
        
  }

  useEffect(() => {

    fetchSubscriptionDetails()

  }, [])

  useEffect(() => {

    fetchSubscriptionDetails()

  }, [props.fetchDetailsSubscription])


  useEffect(() => {

    fetchSubscriptionDetails()
    setRemainingHours(props.userDetails.tutorHours)
  }, [props.userDetails])
  
  const modalBuyTutoringHoursModal = props.buyTutoringHoursModalStatus.status;

  const toggleBuyTutoringHoursModal = () =>{
    dispatch(buyTutoringHours(!props.buyTutoringHoursModalStatus.status));
    
  } 

  return (
    <>
        <Card className="card-stats mb-4 mb-xl-0 w-100">
            <CardBody>
            <Row>
                <div className="col">
                <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                >
                    PREMIUM TUTORING HOURS
                </CardTitle>
                {subscribe == "unlock" ? 
                  remainingHours != 0 ?
                  <span className="h2 font-weight-bold mb-0">{remainingHours} Remaining</span> :

                  <a className={ props.adminUser.admin ? "btn btn-primary mt-2 disabled" : "btn btn-primary mt-2"} onClick={(e) => {e.preventDefault(); toggleBuyTutoringHoursModal()}}>GET TUTORING HOURS</a>
                  :
                  <a className={ props.adminUser.admin ? "btn btn-info mt-2 disabled" : "btn btn-info mt-2"}  onClick={(e) => {e.preventDefault(); history.push('/client/plans')}}><span className="fas fa-lock text-yellow"></span>&nbsp;UPGRADE TO PRO</a>
                }

                
                </div>
                
                  <Col className="col-auto">
                  <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                      <i className="fas fa-glasses" />
                  </div>
                  </Col>
                
            </Row>
            
            </CardBody>
        </Card>
        <BuyTutoringHoursModal modalBuyTutoringHoursModal={modalBuyTutoringHoursModal} toggleBuyTutoringHoursModal={toggleBuyTutoringHoursModal}/>
    </>
  )
}
const mapStateToProps = state => {
  return {
    userDetails : state.loginAuth.userDetails,
    fetchDetailsSubscription : state.fetchDetailsSubscription,
    buyTutoringHoursModalStatus : state.buyTutoringHoursModalStatus,
    updateTutoringHoursAvailable : state.updateTutoringHoursAvailable,
    adminUser : state.adminUser
  }
}
 
export default connect(mapStateToProps)(TutoringHoursAvailable)