/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { connect, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { app, db } from "../../firebase";
import { getDocs, getDoc, setDoc, addDoc, collection, query, where, onSnapshot, doc, orderBy, limit } from "firebase/firestore";
import {useHistory} from 'react-router-dom'
import { useRef, useEffect,useState } from "react";
import {set, useForm} from 'react-hook-form';
import Select from 'react-select';
import {customDropdown} from "util/reactCustomStyles";
import { signupUser,signupUserReset } from "redux/UserAuthentication/RegisterActions";
import Swal from 'sweetalert2'
import { loginUser } from "redux/UserAuthentication/LoginActions";

const McatPowerBookRegister = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [confirmPasswordMatch, setConfirmPasswordMatch] = useState(false);
  const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();
  
  const messageRequired = "This field is required.";

  const [university, setUniversity] = useState('');
  const [universities, setUniversities] = useState([])
 const [schools, setschool] = useState([])
   useEffect(() => {
        fetchSchool();
        return () => {
            setschool([]); 
            };
    }, []);

    const fetchSchool = async () => {
        const userCollection = collection(db, "universitiesList");
        const querySnapshot = await getDocs(userCollection);
        querySnapshot.forEach((doc) => {
            let sdoc = doc.data()
            setschool(sdoc)
        });
    } 

  useEffect(() => {

    register("inputUsername", { required: messageRequired
     });
    register("inputFullname", { required: messageRequired });
    register("inputSchoolname", { required: messageRequired });
    register("inputPhoneNumber", { required: messageRequired });
    register("inputPassword", { 
      required: messageRequired,
      pattern: {
        // value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          value : "^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$",
            message: "Minimum eight characters, at least one letter, one number and one special character."
        } ,
       
      });
    register("inputConfirmPassword", { required: messageRequired });

    register("inputEmail", { 
        required: messageRequired, 
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address."
        } 
    });  

  }, [])

  const handleUniversityChange = (selectedOption) => {
    setValue("inputSchoolname", selectedOption.value)
    setUniversity(selectedOption.value);
  };

   useEffect(() => {
      let universitiesList = schools.universities
      if(universitiesList){
        universitiesList = [...new Set(universitiesList.map((value, index) => {return {value:value, label: value}}))];
        console.log(universitiesList)
        setUniversities(universitiesList)
      
      }

  }, [schools])

  if(props.signupUser.loading){
    Swal.fire({
      allowOutsideClick : false,
      didOpen: () => {
        Swal.showLoading()
        
      }
    });

  }
  else{
    
    
    if(props.signupUser.signupuser.length && typeof props.signupUser.error === "undefined"){
      Swal.fire({
          title: 'Success!',
          text: 'Registered successfully!',
          icon : 'success',
          timer: 3000
          
        }
      ).then(function (result) {
          
        dispatch(loginUser({email : getValues('inputEmail'), password : getValues('inputConfirmPassword')}));

        // history.push("/auth/login");
      
      });

      dispatch(signupUserReset());
      
    }

    else if(typeof props.signupUser.error !== "undefined"){

      if(props.signupUser.error.hasOwnProperty('email')){
        Swal.fire({
          title:'Failed!',
          text:'Email is already in use!',
          icon :'error',
          timer: 3000
          
        })

        
      }
      else if(props.signupUser.error.hasOwnProperty('handle')){
        Swal.fire({
          title:'Failed!',
          text:'Username is already in use!',
          icon :'error',
          timer: 3000
          
        })

        
      }
      else if(props.signupUser.error.hasOwnProperty('general')){
        Swal.fire({
          title:'Failed!',
          text:'Something went wrong, Please try again!',
          icon :'error',
          timer: 3000
          
        })

        
      }
      else{
        Swal.fire({
          title:'Failed!',
          text:'Something went wrong, Please try again!',
          icon :'error',
          timer: 3000
          
        })
      }
          
      dispatch(signupUserReset());       
      
    }

  }

  const formatDate = (date) => {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
  }

  const onSubmit = (data) => {
    if(data.inputPassword == data.inputConfirmPassword){
        const formData = new FormData();
        formData.append("name",data.inputFullname);
        formData.append("file",fileItem);
        formData.append('name', data.inputFullname)
        formData.append('university', data.inputSchoolname)
        formData.append('email', data.inputEmail)
        formData.append('password', data.inputPassword)
        formData.append('confirmPassword', data.inputConfirmPassword)
        formData.append('handle', data.inputUsername)
        formData.append('tpcEntryDate', formatDate(new Date()))
        formData.append('subscriptions', [])
        formData.append('tutorHours', 5)
        formData.append('phoneNumber', data.inputPhoneNumber)
        formData.append('admin', false)

        dispatch(signupUser(formData));

    }
    else{
       setConfirmPasswordMatch(true);
    } 
  }

    const fileInputRef = useRef(null);
    const [imagePreview, setImagePreview] = useState("");
    const [fileItem, setFileItem] = useState(5);

    const handleFileChange = (e) => {
        const file = e.target.files[0] 
        console.log(file)
        if (file) {
          
            const maxImageSizeInMB = 10;
            let fileSizeInMB = file.size / (1024 * 1024);
            const allowedImgTypes = [
                "image/jpg",
                "image/png",
                "image/jpeg",
                "image/gif",
            ];
            if (allowedImgTypes.includes(file.type)) {
                if (fileSizeInMB > maxImageSizeInMB) {
                    Swal.fire({
                        title: "Maximum limit!",
                        text:
                        file.name +
                        " File size exceeds the maximum limit of " +
                        maxImageSizeInMB +
                        "MB",
                        icon: "error",
                    });
                    return;
                }else{
                    setFileItem(file)
                    setImagePreview(file)
                } 
            }
        }
    };
    // Files
    const clickInputFile = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

  return (
    <>
      <Col >
        <Card className="bg-secondary shadow border-0">
        
          <CardBody className="px-lg-5 py-lg-5">
            <CardTitle tag="h2" className="text-center">
                Sign Up
            </CardTitle>
            <Form role="form" onSubmit={handleSubmit(onSubmit)} >
                <Row className="mb-3">
                <Col md="6" className="p-3">
                    <p>If you have purchased MCAT Powerbook, we will be able to upgrade your account. Just upload a screenshot of your proof of purchased.</p>
                    <Button
                        onClick={(e) => clickInputFile(e)}
                        color="success"
                        size=""
                        className=""
                        >
                    <i className="fas fa-solid fa-camera fa-2xs mr-2"></i>
                        Upload
                    </Button>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        // {...register("file")}
                        style={{ display: "none" }}
                    />

                    
                    <div className=" d-flex flex-row align-items-center mt-3" >
                    {
                        imagePreview
                        ? 
                  
                          <img
                                src={URL.createObjectURL(imagePreview)}
                                alt="Mcat powerbook purchased screenshot"
                                className=" m-2 rounded d-block "
                                style={{width: "100%", height: "auto"}}
                                
                            />
                        
                        :""
                    }
                    </div>
                   
                </Col>
              <Col md="6">
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="ni ni-hat-3" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Full Name"
                        className="form-control-alternative"
                        id="inputFullname"
                        name="inputFullname"
                        
                        onChange={e => setValue("inputFullname", e.target.value) }
                        type="text"
                    />
                    
                    </InputGroup>
                    { errors.inputFullname && <small className="text-danger">{errors.inputFullname.message}</small> }
                </FormGroup>
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="ni ni-email-83" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Email"
                        className="form-control-alternative"
                        id="inputEmail"
                        name="inputEmail"
                        
                        onChange={e => {setValue("inputEmail", e.target.value); setValue("inputUsername", e.target.value)} }
                        type="email"
                    />
                    
                    </InputGroup>
                    { errors.inputEmail && <small className="text-danger">{errors.inputEmail.message}</small> }
                </FormGroup>

                    <FormGroup>
                    <Select 
                        placeholder="Select University" 
                        options={universities} 
                        styles={customDropdown } 
                        id="inputSchoolname"
                        name="inputSchoolname" 
                        onChange={handleUniversityChange}
                        value={universities.find((option) => option.value === university)}
                        />
                        { errors.inputSchoolname && <small className="text-danger">{errors.inputSchoolname.message}</small> }
                    </FormGroup>
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="fas fa-phone" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Phone number"
                        className="form-control-alternative"
                        id="inputPhoneNumber"
                        name="inputPhoneNumber"
                        
                        onChange={e => {setValue("inputPhoneNumber", e.target.value);} }
                        type="text"
                    />
                    
                    </InputGroup>
                    { errors.inputPhoneNumber && <small className="text-danger">{errors.inputPhoneNumber.message}</small> }
                </FormGroup>


                <FormGroup>
                    <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Password"
                        className="form-control-alternative"
                        id="inputPassword"
                        name="inputPassword"
                        
                        onChange={e => setValue("inputPassword", e.target.value) }
                        type="password"
                    />
                    
                    </InputGroup>

                    { errors.inputPassword && <small className="text-danger">{errors.inputPassword.message}</small> }
                </FormGroup>
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Confirm Password"
                        className="form-control-alternative"
                        id="inputConfirmPassword"
                        name="inputConfirmPassword"
                        
                        onChange={e => setValue("inputConfirmPassword", e.target.value) }
                        type="password"
                    />
                    
                    </InputGroup>

                    { errors.inputConfirmPassword && <small className="text-danger">{errors.inputConfirmPassword.message}</small> }
                    {confirmPasswordMatch && <small className="text-danger">Passwords do not match.</small> }
                </FormGroup>

                <div className="text-center">
                    <Button className="mt-4" color="primary" type='submit'>
                    Sign Up
                    </Button>
                </div>
              </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const mapStateToProps = state => {
  return {
    signupUser : state.signupUser,
  }
}

export default connect(mapStateToProps)(McatPowerBookRegister);
