import React, {useRef, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import moment from 'moment-timezone';

import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Badge,
    Input,
    Image,
    Form,
    FormGroup,
    Label,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
    TabContent, TabPane, Nav, NavItem, NavLink, 
} from "reactstrap";

import Header from 'components/Headers/Header';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import { getUserData } from 'redux/UserAuthentication/LoginActions';
import Payment from './Payment';
import '../../assets/css/custom-modal.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { studentsLifetimePlan } from 'util/DataList';
import ProMember from './ProMember';
const CommunityProMember = (props) => {

    const dispatch = useDispatch()
    const [plan , setPlan] = useState('')
    const [subscribe, setSubscribe] = useState('lock');
    const [loading, setLoading] = useState(true);
    
     useEffect(() => {
        setTimeout(() => setLoading(false), 2000)
    }, []);


    const fetchSubscriptionDetails = () => {
        
        if(props.userDetails.ultimateAdvisingStudent ===  true || props.userDetails.ultimateAdvisingStudent ==  "true" || studentsLifetimePlan.includes(props.userDetails.email)){
            setSubscribe('unlock')
        }
        else{
            if(props.fetchDetailsSubscription.fetchdetailssubscription.length){

            const subsDetails = props.fetchDetailsSubscription.fetchdetailssubscription[0]

                if(subsDetails.status == "active" || subsDetails.status == "trialing"){
                    setSubscribe('unlock')
                }
                else{

                    const date = new Date();
                    const secondsSinceEpoch = Math.floor(date.getTime() / 1000);
                    
                    if(subsDetails.cancel_at_period_end){
                        if(secondsSinceEpoch > subsDetails.canceled_at){
                            setSubscribe('lock')
                        }
                        else{
                            setSubscribe('unlock')
                        }
                    }
                    else{
                        setSubscribe('lock')
                    }
                
                }
            }
            else{
                setSubscribe('lock')
            }
        }
    }


    useEffect(() => {

    fetchSubscriptionDetails()

    }, [props.fetchDetailsSubscription, studentsLifetimePlan, props.userDetails ])


    return (  
        <>  
            <Header></Header>
            <ProMember/>
        </>
    )
  
}

const mapStateToProps = state  => {
    return {
        userDetails: state.loginAuth.userDetails,
        fetchDetailsSubscription : state.fetchDetailsSubscription,

       
    }
      
}

export default connect(mapStateToProps)(CommunityProMember)

