import React from 'react'
import GoogleCalendarWidget from "components/Widgets/GoogleCalendarWidget";
import {
    Card,
    Container,
    CardHeader,
    Row,
    Form,
    Col,
    FormGroup
} from "reactstrap";
import Header from 'components/Headers/Header';
import Plans from './Plans';

const TpcEvents = () => {

    return (
    <>
        <Header></Header>
        <Container className="mt--7" fluid>
            <Card className='mb-3'>
                <CardHeader className="border-0">
                    <h3 className="mb-0">TPC Events</h3>
                </CardHeader>
            </Card>
            <GoogleCalendarWidget></GoogleCalendarWidget>

        </Container>
        <Plans></Plans>
    </>
  )
}

export default TpcEvents