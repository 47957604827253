import React,{ useEffect }  from 'react'
import {

    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table

} from "reactstrap";

const SchoolPostbacModal = (props) => {

    useEffect(() => {
      console.log(props.medicalSchoolDetails)
    }, [])
    
  return (
    <>
        <Col lg="12 text-center mb-2"><h2 className="text-warning">DETAILS</h2></Col>

        <Col lg="12 text-center "><p className="text-default">{props.medicalSchoolDetails.overview.description}</p></Col>

        {props.medicalSchoolDetails.programContactInformation.name ?
            <Col md="12 mt-3 table-responsive">
                <label className="font-weight-bolder">PROGRAM CONTACT INFORMATION</label>

                
                    <Table className="align-items-center rounded shadow" striped bordered>
                        <thead className="text-default">
                            
                            
                      
                            <tr>
                                <th>Name</th>
                                <td>{props.medicalSchoolDetails.programContactInformation.name}</td>
                            </tr>

                            <tr>
                                <th>Phone</th>
                                <td>{props.medicalSchoolDetails.programContactInformation.phone}</td>
                            </tr>

                            <tr>
                                <th>Email</th>
                                <td>{props.medicalSchoolDetails.programContactInformation.email}</td>
                            </tr>
                            

                        </thead> 
                    </Table>
                

            </Col>
        : "" }


        {props.medicalSchoolDetails.programDirectorInformation.name ?
            <Col md="12 mt-3 table-responsive">
                <label className="font-weight-bolder">PROGRAM DIRECTOR INFORMATION</label>

                
                    <Table className="align-items-center rounded shadow" striped bordered>
                        <thead className="text-default">
                            
                            
                            <tr>
                                <th>Name</th>
                                <td>{props.medicalSchoolDetails.programDirectorInformation.name}</td>
                            </tr>

                            <tr>
                                <th>Phone</th>
                                <td>{props.medicalSchoolDetails.programDirectorInformation.phone}</td>
                            </tr>

                            <tr>
                                <th>Email</th>
                                <td>{props.medicalSchoolDetails.programDirectorInformation.email}</td>
                            </tr>
                            
                            

                        </thead> 
                    </Table>
                

            </Col>
        : "" }

        <Col md="12 mt-3 table-responsive">
            <label className="font-weight-bolder">PROGRAM FOCUS</label>
            <br></br>
            

            {props.medicalSchoolDetails.programFocus.length ? 
                          
                props.medicalSchoolDetails.programFocus.map((value, index) => 
                
                <><Badge pill="true" color="info">{value}</Badge>&nbsp;</>
                
                )
            
                :
                "Not Provided"
            }
        </Col>
        
        <Col md="12 mt-3 table-responsive">
            <label className="font-weight-bolder">PROGRAM REQUIREMENTS</label>

            {props.medicalSchoolDetails.programRequirements.faq ?
                <ul className='text-default'>
                    {Object.entries(props.medicalSchoolDetails.programRequirements.requirements).map(([key, value]) => (
                        <li>value</li>
                    ))}
                </ul>
            : "" }

            {props.medicalSchoolDetails.programRequirements.faq ?
                <Table className="align-items-center rounded shadow" striped bordered>
                    <thead className="text-default">
                        
                        
                        {Object.entries(props.medicalSchoolDetails.programRequirements.faq).map(([key, value]) => (
                            <tr>
                                <th>{value.question}</th>
                                <td>{value.answer}</td>
                            </tr>
                        ))}
                        
                        

                    </thead> 
                </Table>
            : "" }
        </Col>

        <Col md="12 mt-3 table-responsive">
            <label className="font-weight-bolder">PROGRAM STRUCTURE</label>

            {props.medicalSchoolDetails.programStructure ?
                <Table className="align-items-center rounded shadow" striped bordered>
                    <thead className="text-default">
                        
                        
                        {Object.entries(props.medicalSchoolDetails.programStructure).map(([key, value]) => (
                            <tr>
                                <th>{value.question}</th>
                                <td>{value.answer}</td>
                            </tr>
                        ))}
                        
                        

                    </thead> 
                </Table>
            : "" }
        </Col>

        <Col md="12 mt-3 table-responsive">
            <label className="font-weight-bolder">PROGRAM INFORMATION</label>

            {props.medicalSchoolDetails.programInformation ?
                <Table className="align-items-center rounded shadow" striped bordered>
                    <thead className="text-default">
                        
                        
                        {Object.entries(props.medicalSchoolDetails.programInformation).map(([key, value]) => (
                            <tr>
                                <th>{value.question}</th>
                                <td>{value.answer}</td>
                            </tr>
                        ))}
                        
                        

                    </thead> 
                </Table>
            : "" }
        </Col>

        <Col lg="12 text-center mt-4">
            <a href={props.medicalSchoolDetails.admUrl} target='__blank' className='btn btn-info'>Visit Website</a>
        </Col>

    </>
  )
}

export default SchoolPostbacModal