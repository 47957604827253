import React, { useEffect } from 'react'
import { Button, Modal, ModalBody, ModalFooter, Card, CardHeader, Row, Col, FormGroup, Input, CardBody, Form } from 'reactstrap';
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Months from 'components/Options/Months';
import Years from 'components/Options/Years';
import ExperienceTypes from 'components/Options/WorkActivities/ExperienceTypes';
import {useForm} from 'react-hook-form';
import Swal from 'sweetalert2'
import { createWorkActivity, createWorkActivityReset, editWorkActivity, newWorkActivity,updateWorkActivity, updateWorkActivityReset } from 'redux/WorkActivities/WorkActivitiesActions';

import  Select  from 'react-select';
import { customDropdown } from 'util/reactCustomStyles';
import { experienceType, workActivitiesType, monthsList, yearsList } from 'util/DataList';
const WorkActivitiesModal = (props) => {

    let toggleAddWorkActivities = props.toggleAddWorkActivities;
    let modalAddWorkActivities = props.modalAddWorkActivities;

    const dispatch = useDispatch();
    const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();
    const [repeatedHours, setRepeatedHours] = useState(false);
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const [anticipatedHours, setAnticipatedHours] = useState(false);
    const messageRequired = "This field is required.";
    const messageHoursValidation = "Hours must be between 0 and 99999";
    const today = new Date();
    
    const [workactivityId, setWorkactivityId] = useState('');


    const [years, setYears] = useState([]);

    const onChangeRepeatedHours = (event) => {
        
        const repeated = event.target.value == "yes" ? true : false; 

        if (repeated) {
            register("inputSecondStartMonth", { required: messageRequired });
            register("inputSecondStartYear", { required: messageRequired, 
              validate: { 
                validationFirstScenario : value => validationForStartDate(value, "inputSecond", "inputSecond", "greater-than")
              }  
            });
            register("inputSecondEndMonth", { required: messageRequired });
            register("inputSecondEndYear", { required: messageRequired });
            register("inputSecondCompletedHours", 
            { 
              required: messageRequired, 
              type:"number", 
              min:{
                value:0, 
                message:messageHoursValidation
              }, 
              max:{
                value:99999, 
                message:messageHoursValidation
              }
            });
            
        }
        else{

            unregister(["inputSecondStartMonth","inputSecondStartYear","inputSecondEndMonth","inputSecondEndYear","inputSecondCompletedHours","inputThirdStartMonth","inputThirdStartYear","inputThirdEndMonth","inputThirdEndYear","inputThirdCompletedHours","inputFourthStartMonth","inputFourthStartYear","inputFourthEndMonth","inputFourthEndYear","inputFourthCompletedHours"]);

        }
        
        setValue("repeated", repeated)

        return setRepeatedHours(repeated);

    }

    const validationForRepeatedEndDateIfEmpty = (inputEndMonthName, inputEndYearName) => {
      if(getValues(inputEndMonthName) && getValues(inputEndYearName)){
          return true;
      }
      else{
          return false;
      }
    }
    const validationForStartDate = (value, inputEndDateName, inputStartDateName, sign) => {

        let endDate = new Date(Date.parse("1 " + months[getValues(inputEndDateName+"EndMonth") - 1 ] + " " + getValues(inputEndDateName+"EndYear")));
        let startDate =  new Date(Date.parse("1 " + months[getValues(inputStartDateName+"StartMonth") - 1 ] + " " + value));
        
        if(sign == "less-than"){
          return endDate <= startDate;
        }
        else{
          return endDate >= startDate;
        }
    }

    const onChangeAnticipatedHours = (event) => {
        
        const anticipated = event.target.value == "yes" ? true : false; 
        
        if (anticipated) {
            register("inputAnticipatedStartMonth", { required: messageRequired });
            register("inputAnticipatedStartYear", { required: messageRequired });
            register("inputAnticipatedEndMonth", { required: messageRequired });
            register("inputAnticipatedEndYear", { required: messageRequired });
            register("inputAnticipatedCompletedHours", { required: messageRequired, type:"number", min:{value:0, message:messageHoursValidation}, max:{value:99999, message:messageHoursValidation} });
            
        }
        else{

            unregister(["inputAnticipatedStartMonth","inputAnticipatedStartYear","inputAnticipatedEndMonth","inputAnticipatedEndYear","inputAnticipatedCompletedHours"]);
        }

        setValue("anticipated", anticipated)

        return setAnticipatedHours(anticipated);

    }

    const onChangeInputThirdStartMonth = (event) => {

      if(event.value != ""){
        register("inputThirdStartMonth");
        register("inputThirdStartYear", { validate : {
            validationFirstScenario : () => validationForRepeatedEndDateIfEmpty("inputSecondEndMonth","inputSecondEndYear"),// You must enter a 2nd start and end dates before entering 3rd start and end dates 
            validationSecondScenario : value => validationForStartDate(value, "inputSecond", "inputThird", "less-than"), // Your 3rd start date cannot be earlier than your 2nd end date.
            validationThirdScenario : value => validationForStartDate(value, "inputThird", "inputThird", "greater-than") // Your 3rd start date must begin before it's end date.
            
        }});

        setValue("inputThirdStartMonth", event.value)
      }
      else{
        unregister("inputThirdStartMonth");
        unregister("inputThirdStartYear");
      }

      validationForCompletedHours("inputThird");

    }

    const onChangeCompletedHours = (event, inputPrefix) => {

      setValue(inputPrefix+"CompletedHours", event.target.value)

      validationForCompletedHours(inputPrefix);
      
    }

    const validationForCompletedHours = (inputPrefix) => {

      if(getValues(inputPrefix+"StartMonth") || getValues(inputPrefix+"StartYear") || getValues(inputPrefix+"EndMonth") || getValues(inputPrefix+"EndYear")){
        register(inputPrefix+"CompletedHours", 
        { 
          required : messageRequired,
          type:"number", 
          min:{
            value:0, 
            message:messageHoursValidation
          }, 
          max:{
            value:99999, 
            message:messageHoursValidation
          }, 
          validate : {
            validationFirstScenario : () => {
              if(getValues(inputPrefix+"StartMonth") && getValues(inputPrefix+"StartYear") && getValues(inputPrefix+"EndMonth") && getValues(inputPrefix+"EndYear")){
                  return true;
              }
              else{
                  return false;
              }
            }
          }
        });
      }
      else{
        unregister(inputPrefix+"CompletedHours");
      }
    }

    const onChangeInputFourthStartMonth = (event) => {

      if(event.value != ""){
        register("inputFourthStartMonth");
        register("inputFourthStartYear", { validate : {
            validationFirstScenario : () => validationForRepeatedEndDateIfEmpty("inputThirdEndMonth","inputThirdEndYear"),// You must enter a 2nd start and end dates before entering 3rd start and end dates 
            validationSecondScenario : value => validationForStartDate(value, "inputThird", "inputFourth", "less-than"), // Your 3rd start date cannot be earlier than your 2nd end date.
            validationThirdScenario : value => validationForStartDate(value, "inputFourth", "inputFourth", "greater-than") // Your 3rd start date must begin before it's end date.
            
        }});
        setValue("inputFourthStartMonth", event.value)
      }
      else{
        unregister("inputFourthStartMonth");
        unregister("inputFourthStartYear");
      }

      validationForCompletedHours("inputFourth");

    }

    useEffect(() => {

        setYears(yearsList());
        
        register("inputTitle", { required: messageRequired });
        register("inputExperienceType", { required: messageRequired });
        register("inputExperienceName", { required: messageRequired });
        register("inputStartMonth", { required: messageRequired });
        register("inputStartYear", { 
          required: messageRequired, 
          validate: { 
            validationFirstScenario : value => validationForStartDate(value, "input", "input", "greater-than")
          }
        });
        register("inputEndMonth", { required: messageRequired });
        register("inputEndYear", { required: messageRequired });
        register("inputCompletedHours", { required: messageRequired, type:"number", min:{value:0, message:messageHoursValidation}, max:{value:99999, message:messageHoursValidation} });
        register("inputContactTitle"
        // , { required: messageRequired }
        ); 
        register("inputContactFirstName"
        // , { required: messageRequired }
        );
        register("inputContactLastName"
        // , { required: messageRequired }
        );
        register("inputContactPhone", 
        // { required: messageRequired }
        ); 

        register("inputContactEmail", { 
            // required: messageRequired, 
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address."
            } 
        });  

        register("inputLocation");
        register("inputOrganizationName");
        register("inputCountry");
        register("inputCity");
        register("inputExperienceDescription");
        register("inputMostMeaningfulExperienceDescription");

        register("inputWorkType")

        setValue("repeated", false)
        setValue("anticipated", false)
        

    },[])    


    useEffect(() => {
      if(props.editWorkActivity.status){
          
        const workActivityData  = findWorkActivity(props.fetchWorkActivities.workactivities,props.editWorkActivity.workactivityId);

        setWorkactivityId(props.editWorkActivity.workactivityId);

       
        setValue("inputTitle", (typeof workActivityData.experienceTitle != "undefined") ? workActivityData.experienceTitle : "")
        setValue("inputExperienceName", workActivityData.experienceName)
        setValue("inputExperienceType", workActivityData.experienceType)
        setValue("inputWorkType",(typeof workActivityData.workType != "undefined") ? workActivityData.workType : "")
        setValue("inputStartMonth", workActivityData.startMonth)
        setValue("inputStartYear", workActivityData.startYear)
        setValue("inputEndMonth", workActivityData.endMonth)
        setValue("inputEndYear", workActivityData.endYear)
        setValue("inputCompletedHours", workActivityData.completedHours)

        
        if(workActivityData.repeated){
          setValue("repeated", workActivityData.repeated)
          setRepeatedHours(workActivityData.repeated);

          const repeatedNameArr = ["Second", "Third", "Fourth"];
          for (let i = 0; i < workActivityData.repeatedHours.length; i++) {
            if(typeof workActivityData.repeatedHours[i] !== "undefined"){
              setValue("input"+repeatedNameArr[i]+"StartMonth", workActivityData.repeatedHours[i]["input"+repeatedNameArr[i]+"StartMonth"])
              setValue("input"+repeatedNameArr[i]+"StartYear", workActivityData.repeatedHours[i]["input"+repeatedNameArr[i]+"StartYear"])
              setValue("input"+repeatedNameArr[i]+"EndMonth", workActivityData.repeatedHours[i]["input"+repeatedNameArr[i]+"EndMonth"])
              setValue("input"+repeatedNameArr[i]+"EndYear", workActivityData.repeatedHours[i]["input"+repeatedNameArr[i]+"EndYear"])
              setValue("input"+repeatedNameArr[i]+"CompletedHours", workActivityData.repeatedHours[i]["input"+repeatedNameArr[i]+"CompletedHours"])
            }
          }
        }

        if(workActivityData.anticipated){
          setValue("anticipated", workActivityData.anticipated)
          setAnticipatedHours(workActivityData.anticipated);

          setValue("inputAnticipatedStartMonth", workActivityData.inputAnticipatedStartMonth)
          setValue("inputAnticipatedStartYear", workActivityData.inputAnticipatedStartYear)
          setValue("inputAnticipatedEndMonth", workActivityData.inputAnticipatedEndMonth)
          setValue("inputAnticipatedEndYear", workActivityData.inputAnticipatedEndYear)
          setValue("inputAnticipatedCompletedHours", workActivityData.inputAnticipatedCompletedHours)
        }
        
        setValue("inputLocation", (workActivityData.location != "undefined") ? workActivityData.location : "")
        setValue("inputOrganizationName", workActivityData.organizationName)
        setValue("inputCountry", workActivityData.country)
        setValue("inputCity", workActivityData.city)
        setValue("inputContactTitle", workActivityData.contactTitle)
        setValue("inputContactFirstName", workActivityData.contactFirstName)
        setValue("inputContactLastName", workActivityData.contactLastName)
        setValue("inputContactPhone", workActivityData.contactPhone)
        setValue("inputContactEmail", workActivityData.contactEmail)

        setValue("inputExperienceDescription", workActivityData.experienceDescription)
        setValue("inputMostMeaningfulExperienceDescription", workActivityData.experienceDescription)
        
        dispatch(editWorkActivity({
            status : false,
            workactivityId : ""
        }));

      }

      

    },[props.editWorkActivity])
    
    useEffect(() => {
      if(props.newWorkActivity.status){
        setWorkactivityId('');

        setValue("inputExperienceName", "")
        setValue("inputExperienceType", "")
        setValue("inputTitle", "")
        setValue("inputStartMonth", "")
        setValue("inputStartYear", "")
        setValue("inputEndMonth", "")
        setValue("inputEndYear", "")
        setValue("inputCompletedHours", "")

        setValue("inputWorkType", "")
        
        setValue("repeated", false)
        setRepeatedHours(false);

        const repeatedNameArr = ["Second", "Third", "Fourth"];
        for (let i = 0; i < repeatedNameArr.length; i++) {
          
            setValue("input"+repeatedNameArr[i]+"StartMonth", "")
            setValue("input"+repeatedNameArr[i]+"StartYear", "")
            setValue("input"+repeatedNameArr[i]+"EndMonth", "")
            setValue("input"+repeatedNameArr[i]+"EndYear", "")
            setValue("input"+repeatedNameArr[i]+"CompletedHours", "")
          
        }
        

    
        setValue("anticipated", false)
        setAnticipatedHours(false);

        setValue("inputAnticipatedStartMonth", "")
        setValue("inputAnticipatedStartYear", "")
        setValue("inputAnticipatedEndMonth", "")
        setValue("inputAnticipatedEndYear", "")
        setValue("inputAnticipatedCompletedHours", "")
        
        
        setValue("inputLocation", "")
        setValue("inputOrganizationName", "")
        setValue("inputCountry", "")
        setValue("inputCity", "")
        setValue("inputContactTitle", "")
        setValue("inputContactFirstName", "")
        setValue("inputContactLastName", "")
        setValue("inputContactPhone", "")
        setValue("inputContactEmail", "")

        setValue("inputExperienceDescription", "")
        setValue("inputMostMeaningfulExperienceDescription", "")
        
        
        dispatch(newWorkActivity({status : false}));

      }
    }, [props.newWorkActivity])
    

    if(props.createWorkActivities.loading)
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });

      
    else{
     
      
      if(props.createWorkActivities.workactivity.length && typeof props.createWorkActivities.error === "undefined"){
        Swal.fire({
            title: 'Success!',
            text: 'Successfully added new Work/Activity!',
            icon : 'success',
            timer: 3000
            
          }
        )
        dispatch(createWorkActivityReset());
        
      }

      else if(typeof props.createWorkActivities.error !== "undefined"){
        Swal.fire({
            title:'Failed!',
            text:'Failed to add new Work/Activity!',
            icon :'error',
            timer: 3000
            
          }
        )     
        dispatch(createWorkActivityReset());       
        
      }

    }


    if(props.updateWorkActivities.loading)
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });

      
    else{
     
      
      if(props.updateWorkActivities.workactivity.length && typeof props.updateWorkActivities.error === "undefined"){
        Swal.fire({
            title: 'Success!',
            text: 'Successfully updated Work/Activity!',
            icon : 'success',
            timer: 3000
            
          }
        )
        dispatch(updateWorkActivityReset());
        
        
      }

      else if(typeof props.updateWorkActivities.error !== "undefined"){
        Swal.fire({
            title:'Failed!',
            text:'Failed to update Work/Activity!',
            icon :'error',
            timer: 3000
          }
        )            

        dispatch(updateWorkActivityReset());
        
      }

      

    }


    const findWorkActivity = (arr,workactivityId) => {
      
      return arr.find(x => x.workActivityId === workactivityId);
    }

    


    const onSubmit = (data) => {
      
      data['userId'] = props.userDetails.userId;

      if(data['inputExperienceType'] != "Other"){
        if(data['inputExperienceType'] == "Community Service/Volunteer - Medical/Clinical" || data['inputExperienceType'] == "Paid Employment - Medical/Clinical"){
          data['inputWorkType'] = "Clinical"
        }
        else if(data['inputExperienceType'] == "Community Service/Volunteer - Not Medical/Clinical"){
          data['inputWorkType'] = "Volunteering"
        }
  
        else if(data['inputExperienceType'] == "Physician Shadowing/Clinical Observation"){
          data['inputWorkType'] = "Shadowing"
        }
  
        else{
          data['inputWorkType'] = "Activity"
        }
      }
      
      if(workactivityId){
        data['docId'] = workactivityId;
        
        
        dispatch(updateWorkActivity(data));
      }
      else{
        
        dispatch(createWorkActivity(data));
      }
      
    }


    const countWords = (str) => {
      str = str.trim();

      // Return 0 if the string is empty
      if (str === "") {
        return 0;
      }
      
      // Split the string into an array of words using whitespace as the delimiter
      const words = str.split(/\s+/);
      
      // Return the number of words
      return words.length;

    }

    const onChangeExperienceType = (e) => {
      setValue("inputExperienceType", e.value)
      if(e.value == "Other"){
        register("inputWorkType", { required: messageRequired })
      }
      else{
        unregister("inputWorkType")
        register("inputWorkType")
      }
      
    }

    return (
        
      <div>
        
        <Modal className="modal-dialog-centered" size="lg" isOpen={modalAddWorkActivities} toggle={toggleAddWorkActivities} >
          <Form  onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className='modal-body p-0'>
          <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{workactivityId ? "Update" : "Add" }  Work/Activities</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                
                  <h6 className="heading-small text-muted mb-4">
                    Experience information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputTitle"
                          >
                            Title <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative is-invalid"
                            id="inputTitle"
                            name="inputTitle"
                            value={watch("inputTitle")}
                            onChange={e => setValue("inputTitle", e.target.value)}
                            type="text"
                          />
                          { errors.inputTitle && <small className="text-danger">{errors.inputTitle.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceType"
                          >
                            Experience Type <span className='text-danger'>*</span>
                          </label>
                          {/* <Input
                            className="form-control-alternative"
                            id="inputExperienceType"
                            name="inputExperienceType"
                            value={watch("inputExperienceType")}
                            onChange={e => setValue("inputExperienceType", e.target.value)}
                            type="select"
                            >
                                <ExperienceTypes/>
                          </Input> */}

                          <Select 
                            placeholder='Select Experience Type'
                            options={experienceType} 
                            styles={customDropdown }  
                            onChange={(e) => onChangeExperienceType(e)}
                            menuPortalTarget={document.body}
                            value={experienceType.find((option) => option.value === watch("inputExperienceType"))}
                          />

                        { errors.inputExperienceType && <small className="text-danger">{errors.inputExperienceType.message}</small> }
                        </FormGroup>
                        
                      </Col>
                    
                      {getValues("inputExperienceType") == "Other" ? 
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputWorkType"
                          >
                            Type <span className='text-danger'>*</span>
                          </label>
                          {/* <Input
                            className="form-control-alternative"
                            id="inputWorkType"
                            name="inputWorkType"
                            value={watch("inputWorkType")}
                            onChange={e => setValue("inputWorkType", e.target.value)}
                            type="select"
                            >
                              <option value="">Select Type</option>
                              <option value="Activity">Activity</option>
                              <option value="Clinical">Clinical</option>
                              <option value="Shadowing">Shadowing</option>
                              <option value="Volunteering">Volunteering</option>
                          </Input> */}

                          <Select 
                            placeholder='Select Type'
                            options={workActivitiesType} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputWorkType", e.value)}
                            menuPortalTarget={document.body}
                            value={workActivitiesType.find((option) => option.value === watch("inputWorkType"))}
                          />
                          { errors.inputWorkType && <small className="text-danger">{errors.inputWorkType.message}</small> }
                        </FormGroup>
                        
                      </Col>
                      : ""}
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceName"
                          >
                            Experience Name <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative is-invalid"
                            id="inputExperienceName"
                            name="inputExperienceName"
                            value={watch("inputExperienceName")}
                            onChange={e => setValue("inputExperienceName", e.target.value)}
                            type="text"
                          />
                          { errors.inputExperienceName && <small className="text-danger">{errors.inputExperienceName.message}</small> }
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Completed Hours
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputStartMonth"
                          >
                            Start Date <span className='text-danger'>*</span>
                          </label>
                          {/* <Input
                            className="form-control-alternative"
                            id="inputStartMonth"
                            placeholder="Select Month"
                            name="inputStartMonth"
                            value={watch("inputStartMonth")}
                            onChange={e => setValue("inputStartMonth", e.target.value)}
                            type="select">
                                
                                <Months/>
                          </Input> */}

                          <Select 
                            placeholder='Select Month'
                            options={monthsList} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputStartMonth", e.value)}
                            menuPortalTarget={document.body}
                            value={monthsList.find((option) => option.value === watch("inputStartMonth"))}
                          />
                          { errors.inputStartMonth && <small className="text-danger">{errors.inputStartMonth.message}</small> }
                          {/* <Input
                            className="form-control-alternative mt-2"
                            id="inputStartYear"
                            placeholder="Select Years"
                            name="inputStartYear"
                            value={watch("inputStartYear")}
                            onChange={e => setValue("inputStartYear", e.target.value)}
                            type="select">
                                
                                <Years/>
                          </Input> */}
                          {/* {console.log(Years)} */}
                          <Select 
                            placeholder='Select Year'
                            className='mt-2'
                            
                            options={years} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputStartYear", e.value)}
                            menuPortalTarget={document.body}
                            value={years.find((option) => option.value === watch("inputStartYear"))}
                          />

                          { errors.inputStartYear && <small className="text-danger">{errors.inputStartYear.message}</small> }
                          {errors.inputStartYear && errors.inputStartYear.type === "validationFirstScenario" && (<small className="text-danger">Your 1st start date must begin before it's end date.</small>)}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputEndMonth"
                          >
                            End Date <span className='text-danger'>*</span>
                          </label>
                          {/* <Input
                            className="form-control-alternative"
                            id="inputEndMonth"
                            placeholder="Select Month"
                            name="inputEndMonth"
                            value={watch("inputEndMonth")}
                            onChange={e => setValue("inputEndMonth", e.target.value)}
                            type="select">
                                
                                <Months/>
                          </Input> */}

                          <Select 
                            placeholder='Select Month'
                            options={monthsList} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputEndMonth", e.value)}
                            menuPortalTarget={document.body}
                            value={monthsList.find((option) => option.value === watch("inputEndMonth"))}
                          />

                          { errors.inputEndMonth && <small className="text-danger">{errors.inputEndMonth.message}</small> }
                          {/* <Input
                            className="form-control-alternative mt-2"
                            id="inputEndYear"
                            placeholder="Select Years"
                            name="inputEndYear"
                            value={watch("inputEndYear")}
                            onChange={e => setValue("inputEndYear", e.target.value)}
                            type="select">
                                
                                <Years/>
                          </Input> */}

                          <Select 
                            placeholder='Select Year'
                            className='mt-2'
                            options={years} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputEndYear", e.value)}
                            menuPortalTarget={document.body}
                            value={years.find((option) => option.value === watch("inputEndYear"))}
                          />

                          { errors.inputEndYear && <small className="text-danger">{errors.inputEndYear.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputCompletedHours"
                          >
                            Completed Hours <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputCompletedHours"
                            name="inputCompletedHours"
                            value={watch("inputCompletedHours")}
                            onChange={e => setValue("inputCompletedHours", e.target.value)}
                            type="number"
                          />
                          { errors.inputCompletedHours && <small className="text-danger">{errors.inputCompletedHours.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="repeatedYes"
                          >
                            Repeated
                          </label>
                          <div className="custom-control custom-radio mb-3">
                            <Input
                                className="custom-control-input"
                                id="repeatedYes"
                                name="repeatedHours"
                                type="radio"
                                value="yes"
                                checked={repeatedHours ? 'checked' : ''}
                                onChange={onChangeRepeatedHours}
                            />
                            <label className="custom-control-label" htmlFor="repeatedYes">
                                Yes
                            </label>
                            </div>
                            <div className="custom-control custom-radio mb-3">
                            <Input
                                className="custom-control-input"
                                id="repeatedNo"
                                name="repeatedHours"
                                type="radio"
                                value="no"
                                checked={repeatedHours ? '' : 'checked'}
                                onChange={onChangeRepeatedHours}
                            />
                            <label className="custom-control-label" htmlFor="repeatedNo">
                                No
                            </label>
                        </div>
                        </FormGroup>
                      </Col>
                      
                    </Row>
                    <Row className={repeatedHours ? '' : 'd-none'}>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="inputSecondStartMonth"
                            >
                                Second Start Date <span className='text-danger'>*</span>
                            </label>
                            {/* <Input
                                className="form-control-alternative"
                                id="inputSecondStartMonth"
                                placeholder="Select Month"
                                name="inputSecondStartMonth"
                                value={watch("inputSecondStartMonth")}
                                onChange={e => setValue("inputSecondStartMonth", e.target.value)}
                                type="select">
                                    
                                    <Months/>
                            </Input> */}


                            <Select 
                              placeholder='Select Month'
                              options={monthsList} 
                              styles={customDropdown }  
                              onChange={(e) => setValue("inputSecondStartMonth", e.value)}
                              menuPortalTarget={document.body}
                              value={monthsList.find((option) => option.value === watch("inputSecondStartMonth"))}
                            />
                            { errors.inputSecondStartMonth && <small className="text-danger">{errors.inputSecondStartMonth.message}</small> }
                            {/* <Input
                                className="form-control-alternative mt-2"
                                id="inputSecondStartYear"
                                placeholder="Select Years"
                                name="inputSecondStartYear"
                                value={watch("inputSecondStartYear")}
                                onChange={e => setValue("inputSecondStartYear", e.target.value)}
                                type="select">
                                    
                                    <Years/>
                            </Input> */}


                            <Select 
                              placeholder='Select Year'
                              className='mt-2'
                              options={years} 
                              styles={customDropdown }  
                              onChange={(e) => setValue("inputSecondStartYear", e.value)}
                              menuPortalTarget={document.body}
                              value={years.find((option) => option.value === watch("inputSecondStartYear"))}
                            />

                            { errors.inputSecondStartYear && <small className="text-danger">{errors.inputSecondStartYear.message}</small> }
                            { errors.inputSecondStartYear && errors.inputSecondStartYear.type === "validationFirstScenario" && (<small className="text-danger">Your 1st start date must begin before it's end date.</small>) }
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="inputSecondEndMonth"
                            >
                                Second End Date <span className='text-danger'>*</span>
                            </label>
                            {/* <Input
                                className="form-control-alternative"
                                id="inputSecondEndMonth"
                                placeholder="Select Month"
                                name="inputSecondEndMonth"
                                value={watch("inputSecondEndMonth")}
                                onChange={e => setValue("inputSecondEndMonth", e.target.value)}
                                type="select">
                                    
                                    <Months/>
                            </Input> */}

                            <Select 
                              placeholder='Select Month'
                              options={monthsList} 
                              styles={customDropdown }  
                              onChange={(e) => setValue("inputSecondEndMonth", e.value)}
                              menuPortalTarget={document.body}
                              value={monthsList.find((option) => option.value === watch("inputSecondEndMonth"))}
                            />
                            { errors.inputSecondEndMonth && <small className="text-danger">{errors.inputSecondEndMonth.message}</small> }
                            {/* <Input
                                className="form-control-alternative mt-2"
                                id="inputSecondEndYear"
                                placeholder="Select Years"
                                name="inputSecondEndYear"
                                value={watch("inputSecondEndYear")}
                                onChange={e => setValue("inputSecondEndYear", e.target.value)}
                                type="select">
                                    
                                    <Years/>
                            </Input> */}

                            <Select 
                              placeholder='Select Year'
                              className='mt-2'
                              options={years} 
                              styles={customDropdown }  
                              onChange={(e) => setValue("inputSecondEndYear", e.value)}
                              menuPortalTarget={document.body}
                              value={years.find((option) => option.value === watch("inputSecondEndYear"))}
                            />
                            { errors.inputSecondEndYear && <small className="text-danger">{errors.inputSecondEndYear.message}</small> }
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="inputSecondCompletedHours"
                            >
                                Second Completed Hours <span className='text-danger'>*</span>
                            </label>
                            <Input
                                className="form-control-alternative"
                                id="inputSecondCompletedHours"
                                name="inputSecondCompletedHours"
                                value={watch("inputSecondCompletedHours")}
                                onChange={e => setValue("inputSecondCompletedHours", e.target.value)}
                                type="number"
                            />
                            { errors.inputSecondCompletedHours && <small className="text-danger">{errors.inputSecondCompletedHours.message}</small> }
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="inputThirdStartMonth"
                            >
                                Third Start Date
                            </label>
                            {/* <Input
                                className="form-control-alternative"
                                id="inputThirdStartMonth"
                                placeholder="Select Month"
                                name="inputThirdStartMonth"
                                value={watch("inputThirdStartMonth")}
                                onChange={onChangeInputThirdStartMonth}
                                type="select">
                                    
                                    <Months/>
                            </Input> */}

                            <Select 
                              placeholder='Select Month'
                              options={monthsList} 
                              styles={customDropdown }  
                              onChange={onChangeInputThirdStartMonth}
                              menuPortalTarget={document.body}
                              value={monthsList.find((option) => option.value === watch("inputThirdStartMonth"))}
                            />

                            {/* <Input
                                className="form-control-alternative mt-2"
                                id="inputThirdStartYear"
                                placeholder="Select Years"
                                name="inputThirdStartYear"
                                value={watch("inputThirdStartYear")}
                                onChange={e => {
                                  setValue("inputThirdStartYear", e.target.value)
                                  validationForCompletedHours("inputThird");
                                }}
                                type="select">
                                    
                                    <Years/>
                            </Input> */}

                            <Select 
                              placeholder='Select Year'
                              className='mt-2'
                              options={years} 
                              styles={customDropdown }  
                              onChange={(e) => {
                                setValue("inputThirdStartYear", e.value)
                                validationForCompletedHours("inputThird");
                              }}
                              menuPortalTarget={document.body}
                              value={years.find((option) => option.value === watch("inputThirdStartYear"))}
                            />

                            {errors.inputThirdStartYear && errors.inputThirdStartYear.type === "validationFirstScenario" && (<small className="text-danger">You must enter a 2nd start and end dates before entering 3rd start and end dates.</small>)}
                            {errors.inputThirdStartYear && errors.inputThirdStartYear.type === "validationSecondScenario" && (<small className="text-danger">Your 3rd start date cannot be earlier than your 2nd end date.</small>)}
                            {errors.inputThirdStartYear && errors.inputThirdStartYear.type === "validationThirdScenario" && (<small className="text-danger">Your 3rd start date must begin before it's end date.</small>)}
                            
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="inputThirdEndMonth"
                            >
                                Third End Date
                            </label>
                            {/* <Input
                                className="form-control-alternative"
                                id="inputThirdEndMonth"
                                placeholder="Select Month"
                                name="inputThirdEndMonth"
                                value={watch("inputThirdEndMonth")}
                                onChange={e => {
                                  setValue("inputThirdEndMonth", e.target.value)
                                  validationForCompletedHours("inputThird");
                                }}
                                type="select">
                                    
                                    <Months/>
                            </Input> */}

                            <Select 
                              placeholder='Select Month'
                              options={monthsList} 
                              styles={customDropdown }  
                              onChange={e =>{
                                setValue("inputThirdEndMonth", e.value)
                                validationForCompletedHours("inputThird");
                              }}
                              menuPortalTarget={document.body}
                              value={monthsList.find((option) => option.value === watch("inputThirdEndMonth"))}
                            />

                            {/* <Input
                                className="form-control-alternative mt-2"
                                id="inputThirdEndYear"
                                placeholder="Select Years"
                                name="inputThirdEndYear"
                                value={watch("inputThirdEndYear")}
                                onChange={e => {
                                  setValue("inputThirdEndYear", e.target.value)
                                  validationForCompletedHours("inputThird");
                              }}
                                type="select">
                                    
                                    <Years/>
                            </Input> */}

                            <Select 
                              placeholder='Select Year'
                              className='mt-2'
                              options={years} 
                              styles={customDropdown }  
                              onChange={(e) => {
                                setValue("inputThirdEndYear", e.value)
                                validationForCompletedHours("inputThird");
                              }}
                              menuPortalTarget={document.body}
                              value={years.find((option) => option.value === watch("inputThirdEndYear"))}
                            />
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="inputThirdCompletedHours"
                            >
                                Third Completed Hours
                            </label>
                            <Input
                                className="form-control-alternative"
                                id="inputThirdCompletedHours"
                                name="inputThirdCompletedHours"
                                value={watch("inputThirdCompletedHours")}
                                onChange={e => {
                                  onChangeCompletedHours(e, "inputThird")
                                  validationForCompletedHours("inputThird");
                              }}
                                type="number"
                            />
                            {errors.inputThirdCompletedHours && errors.inputThirdCompletedHours.type === "validationFirstScenario" && (<small className="text-danger">If entering completed hours, you must specify dates.</small>)}
                            { errors.inputThirdCompletedHours && <small className="text-danger">{errors.inputThirdCompletedHours.message}</small> }
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="inputFourthStartMonth"
                            >
                                Fourth Start Date
                            </label>
                            {/* <Input
                                className="form-control-alternative"
                                id="inputFourthStartMonth"
                                name="inputFourthStartMonth"
                                placeholder="Select Month"
                                value={watch("inputFourthStartMonth")}
                                onChange={onChangeInputFourthStartMonth}
                                type="select">
                                    
                                    <Months/>
                            </Input> */}


                            <Select 
                              placeholder='Select Month'
                              options={monthsList} 
                              styles={customDropdown }  
                              onChange={onChangeInputFourthStartMonth}
                              menuPortalTarget={document.body}
                              value={monthsList.find((option) => option.value === watch("inputFourthStartMonth"))}
                            />

                            {/* <Input
                                className="form-control-alternative mt-2"
                                id="inputFourthStartYear"
                                name="inputFourthStartYear"
                                placeholder="Select Years"
                                value={watch("inputFourthStartYear")}
                                onChange={e => {
                                  setValue("inputFourthStartYear", e.target.value)
                                  validationForCompletedHours("inputFourth");
                              }}
                                type="select">
                                    
                                    <Years/>
                            </Input> */}

                            <Select 
                              placeholder='Select Year'
                              className='mt-2'
                              options={years} 
                              styles={customDropdown }  
                              onChange={(e) => {
                                setValue("inputFourthStartYear", e.value)
                                validationForCompletedHours("inputFourth");
                              }}
                              menuPortalTarget={document.body}
                              value={years.find((option) => option.value === watch("inputFourthStartYear"))}
                            />
                            {errors.inputFourthStartYear && errors.inputFourthStartYear.type === "validationFirstScenario" && (<small className="text-danger">You must enter a 3rd start and end dates before entering 4th start and end dates.</small>)}
                            {errors.inputFourthStartYear && errors.inputFourthStartYear.type === "validationSecondScenario" && (<small className="text-danger">Your 4th start date cannot be earlier than your 3rd end date.</small>)}
                            {errors.inputFourthStartYear && errors.inputFourthStartYear.type === "validationThirdScenario" && (<small className="text-danger">Your 4th start date must begin before it's end date.</small>)}
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="inputFourthEndMonth"
                            >
                                Fourth End Date
                            </label>
                            {/* <Input
                                className="form-control-alternative"
                                id="inputFourthEndMonth"
                                name="inputFourthEndMonth"
                                value={watch("inputFourthEndMonth")}
                                onChange={e => {
                                  setValue("inputFourthEndMonth", e.target.value)
                                  validationForCompletedHours("inputFourth");
                              }}
                                placeholder="Select Month"
                                type="select">
                                    
                                    <Months/>
                            </Input> */}

                            <Select 
                              placeholder='Select Month'
                              options={monthsList} 
                              styles={customDropdown }  
                              onChange={e =>{
                                setValue("inputFourthEndMonth", e.value)
                                validationForCompletedHours("inputFourth");
                              }}
                              menuPortalTarget={document.body}
                              value={monthsList.find((option) => option.value === watch("inputFourthEndMonth"))}
                            />

                            {/* <Input
                                className="form-control-alternative mt-2"
                                id="inputFourthEndYear"
                                name="inputFourthEndYear"
                                value={watch("inputFourthEndYear")}
                                onChange={e => {
                                  setValue("inputFourthEndYear", e.target.value)
                                  validationForCompletedHours("inputFourth");
                              }}
                                placeholder="Select Years"
                                type="select">
                                    
                                    <Years/>
                            </Input> */}
                            <Select 
                              placeholder='Select Year'
                              className='mt-2'
                              options={years} 
                              styles={customDropdown }  
                              onChange={(e) => {
                                setValue("inputFourthEndYear", e.value)
                                validationForCompletedHours("inputFourth");
                            }}
                              menuPortalTarget={document.body}
                              value={years.find((option) => option.value === watch("inputFourthEndYear"))}
                            />
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="inputFourthCompletedHours"
                            >
                                Fourth Completed Hours
                            </label>
                            <Input
                                className="form-control-alternative"
                                id="inputFourthCompletedHours"
                                name="inputFourthCompletedHours"
                                value={watch("inputFourthCompletedHours")}
                                onChange={e => onChangeCompletedHours(e, "inputFourth")}
                                type="number"
                            />
                            {errors.inputFourthCompletedHours && errors.inputFourthCompletedHours.type === "validationFirstScenario" && (<small className="text-danger">If entering completed hours, you must specify dates.</small>)}
                            { errors.inputFourthCompletedHours && <small className="text-danger">{errors.inputFourthCompletedHours.message}</small> }
                            </FormGroup>
                        </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Description */}
                  <h6 className="heading-small text-muted mb-4">Anticipated Hours</h6>
                  <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="anticipatedYes"
                          >
                            Do you anticipate completing any hours for this experience in the future?
                          </label>
                          <div className="custom-control custom-radio mb-3">
                            <Input
                                className="custom-control-input"
                                id="anticipatedYes"
                                name="anticipatedHours"
                                type="radio"
                                value="yes"
                                checked={anticipatedHours ? 'checked' : ''}
                                onChange={onChangeAnticipatedHours}
                            />
                            <label className="custom-control-label" htmlFor="anticipatedYes">
                                Yes
                            </label>
                            </div>
                            <div className="custom-control custom-radio mb-3">
                            <Input
                                className="custom-control-input"
                                id="anticipated-no"
                                name="anticipatedHours"
                                type="radio"
                                value="no"
                                checked={anticipatedHours ? '' : 'checked'}
                                onChange={onChangeAnticipatedHours}
                            />
                            <label className="custom-control-label" htmlFor="anticipated-no">
                                No
                            </label>
                        </div>
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row className={anticipatedHours ? '' : 'd-none'}>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputAnticipatedStartMonth"
                          >
                            Anticipated Start Date <span className='text-danger'>*</span>
                          </label>
                          {/* <Input
                            className="form-control-alternative"
                            id="inputAnticipatedStartMonth"
                            placeholder="Select Month"
                            name="inputAnticipatedStartMonth"
                            value={watch("inputAnticipatedStartMonth")}
                            onChange={e => setValue("inputAnticipatedStartMonth", e.target.value)}
                            type="select">
                                <Months/>
                          </Input> */}

                          <Select 
                              placeholder='Select Month'
                              options={monthsList} 
                              styles={customDropdown }  
                              onChange={e =>setValue("inputAnticipatedStartMonth", e.value)}
                              menuPortalTarget={document.body}
                              value={monthsList.find((option) => option.value === watch("inputAnticipatedStartMonth"))}
                          />

                          { errors.inputAnticipatedStartMonth && <small className="text-danger">{errors.inputAnticipatedStartMonth.message}</small> }
                          {/* <Input
                            className="form-control-alternative mt-2"
                            id="inputAnticipatedStartYear"
                            placeholder="Select Years"
                            name="inputAnticipatedStartYear"
                            value={watch("inputAnticipatedStartYear")}
                            onChange={e => setValue("inputAnticipatedStartYear", e.target.value)}
                            type="select">
                                <option value="">Select Year</option>
                                <option value={today.getFullYear()}>{today.getFullYear()}</option>
                          </Input> */}

                          <Select 
                              placeholder='Select Year'
                              className='mt-2'
                              options={[{value: today.getFullYear(), label : today.getFullYear()}]} 
                              styles={customDropdown }  
                              onChange={(e) => setValue("inputAnticipatedStartYear", e.value)}
                              menuPortalTarget={document.body}
                              value={[{value: today.getFullYear(), label : today.getFullYear()}].find((option) => option.value === watch("inputAnticipatedStartYear"))}
                            />
                          { errors.inputAnticipatedStartYear && <small className="text-danger">{errors.inputAnticipatedStartYear.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputAnticipatedEndMonth"
                          >
                            Anticipated End Date <span className='text-danger'>*</span>
                          </label>
                          {/* <Input
                            className="form-control-alternative"
                            id="inputAnticipatedEndMonth"
                            placeholder="Select Month"
                            name="inputAnticipatedEndMonth"
                            value={watch("inputAnticipatedEndMonth")}
                            onChange={e => setValue("inputAnticipatedEndMonth", e.target.value)}
                            type="select">
                                
                                <Months/>
                          </Input> */}

                          <Select 
                              placeholder='Select Month'
                              options={monthsList} 
                              styles={customDropdown }  
                              onChange={e =>setValue("inputAnticipatedEndMonth", e.value)}
                              menuPortalTarget={document.body}
                              value={monthsList.find((option) => option.value === watch("inputAnticipatedEndMonth"))}
                          />

                          { errors.inputAnticipatedEndMonth && <small className="text-danger">{errors.inputAnticipatedEndMonth.message}</small> }
                          {/* <Input
                            className="form-control-alternative mt-2"
                            id="inputAnticipatedEndYear"
                            placeholder="Select Years"
                            name="inputAnticipatedEndYear"
                            value={watch("inputAnticipatedEndYear")}
                            onChange={e => setValue("inputAnticipatedEndYear", e.target.value)}
                            type="select">
                                <option value="">Select Year</option>
                                <option value={today.getFullYear()}>{today.getFullYear()}</option>
                          </Input> */}

                          <Select 
                              placeholder='Select Year'
                              className='mt-2'
                              options={[{value: today.getFullYear(), label : today.getFullYear()}]} 
                              styles={customDropdown }  
                              onChange={(e) => setValue("inputAnticipatedEndYear", e.value)}
                              menuPortalTarget={document.body}
                              value={[{value: today.getFullYear(), label : today.getFullYear()}].find((option) => option.value === watch("inputAnticipatedEndYear"))}
                            />
                          { errors.inputAnticipatedEndYear && <small className="text-danger">{errors.inputAnticipatedEndYear.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputAnticipatedCompletedHours"
                          >
                            Anticipated Hours <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputAnticipatedCompletedHours"
                            type="number"
                            name="inputAnticipatedCompletedHours"
                            value={watch("inputAnticipatedCompletedHours")}
                            onChange={e => setValue("inputAnticipatedCompletedHours", e.target.value)}
                          />
                          { errors.inputAnticipatedCompletedHours && <small className="text-danger">{errors.inputAnticipatedCompletedHours.message}</small> }
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputLocation"
                          >
                            Location
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputLocation"
                            name="inputLocation"
                            value={watch("inputLocation")}
                            onChange={e => setValue("inputLocation", e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputOrganizationName"
                          >
                            Organization Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputOrganizationName"
                            name="inputOrganizationName"
                            value={watch("inputOrganizationName")}
                            onChange={e => setValue("inputOrganizationName", e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputCountry"
                          >
                            Country
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputCountry"
                            name="inputCountry"
                            value={watch("inputCountry")}
                            onChange={e => setValue("inputCountry", e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputCity"
                          >
                            City
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputCity"
                            name="inputCity"
                            value={watch("inputCity")}
                            onChange={e => setValue("inputCity", e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputContactTitle"
                          >
                            Contact Title 
                            {/* <span className='text-danger'>*</span> */}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputContactTitle"
                            type="text"
                            name="inputContactTitle"
                            value={watch("inputContactTitle")}
                            onChange={e => setValue("inputContactTitle", e.target.value)}
                          />
                          { errors.inputContactTitle && <small className="text-danger">{errors.inputContactTitle.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputContactFirstName"
                          >
                            Contact First Name 
                            {/* <span className='text-danger'>*</span> */}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputContactFirstName"
                            type="text"
                            name="inputContactFirstName"
                            value={watch("inputContactFirstName")}
                            onChange={e => setValue("inputContactFirstName", e.target.value)}
                          />
                          { errors.inputContactFirstName && <small className="text-danger">{errors.inputContactFirstName.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputContactLastName"
                          >
                            Contact Last Name 
                            {/* <span className='text-danger'>*</span> */}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputContactLastName"
                            type="text"
                            name="inputContactLastName"
                            value={watch("inputContactLastName")}
                            onChange={e => setValue("inputContactLastName", e.target.value)}
                          />
                          { errors.inputContactLastName && <small className="text-danger">{errors.inputContactLastName.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputContactPhone"
                          >
                            Contact's Phone 
                            {/* <span className='text-danger'>*</span> */}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputContactPhone"
                            type="text"
                            name="inputContactPhone"
                            value={watch("inputContactPhone")}
                            onChange={e => setValue("inputContactPhone", e.target.value)}
                          />
                          { errors.inputContactPhone && <small className="text-danger">{errors.inputContactPhone.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputContactEmail"
                          >
                            Email 
                            {/* <span className='text-danger'>*</span> */}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputContactEmail"
                            type="text"
                            name="inputContactEmail"
                            value={watch("inputContactEmail")}
                            onChange={e => setValue("inputContactEmail", e.target.value)}
                          />
                          { errors.inputContactEmail && <small className="text-danger">{errors.inputContactEmail.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceDescription">
                            Experience Description
                          </label>
                            <Input
                                id="inputExperienceDescription"
                                name="inputExperienceDescription"
                                value={watch("inputExperienceDescription")}
                                placeholder=""
                                rows="5"
                                type="textarea"
                                onChange={e => setValue("inputExperienceDescription", e.target.value)}
                            />
                            <div className='text-sm'>Character count : {(typeof getValues("inputExperienceDescription") == "undefined")  ? "0" : getValues("inputExperienceDescription").length} | Word Count : {countWords((typeof getValues("inputExperienceDescription") == "undefined")  ? "" : getValues("inputExperienceDescription"))}</div>   
                        </FormGroup>
                      </Col>

                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputMostMeaningfulExperienceDescription">
                            Most Meaningful Experience Description
                          </label>
                            <Input
                                id="inputMostMeaningfulExperienceDescription"
                                name="inputMostMeaningfulExperienceDescription"
                                value={watch("inputMostMeaningfulExperienceDescription")}
                                placeholder=""
                                rows="5"
                                type="textarea"
                                onChange={e => setValue("inputMostMeaningfulExperienceDescription", e.target.value)}
                            />
                            <div className='text-sm'>Character count : {(typeof getValues("inputMostMeaningfulExperienceDescription") == "undefined")  ? "0" : getValues("inputMostMeaningfulExperienceDescription").length} | Word Count : {countWords((typeof getValues("inputMostMeaningfulExperienceDescription") == "undefined")  ? "0" : getValues("inputMostMeaningfulExperienceDescription"))}</div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            {props.adminUser.admin ? "" :
              <>
              <Button color="primary" type='submit'>
              {workactivityId ? "Update" : "Save" } 
              </Button>{' '}
              <Button color="secondary" onClick={toggleAddWorkActivities}>
                Cancel
              </Button>
            </>}
          </ModalFooter>
          </Form>
        </Modal>
      </div>
    );

}

const mapStateToProps = state => {
  return {
    createWorkActivities : state.createWorkActivities,
    updateWorkActivities : state.updateWorkActivities,
    editWorkActivity : state.editWorkActivity,
    fetchWorkActivities : state.fetchWorkActivities,
    newWorkActivity : state.newWorkActivity,
    userDetails : state.loginAuth.userDetails,
    adminUser : state.adminUser

  }
}


export default connect(mapStateToProps)(WorkActivitiesModal)