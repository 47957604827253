import React, {useRef, useState, useEffect,} from 'react';
import {useForm} from 'react-hook-form';
import moment from 'moment-timezone';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Mention, MentionsInput } from 'react-mentions';
// import styles from './FormInputStyle.module.css';
// import mentionsInputStyle from './mentionsInputStyle';
// import mentionStyle from './mentionStyle';

import {
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Badge,
    Input,
    Image,
    Form,
    FormGroup,
    Label,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ListGroup,
    ListGroupItem,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    TabContent, TabPane, Nav, NavItem, NavLink, 
} from "reactstrap";

import classnames from 'classnames';
import Header from 'components/Headers/Header';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { getUserData } from 'redux/UserAuthentication/LoginActions';
import ProMember from './ProMember';
import OpenCommunity from './OpenCommunity';
import UpgradeUas from './UpgradeUas';
import UasCommunity from './UasCommunity';
import SearchPost from 'components/Community/SearchPost';
import { fetchDetailsSubscription } from "redux/Payments/PaymentsActions";


const Community = (props) => {
    // console.log(props)

    const dispatch = useDispatch()
    const [searchPost, setSearchPost] = useState("")

    // const cmenus = ['Open Community', 'Pro Members', 'Ultimate Advising Students'];

    const cmenus = ['Pro Members', 'Ultimate Advising Students'];
    
    const [activemenu, setActiveMenu] = useState('Pro Members');

    const togglemenu = tab => {
        if(activemenu !== tab) setActiveMenu(tab);
    }


    const catoptions = ['All','General', 'Classes', 'MCAT'];

    const categoryOptions = [
    { value: '', label: 'Select a category' },
    { value: 'General', label: 'General' },
    { value: 'Classes', label: 'Classes' },
    { value: 'MCAT', label: 'MCAT' }
    ]

    const [activeTab, setActiveTab] = useState('All');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
      if(typeof props.userDetails.subsId != "undefined"){
        dispatch(fetchDetailsSubscription({ subsId : props.userDetails.subsId }))
      }
      
    }, [])

    const searchAPost = (value) => {
        setSearchPost(value)
    }
    
  return (
    <>
        
        <Header></Header>
        <Container className="mt--7" >
            <Row className="">
                <Col lg='12' className="p-1">
                    <div className="d-flex flex-row align-items-center justify-content-end mb-3">
                                     
                        <Nav fill pills>
                            { cmenus.length > 0 ?
                                cmenus.map((c, i) => {
                                    return (
                                        <NavItem className="ml-1" key={i}>
                                        <NavLink
                                            className={classnames({ active: activemenu === c })}
                                                onClick={() => { togglemenu(c); }}
                                                style={{ cursor: 'pointer' }}
                                        >
                                        {c}
                                        </NavLink>
                                    </NavItem>
                                    )
                                }) : ''
                            }
                        </Nav>
            
                    </div>
                    <TabContent activeTab={activemenu}>
                    {
                        cmenus.map((c, i) => { 
                            return(
                                <TabPane tabId={c} key={i}>
                                    {
                                        c == 'Open Community' ?
                                            <>
                                                <ProMember key={c} />
                                                {/* {
                                                    searchPost ? <SearchPost value={searchPost} />
                                                    : 
                                                } */}
                                                
                                            </>
                                       
                                        : c == 'Pro Members' ? 
                                            <OpenCommunity key={c} />
                                        : <UasCommunity key={c}/>
                                    }
                                    
                                </TabPane>
                            )      
                        }) 
                    }
                    </TabContent>
                </Col>
              </Row>
            <Row className="mt-5"></Row>
        </Container>
        
    </>
  )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        createCommunityPost: state.createCommunityPost,
        fetchAllCommunityPosts: state.fetchAllCommunityPosts,
       
    }
      
}

export default connect(mapStateToProps)(Community)

