import {
    CREATE_TIMELINE_SCHEDULE_REQUEST, 
    CREATE_TIMELINE_SCHEDULE_SUCCESS, 
    CREATE_TIMELINE_SCHEDULE_FAILURE, 
    CREATE_TIMELINE_SCHEDULE_RESET,
    FETCH_TIMELINE_SCHEDULE_FAILURE,
    FETCH_TIMELINE_SCHEDULE_REQUEST,
    FETCH_TIMELINE_SCHEDULE_SUCCESS

} from './TimelineTypes'

const initialTimelineScheduleState = {
    timelineschedule: [],
    loading : false
}

export const createTimelineScheduleReducer = (state = initialTimelineScheduleState, action) => {
    switch(action.type){
        case CREATE_TIMELINE_SCHEDULE_RESET:
            return {
                loading: false,
                timelineschedule: []
            }
        case CREATE_TIMELINE_SCHEDULE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_TIMELINE_SCHEDULE_SUCCESS:
            return {
                loading: false,
                timelineschedule: [action.payload]
            }
        case CREATE_TIMELINE_SCHEDULE_FAILURE:
            return {
                loading : false,
                timelineschedule : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialFetchTimelineScheduleState = {
    timelineschedule : [],
    loading: false,

}

export const fetchTimelineScheduleReducer = (state = initialFetchTimelineScheduleState, action) => {
    switch (action.type) {
        case FETCH_TIMELINE_SCHEDULE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_TIMELINE_SCHEDULE_SUCCESS:
            return {
                loading: false,
                timelineschedule: action.payload
            }
        case FETCH_TIMELINE_SCHEDULE_FAILURE:
            return {
                loading: false,
                timelineschedule: [],
                error: action.payload
            }
        default: return state;
    }
}