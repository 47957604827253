import React from 'react'
import Header from 'components/Headers/Header'
import CoursesWidget from 'components/Widgets/CoursesWidget';
import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Button,
} from "reactstrap";

import CoursesTable from 'components/Table/CoursesTable';
import {fetchCourse, updateCourseModal,newCourse } from 'redux/Courses/CoursesActions';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import CoursesModal from 'components/Modal/CoursesModal'
const Courses = (props) => {

    const dispatch = useDispatch();

    const modalAddCourses = props.updateCourseModalStatus.status;

    const toggleAddCourses = () =>{
      dispatch(updateCourseModal(!props.updateCourseModalStatus.status));
      dispatch(newCourse({status: true}))
    } 

    // useEffect(() => {
      
  
      if(modalAddCourses && props.createCourses.course.length && typeof props.createCourses.error === "undefined"){
        dispatch(updateCourseModal(false));
      }
  
      if(modalAddCourses && props.updateCourses.course.length && typeof props.updateCourses.error === "undefined"){
        dispatch(updateCourseModal(false));
      }
  
      if(modalAddCourses && typeof props.createCourses.error !== "undefined"){
        dispatch(updateCourseModal(false));
      }
  
      if(modalAddCourses && typeof props.updateCourses.error !== "undefined"){
        dispatch(updateCourseModal(false));
      }
      // }, [])
    return (
    <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              <CoursesWidget></CoursesWidget>
            </div>
          </Container>
        </div>
        
        <Container className="mt--7 mb-5" fluid>
            <Row>
            <div className="col">
                <Card className="shadow">
                <CardHeader className="border-0">
                    <div className='float-right'>
                        <Button color="primary" disabled={props.adminUser.admin ? "true" : false} onClick={() => toggleAddCourses()}>Add Courses</Button>
                    </div>
                </CardHeader>
                <CardBody>
                <CoursesTable />
                </CardBody>
               
                
                </Card>
            </div>
            </Row>
        </Container>
        <CoursesModal toggleAddCourses={toggleAddCourses} modalAddCourses={modalAddCourses} />
    </>
    
  )
}

const mapStateToProps = state => {
    return {
      fetchCourses : state.fetchCourses,
      updateCourseModalStatus : state.updateCourseModalStatus,
      createCourses : state.createCourses,
      updateCourses : state.updateCourses,
      editCourse : state.editCourse,
      adminUser : state.adminUser
    }
  }

export default connect(mapStateToProps)(Courses)