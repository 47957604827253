import React, { useEffect, useRef, useState } from 'react'
import {
    Card,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container
  } from "reactstrap";
import SchoolCard from 'components/Card/SchoolSearch/SchoolCard';

import { useDispatch } from 'react-redux';
import { fetchSchoolSearch } from 'redux/SchoolSearch/SchoolSearchActions';

import { connect } from 'react-redux';
import Nouislider from "nouislider-react";
import LazyLoad from 'react-lazyload';
import Swal from 'sweetalert2'
import  Select  from 'react-select';
import {customDropdownWithBorder} from "util/reactCustomStyles"
const SchoolSearch = (props) => {

  const dispatch = useDispatch();

  const [medicalSchoolList, setMedicalSchoolList] = useState([]);

  const [medicalSchoolStateList, setMedicalSchoolStateList] = useState([]);


  const [stateProvinceFilter, setStateProvinceFilter] = useState("");
  const [inStateAnnualTuitionFilter, setInStateAnnualTuitionFilter] = useState("");
  const [medianGPAFilter, setMedianGPAFilter] = useState({min:3, max:4});
  const [medianMCATFilter, setMedianMCATFilter] = useState({min:472, max:528});
  const [classSizeFilter, setClassSizeFilter] = useState({min:40, max:500});
  const [schoolTypeFilter, setSchoolTypeFilter] = useState({schoolType:[]});
  const [campusTypeFilter, setCampusTypeFilter] = useState({campusType:[]});
  const [searchSchoolName, setSearchSchoolName] = useState("");
  const [requireCasperFilter, setRequireCasperFilter] = useState({requireCasper:[]});

  const [postbacFilter, setPostbacFilter] = useState({postbac:[]});

  useEffect(() => {

    dispatch(fetchSchoolSearch({userId:props.userDetails.userId}));

  }, []);

  useEffect(() => {
        
    // setMedicalSchoolList(props.fetchSchoolSearch.schoolsearch);
    filterStateProvince(props.fetchSchoolSearch.schoolsearch, stateProvinceFilter)
    let state = [];

    props.fetchSchoolSearch.schoolsearch.map((value, key)=>{
      state.push(value.overview.state);
    });

    state.sort();
    state = [...new Set(state)];
    // state = Array.from(new Set(state.map((s)=> {return {value:s.name, label: s.name}})));
    let states = [...new Set(state.map((value,index) => {return {value:value, label: value}}))];
    
    states.unshift({value:"" , label : "ALL"})
    setMedicalSchoolStateList(states);
    
  }, [props.fetchSchoolSearch])

  useEffect(async () => {

   
    filterStateProvince(props.fetchSchoolSearch.schoolsearch, stateProvinceFilter)
      

  }, [stateProvinceFilter, inStateAnnualTuitionFilter,medianGPAFilter,medianMCATFilter,classSizeFilter,campusTypeFilter, schoolTypeFilter,requireCasperFilter,postbacFilter,searchSchoolName]);

 
  const filterStateProvince = (data, filter) => {
    let medicalSchoolListData = data;

    if(filter){
      
      medicalSchoolListData = medicalSchoolListData.filter(e => e.overview.state == filter);
    }
    else{
      medicalSchoolListData = medicalSchoolListData.filter(e => e.overview.state != filter);
    }

    
    filterInStateAnnualTuition(medicalSchoolListData, inStateAnnualTuitionFilter)
  }

  const filterInStateAnnualTuition = (data, filter) => {
    let medicalSchoolListData = data;

    if(filter){
      filter = parseInt(filter)
      if(filter == 50001){
        medicalSchoolListData = medicalSchoolListData.filter(e => e.overview.tuitionAndFeesRes >= filter);
      }
      else{
        
        if(filter == 10000){
        
          medicalSchoolListData = medicalSchoolListData.filter(e => e.overview.tuitionAndFeesRes >= filter && e.overview.tuitionAndFeesRes <= (filter + 10000));
        }
        else{
          medicalSchoolListData = medicalSchoolListData.filter(e => e.overview.tuitionAndFeesRes >= filter && e.overview.tuitionAndFeesRes <= (filter + 9999));
        }
      }
      
    }
    
    
    
    filterMedianGPA(medicalSchoolListData,medianGPAFilter);
  }

  const filterMedianGPA = (data, filter) => {
    let medicalSchoolListData = data;
    
    if(parseFloat(filter.min) == 3){
      medicalSchoolListData = medicalSchoolListData.filter(e =>{ 
        
        let gpa = e.medSchoolMatDemo.gpaAcceptedTotalAvg;
        gpa = gpa == null || gpa == "" || gpa == "0.00" ? '3.0' : gpa;

        return (parseFloat(gpa) >= parseFloat(filter.min) && parseFloat(gpa) <= parseFloat(filter.max)) || gpa == ""
      
      });
    }
    else{

      

      medicalSchoolListData = medicalSchoolListData.filter(e => 
        {
          let gpa = e.medSchoolMatDemo.gpaAcceptedTotalAvg;
          gpa = gpa == null || gpa == "" || gpa == "0.00" ? '3.0' : gpa;

          return parseFloat(gpa) >= parseFloat(filter.min) && parseFloat(gpa) <= parseFloat(filter.max)
      
      });
    }
    
      

    
    filterMedianMCAT(medicalSchoolListData,medianMCATFilter);
    
  }

  const filterMedianMCAT = (data, filter) => {
    let medicalSchoolListData = data;
    
    if(parseFloat(filter.min) == 472){
      medicalSchoolListData = medicalSchoolListData.filter(e => {

        let mcat = e.medSchoolMatDemo.mcatAcceptedTotalAvg;
        mcat = mcat == null || mcat == "" || mcat == "0.0" ? '472' : mcat;

        return (parseFloat(mcat) >= parseFloat(filter.min) && parseFloat(mcat) <= parseFloat(filter.max)) || mcat == ""}
        
        );
    }
    else{
      medicalSchoolListData = medicalSchoolListData.filter(e => {
        
        let mcat = e.medSchoolMatDemo.mcatAcceptedTotalAvg;
        mcat = mcat == null || mcat == "" || mcat == "0.0" ? '472' : mcat;

        return parseFloat(mcat) >= parseFloat(filter.min) && parseFloat(mcat) <= parseFloat(filter.max)
      
      });
    }
    
      
    
    filterClassSize(medicalSchoolListData,classSizeFilter);
  }

  const filterClassSize = (data, filter) => {
    let medicalSchoolListData = data;
    
    if(parseFloat(filter.min) == 40){
      medicalSchoolListData = medicalSchoolListData.filter(e => (parseInt(e.overview.total) >= 0 && parseInt(e.overview.total) <= parseInt(filter.max)) || e.overview.total == null || e.overview.total == "");
    }
    else{
      medicalSchoolListData = medicalSchoolListData.filter(e => parseInt(e.overview.total) >= parseInt(filter.min) && parseInt(e.overview.total) <= parseInt(filter.max));
    }
    
      

    filterCampusType(medicalSchoolListData,campusTypeFilter);
    
    // setMedicalSchoolList(medicalSchoolListData);
  }

  const filterCampusType = (data, filter) => {
    let medicalSchoolListData = data;
    
    if(filter.campusType.length){
      medicalSchoolListData = medicalSchoolListData.filter(e => filter.campusType.includes(e.overview.campusType));
    }
      

    filterSchoolType(medicalSchoolListData, schoolTypeFilter);
    // setMedicalSchoolList(medicalSchoolListData);
  }

  const filterSchoolType = (data, filter) => {
    let medicalSchoolListData = data;
    
    
    if(filter.schoolType.length){
      medicalSchoolListData = medicalSchoolListData.filter(e =>{
        
        return filter.schoolType.includes(e.schoolType)
        
      } );
    }

    filterRequireCasper(medicalSchoolListData, requireCasperFilter);
    // setMedicalSchoolList(medicalSchoolListData);
  }

  const filterRequireCasper = (data, filter) => {
    let medicalSchoolListData = data;
    
    if(filter.requireCasper.length){
      medicalSchoolListData = medicalSchoolListData.filter(e =>{
        
        return filter.requireCasper.includes(e.requireCasper)
        
      } );
    }

    filterPostbac(medicalSchoolListData, postbacFilter);
    // setMedicalSchoolList(medicalSchoolListData);
  }

  const filterPostbac = (data, filter) => {
    let medicalSchoolListData = data;
    
    if(filter.postbac.length){
      medicalSchoolListData = medicalSchoolListData.filter(e =>{
        
        return filter.postbac.includes(e.postBac)
        
      } );
    }
    else{

      medicalSchoolListData = medicalSchoolListData.filter(e =>{
        
        return typeof e.postBac == "undefined";
        
      } );

    }

    searchNameOfSchool(medicalSchoolListData, searchSchoolName);
    // setMedicalSchoolList(medicalSchoolListData);
  }

  const searchNameOfSchool = (data, filter) => {
    let medicalSchoolListData = data;
    
    
    if(filter){
      medicalSchoolListData = medicalSchoolListData.filter(e => e.overview.schoolName.toLowerCase().includes(filter.toLowerCase()));
    }
    

    setMedicalSchoolList(medicalSchoolListData);
    // setMedicalSchoolList(medicalSchoolListData);
  }

  const getSearchSchoolName = (data) => {
    
    setSearchSchoolName(data.target.value)
  }

  const getStateProvince = (data) => {
    
    setStateProvinceFilter(data.value)
  }

  const getInStateAnnualTuition = (data) => {
    
    setInStateAnnualTuitionFilter(data.value)
  }

  const getCampusType = (data) => {
    
    let {campusType} = campusTypeFilter;
    if(data.target.checked){

      setCampusTypeFilter({
        campusType: [...campusType, data.target.value],
      });
    }
    else{

      setCampusTypeFilter({
        campusType: campusType.filter(e => e !== data.target.value)
      });

    }

    
  }

  const getSchoolType = (data) => {
    
    
  
    let {schoolType} = schoolTypeFilter;
    if(data.target.checked){

      setSchoolTypeFilter({
        schoolType: [...schoolType, data.target.value],
      });
    }
    else{

      setSchoolTypeFilter({
        schoolType: schoolType.filter(e => e !== data.target.value)
      });

    }


  }

  const getRequireCasper = (data) => {
    
    
  
    let {requireCasper} = requireCasperFilter;
    if(data.target.checked){
      
      setRequireCasperFilter({
        requireCasper: [...requireCasper, data.target.value],
      });
    }
    else{

      setRequireCasperFilter({
        requireCasper: requireCasper.filter(e => e !== data.target.value)
      });

    }
    

  }

  const getPostBac = (data) => {
    
    
  
    let {postbac} = postbacFilter;
    if(data.target.checked){
      
      setPostbacFilter({
        postbac: [...postbac, data.target.value],
      });
    }
    else{

      setPostbacFilter({
        postbac: postbac.filter(e => e !== data.target.value)
      });

    }
    

  }


  const getMedianGPA = (data) => {
    
    setMedianGPAFilter({min:parseFloat(data[0]),max:parseFloat(data[1])})
  }

  const getMedianMCAT = (data) => {
    setMedianMCATFilter({min:parseInt(data[0]),max:parseInt(data[1])})
  }

  const getClassSize = (data) => {
    setClassSizeFilter({min:parseInt(data[0]),max:parseInt(data[1])})
  }


  useEffect(() => {
    if(props.fetchSchoolSearch.loading){
      
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });
    }
    else{
      Swal.close()
    }
    

  }, [props.fetchSchoolSearch])
  

  return (
    <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              
              <Card className="card-profile shadow mt-4 p-4">
                
                  <Row>
                    
                    <Col md="6"><FormGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Enter Institution Name" type="text" onChange={data => getSearchSchoolName(data)}/>
                        </InputGroup>
                      </FormGroup></Col>
                    <Col md="6">
                      
                    </Col>

                    <Col md="12">
                      <h2 className="mb-2 text-primary">
                        FILTERS
                      </h2>
                    </Col>
                  </Row>

                  
                  <Row>
                  
                    <Col md="3">
                      <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                        <FormGroup>
                          <label className="form-control-label text-default" htmlFor="customCheck1">
                            STATE OR PROVINCE
                          </label>
                          {/* <Input
                            id="state-or-province"
                            placeholder="name@example.com"
                            type="select"
                            onChange={(data) => getStateProvince(data)}
                            >
                              <option  value="" >ALL</option>
                            {
                              medicalSchoolStateList.map((value, key)=>{

                                return <option key={key} value={value} >{value}</option>
                              })
                            }

                          </Input> */}
                          <Select 
                            placeholder="Select State or Province" 
                            options={medicalSchoolStateList} 
                            styles={customDropdownWithBorder }  
                            onChange={getStateProvince}
                            menuPortalTarget={document.body}
                            value={medicalSchoolStateList.find((option) => option.value === stateProvinceFilter)}
                          />
                        </FormGroup>
                        </Col>

                        <Col md="12">
                        <FormGroup>
                          <label className="form-control-label text-default" htmlFor="customCheck1">
                            IN-STATE ANNUAL TUITION
                          </label>
                          {/* <Input
                            id="in-state-annual-tuition"
                            placeholder="name@example.com"
                            type="select"
                            onChange={(data) => getInStateAnnualTuition(data)}
                          >
                            <option  value="" >ALL</option>
                            <option  value="10000" >$10,000 - $20,000</option>
                            <option  value="20001" >$20,001 - $30,000</option>
                            <option  value="30001" >$30,001 - $40,000</option>
                            <option  value="40001" >$40,001 - $50,000</option>
                            <option  value="50001" >$50,001+</option>
                          </Input> */}

                          <Select 
                            menuPortalTarget={document.body}
                            placeholder="Select In-state annual tuition" 
                            options={[
                              { value: '', label: 'ALL' },
                              { value: '10000', label: '$10,000 - $20,000' },
                              { value: '20001', label: '$20,001 - $30,000' },
                              { value: '30001', label: '$30,001 - $40,000' },
                              { value: '40001', label: '$40,001 - $50,000' },
                              { value: '50001', label: '$50,001+' },
                            ]} 
                            styles={customDropdownWithBorder }  
                            onChange={getInStateAnnualTuition}
                            value={[
                              { value: '', label: 'ALL' },
                              { value: '10000', label: '$10,000 - $20,000' },
                              { value: '20001', label: '$20,001 - $30,000' },
                              { value: '30001', label: '$30,001 - $40,000' },
                              { value: '40001', label: '$40,001 - $50,000' },
                              { value: '50001', label: '$50,001+' },
                            ].find((option) => option.value === inStateAnnualTuitionFilter)}
                          />

                        </FormGroup>
                        </Col>

                      </Row>
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <label
                            className="form-control-label text-default"
                            htmlFor="median-gpa"
                          >
                            MEAN GPA <span className='text-warning'>&nbsp;{parseFloat(medianGPAFilter.min)} - {parseFloat(medianGPAFilter.max)}</span>
                          </label>
                          <Nouislider range={{ min: 3, max: 4 }} start={[3, 4]} connect  onSlide={(data) => getMedianGPA(data)}/>
                        </Col>
                        <Col md="12">
                          <label
                            className="form-control-label text-default"
                            htmlFor="median-mcat"
                          >
                            MEAN MCAT <span className='text-warning'>&nbsp;{parseInt(medianMCATFilter.min)} - {parseInt(medianMCATFilter.max)}</span>
                          </label>
                          <Nouislider range={{ min: 472, max: 528 }} start={[472, 528]} connect onSlide={(data) => getMedianMCAT(data)} />
                        </Col>
                        <Col md="12">
                          <label
                            className="form-control-label text-default"
                            htmlFor="class-size"
                          >
                            CLASS SIZE <span className='text-warning'>&nbsp;{parseInt(classSizeFilter.min)} - {parseInt(classSizeFilter.max)}</span>
                          </label>
                          <Nouislider range={{ min: 40, max: 500 }} start={[40, 500]} connect onSlide={(data) => getClassSize(data)} />
                        </Col>
                      </Row>
                      </div>
                    </Col>

                    <Col md="6">
                      <div className="pl-lg-4">
                      <Row>
                        <Col md="4">
                          <label
                            className="form-control-label text-default"
                            htmlFor="median-gpa"
                          >
                            SCHOOL TYPE
                          </label>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="md-school-type"
                              type="checkbox"
                              value="MD"
                              onChange={(data) => getSchoolType(data)}
                            />
                            <label className="custom-control-label form-control-label" htmlFor="md-school-type">
                              MD
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="do-school-type"
                              type="checkbox"
                              value="DO"
                              onChange={(data) => getSchoolType(data)}
                            />
                            <label className="custom-control-label form-control-label" htmlFor="do-school-type">
                              DO
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="canadian-school-type"
                              type="checkbox"
                              value="CANADIAN"
                              onChange={(data) => getSchoolType(data)}
                            />
                            <label className="custom-control-label form-control-label" htmlFor="canadian-school-type">
                              CANADIAN
                            </label>
                          </div>

                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="international-school-type"
                              type="checkbox"
                              value="INTERNATIONAL"
                              onChange={(data) => getSchoolType(data)}
                            />
                            <label className="custom-control-label form-control-label" htmlFor="international-school-type">
                              INTERNATIONAL
                            </label>
                          </div>
                        </Col>

                        <Col md="4">
                          <label
                            className="form-control-label text-default"
                            htmlFor="median-gpa"
                          >
                            CAMPUS TYPE
                          </label>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="urban-campus-type"
                              type="checkbox"
                              value="URBAN"
                              onChange={(data) => getCampusType(data)}
                            />
                            <label className="custom-control-label form-control-label" htmlFor="urban-campus-type">
                              URBAN
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="suburban-campus-type"
                              type="checkbox"
                              value="SUBURBAN"
                              onChange={(data) => getCampusType(data)}
                            />
                            <label className="custom-control-label form-control-label" htmlFor="suburban-campus-type">
                              SUBURBAN
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="rural-campus-type"
                              type="checkbox"
                              value="RURAL"
                              onChange={(data) => getCampusType(data)}
                            />
                            <label className="custom-control-label form-control-label" htmlFor="rural-campus-type">
                              RURAL
                            </label>
                          </div>
                        </Col>

                        <Col lg="4">
                          <label
                              className="form-control-label text-default"
                              htmlFor="median-gpa"
                            >
                              REQUIRE
                            </label>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input"
                                id="casper-require"
                                type="checkbox"
                                value="Y"
                                onChange={(data) => getRequireCasper(data)}
                              />
                              <label className="custom-control-label form-control-label" htmlFor="casper-require">
                                CASPER
                              </label>
                            </div>
                            <label
                              className="form-control-label text-default"
                              htmlFor="median-gpa"
                            >
                              PROGRAM
                            </label>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input"
                                id="postbac"
                                type="checkbox"
                                value="Y"
                                onChange={(data) => getPostBac(data)}
                              />
                              <label className="custom-control-label form-control-label" htmlFor="postbac">
                                POSTBAC
                              </label>
                            </div>

                        </Col>

                      </Row>
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="pl-lg-4">
                      <Row>
                      
                      </Row>
                      </div>
                    </Col>
                  </Row>
                
              </Card>
            </div>
          </Container>
          <Container fluid className='mt-3 text-center'>
            <h3 className='text-secondary'>Medical Schools - {medicalSchoolList.length} results</h3>
            <h3 className='text-secondary'>Click on a school name to view more details.</h3>
          </Container>
          
        </div>
        
        <Container className="mt--7" fluid>
        
            {
              medicalSchoolList.sort((a, b) => {
                const nameA = a.overview.schoolName.toLowerCase();
                const nameB = b.overview.schoolName.toLowerCase();
              
                if (nameA < nameB) {
                  return -1; // a should be placed before b
                }
                if (nameA > nameB) {
                  return 1; // a should be placed after b
                }
                return 0; // names are equal, no change in order
              })
              .map((value, key)=>{
                return (<LazyLoad key={key} height={100} >
                  <SchoolCard key={key+"1"} medicalSchoolDetails={value} placeholder={<h4>Loading...</h4>}></SchoolCard>
                </LazyLoad>)
                // return 
              })
            }
           
        </Container>
    </>
  )
}

const mapStateToProps = state  => {
  return {
      fetchSchoolSearch : state.fetchSchoolSearch,
      userDetails : state.loginAuth.userDetails,
      
  }
    
}

export default connect(mapStateToProps)(SchoolSearch);
