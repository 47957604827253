import { 
    OPEN_WORK_ACTIVITY_MODAL,
    CLOSE_WORK_ACTIVITY_MODAL,
    CREATE_WORK_ACTIVITY_REQUEST, 
    CREATE_WORK_ACTIVITY_SUCCESS, 
    CREATE_WORK_ACTIVITY_FAILURE,
    CREATE_WORK_ACTIVITY_RESET,
    FETCH_WORK_ACTIVITIES_REQUEST, 
    FETCH_WORK_ACTIVITIES_SUCCESS, 
    FETCH_WORK_ACTIVITIES_FAILURE,
    EDIT_WORK_ACTIVITY,
    NEW_WORK_ACTIVITY,
    UPDATE_WORK_ACTIVITY_REQUEST, 
    UPDATE_WORK_ACTIVITY_SUCCESS, 
    UPDATE_WORK_ACTIVITY_FAILURE,
    UPDATE_WORK_ACTIVITY_RESET,
    DELETE_WORK_ACTIVITY_REQUEST,
    DELETE_WORK_ACTIVITY_SUCCESS, 
    DELETE_WORK_ACTIVITY_FAILURE,
    DELETE_WORK_ACTIVITY_RESET  } from "./WorkActivitiesTypes";

const initialCreateWorkActivityState = {
    loading: false,
    workactivity: [],
}

const initialUpdateWorkActivityState = {
    loading: false,
    workactivity: [],
}

const initialDeleteWorkActivityState = {
    loading: false,
    workactivity: [],
}

const initialFetchWorkActivitiesState = {
    workactivities : [],
    loading: false,

}

const initialModalWorkActivityState = {
    status: false
}

const initialEditWorkActivityState = {
    status: false,
    workactivityId : ''
}

const initialNewWorkActivityState = {
    status: false,
}

export const createWorkActivitiesReducer = (state = initialCreateWorkActivityState, action) => {
    switch (action.type) {
        case CREATE_WORK_ACTIVITY_RESET:
            return {
                loading: false,
                workactivity: [],
            }
        case CREATE_WORK_ACTIVITY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_WORK_ACTIVITY_SUCCESS:
            return {
                loading: false,
                workactivity: [action.payload]
            }
        case CREATE_WORK_ACTIVITY_FAILURE:
            return {
                loading: false,
                workactivity: [],
                error: action.payload
            }
        default: return state;
    }
}

export const fetchWorkActivitiesReducer = (state = initialFetchWorkActivitiesState, action) => {
    switch (action.type) {
        case FETCH_WORK_ACTIVITIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_WORK_ACTIVITIES_SUCCESS:
            return {
                loading: false,
                workactivities: action.payload
            }
        case FETCH_WORK_ACTIVITIES_FAILURE:
            return {
                loading: false,
                workactivities: [],
                error: action.payload
            }
        default: return state;
    }
}

export const updateWorkActivityReducer = (state = initialUpdateWorkActivityState, action) => {
    switch (action.type) {
        case UPDATE_WORK_ACTIVITY_RESET:
            return {
                loading: false,
                workactivity: [],
            }
        case UPDATE_WORK_ACTIVITY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_WORK_ACTIVITY_SUCCESS:
            return {
                loading: false,
                workactivity: [action.payload]
            }
        case UPDATE_WORK_ACTIVITY_FAILURE:
            return {
                loading: false,
                workactivity: [],
                error: action.payload
            }
        default: return state;
    }
}

export const editWorkActivityReducer = (state = initialEditWorkActivityState, action) => {
    switch (action.type) {
        case EDIT_WORK_ACTIVITY:
            return {
                status: action.payload.status,
                workactivityId: action.payload.workactivityId
            }
        default: return state;
    }
}

export const newWorkActivityReducer = (state = initialNewWorkActivityState, action) => {
    switch (action.type) {
        case NEW_WORK_ACTIVITY:
            return {
                status: action.payload.status,
            }
        default: return state;
    }
}

export const updateWorkActivityModalStatusReducer = (state = initialModalWorkActivityState, action) => {
    switch (action.type) {
        case OPEN_WORK_ACTIVITY_MODAL:
            return {
                status : true
            }
        case CLOSE_WORK_ACTIVITY_MODAL:
            return {
                status : false
            }
        default: return state;
    }
}


export const deleteWorkActivityReducer = (state = initialDeleteWorkActivityState, action) => {
    switch (action.type) {
        case DELETE_WORK_ACTIVITY_RESET:
            return {
                loading: false,
                workactivity: [],
            }
        case DELETE_WORK_ACTIVITY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_WORK_ACTIVITY_SUCCESS:
            return {
                loading: false,
                workactivity: [action.payload]
            }
        case DELETE_WORK_ACTIVITY_FAILURE:
            return {
                loading: false,
                workactivity: [],
                error: action.payload
            }
        default: return state;
    }
}

