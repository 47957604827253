import React, { useState, useEffect } from 'react';
import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, FormGroup, Label, Input, FormText, CardBody, Card } from 'reactstrap';
import Header from 'components/Headers/Header';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createTimelineSchedule, fetchTimelineSchedule } from "redux/Timeline/TimelineActions";
import { connect, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { premedReqs } from 'util/DataList';

// const CardDiv = ({ title, category, color, source, onRemove, isDefault, isPrevious, onEdit }) => {
//   const [{ isDragging }, drag] = useDrag({
//     type: 'CARD',
//     item: { title, category, color, source, isDefault },
//     collect: (monitor) => ({
//       isDragging: !!monitor.isDragging(),
//     }),
//     canDrag: !isPrevious, // Disable drag if card is from previous data
//   });

//   return (
//     <div
//       ref={!isPrevious ? drag : null} // Only enable drag ref if not previous data
//       style={{
//         opacity: isDragging ? 0.5 : 1, // Lower opacity for previous data
//         backgroundColor: color, // Grey out color for previous data
//         padding: '10px',
//         margin: '5px 0',
//         borderRadius: '5px',
//         color: '#fff', // Lighter text color for previous data
//         fontWeight: 'bold',
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         cursor: isPrevious ? 'default' : 'move', // Change cursor if previous data
//         boxShadow: '10px solid'
//       }}
//     >
//       <div>
//         <span className='d-block mb-1 text-xs opacity-7'>{category}</span>
//         <span className='text-sm'>{title}</span>
//       </div>
//       {!isPrevious && (
//         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//           <Button
//             color="danger"
//             size="sm"
//             onClick={(e) => {
//               e.stopPropagation();
//               onRemove(title, source);
//             }}
//           >
//             X
//           </Button>
//         </div>
//       )}
//     </div>
//   );
// };

const CardDiv = ({ title, category, color, source, onRemove, isDefault, isPrevious, onEdit, list }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: { title, category, color, source, isDefault },
    canDrag: !isPrevious, // Prevent dragging if card is from previous data
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={!isPrevious ? drag : null} // Only attach drag ref if not previous data
      style={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: color,
        padding: '10px',
        margin: '5px 0',
        borderRadius: '5px',
        color: '#fff',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: isPrevious ? 'default' : 'move',
        boxShadow: '10px solid',
      }}
    >
      <div>
        <span className='d-block mb-1 text-xs opacity-7'>{category}</span>
        <span className='text-sm'>{title}</span>
      </div>
      {!isPrevious && !list && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            color="danger"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onRemove(title, source);
            }}
          >
            X
          </Button>
        </div>
      )}
    </div>
  );
};




// const SemesterDropZone = ({ season, year, cards, onDrop, onRemove }) => {
//   const [{ isOver }, drop] = useDrop({
//     accept: 'CARD',
//     drop: (item) => onDrop(item, season, year), // No condition on drop, it’s always active
//     collect: (monitor) => ({
//       isOver: !!monitor.isOver(),
//     }),
//   });

//   return (
//     <div
//       ref={drop}
//       className='mt-3'
//       style={{
//         minHeight: '100px',
//         border: isOver ? '2px dashed #5e72e4' : '2px solid #c0c0c0',
//         padding: '10px',
//         backgroundColor: isOver ? '#f0f8ff' : '#fafafa',
//         borderRadius: '10px',
//         transition: 'background-color 0.3s ease',
//       }}
//     >
//       {cards.length > 0 ? (
//         cards.map((card, index) => (
//           <CardDiv
//             key={index}
//             title={card.title}
//             category={card.isPrevious ? card.category+ " (Log Classes)" : card.category}
//             color={card.color}
//             isPrevious={card.isPrevious}
//             source={{ season, year }}
//             onRemove={onRemove} // Enables remove functionality
//             isDefault={card.isDefault}
//             canDelete={true} // Allows delete for all cards
//           />
//         ))
//       ) : (
//         <p style={{ color: '#888', textAlign: 'center', marginTop: '20px' }}>
//           Drag cards here
//         </p>
//       )}
//     </div>
//   );
// };


const SemesterDropZone = ({ season, year, level, cards, onDrop, onRemove }) => {
  const [{ isOver }, drop] = useDrop({
    accept: 'CARD',
    drop: (item) => onDrop(item, season, year, level),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const academicLevelsColor = {
    Freshman : "#2dce89",
    Sophomore : "#ffd600",
    Junior : "#fb6340",
    Senior : "#f5365c",
    Postbac : "#5e72e4",
    Graduate : "#5603ad"
  }

  return (
    <div
      ref={drop}
      className='mt-3'
      style={{
        minHeight: '100px',
        border: isOver ? '2.5px solid ' + academicLevelsColor[level] : '2.5px dashed ' + academicLevelsColor[level],
        padding: '10px',
        backgroundColor: isOver ? '#f0f8ff' : '#fafafa',
        borderRadius: '10px',
        transition: 'background-color 0.3s ease',
      }}
    >
      <h4 className="text-sm font-weight-bold" style={{color:academicLevelsColor[level]}}>{level}</h4>
 
      {cards.length > 0 ? (
        cards.map((card, index) => (
          <CardDiv
            key={index}
            title={card.title}
            category={card.isPrevious ? `${card.category} (Log Classes)` : card.category}
            color={card.color}
            isPrevious={card.isPrevious}
            source={{ season, year, level }}
            onRemove={onRemove}
            isDefault={card.isDefault}
          />
        ))
      ) : (
        <p className='text-gray' style={{  textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>Drag cards here</p>
      )}
    </div>
  );
};




const Timeline = (props) => {
  const currentYear = new Date().getFullYear();
  const [years, setYears] = useState([currentYear]);
  const [semesterData, setSemesterData] = useState({});
  const [modal, setModal] = useState(false);
  const [availableCards, setAvailableCards] = useState([]);
  const [tempData, setTempData] = useState({ category: '', title: '', color: '#66b2ff' });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const toggleModal = () => setModal(!modal);

  const handleInputChange = (field, value) => {
    setTempData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const [showPreviousData, setShowPreviousData] = useState(false);
  const [previousData, setPreviousData] = useState([]);

  useEffect(() => {
    dispatch(fetchTimelineSchedule({ user: props.userDetails.handle }));

    setSemesterData(initializeSemesterData(currentYear));

    premedReqs.map((value, index) => {
        if(value != "Class not a Premed Requirement" && value != "default"){
            setRequirements((prevRequirements) => ({
                ...prevRequirements,
                [value]: false
            }));
        }
        
    })


  }, []);


  const initializeSemesterData = (year) => {
    const yearData = {};
    seasons.forEach((season) => {
        yearData[season] = {};
        academicLevels.forEach((level) => {
            yearData[season][level] = []; // Empty array for cards in each level
        });
    });
    return { [year]: yearData };
  };

  

  useEffect(() => {
      if(props.fetchTimelineSchedule.loading){
        Swal.fire({
            allowOutsideClick : false,
            didOpen: () => {
                Swal.showLoading()
                
            }
        });
      }
      else{

        Swal.close()
        if (props.fetchTimelineSchedule && props.fetchTimelineSchedule.timelineschedule && props.fetchTimelineSchedule.timelineschedule.data) {

            setSemesterData(props.fetchTimelineSchedule.timelineschedule.data)

            let dataYears = [...new Set([
                ...Object.keys(props.fetchTimelineSchedule.timelineschedule.data).map(Number), 
                ...[currentYear].map(Number)
            ])];
            
            dataYears = dataYears.length ? dataYears : [currentYear];
            setYears(dataYears);

            if(typeof props.fetchTimelineSchedule.timelineschedule.availableCards != "undefined"){
                setAvailableCards(props.fetchTimelineSchedule.timelineschedule.availableCards)
            }
            

        }
      }
  }, [props.fetchTimelineSchedule]);


  useEffect(() => {
        
    dispatch(fetchTimelineSchedule({user:props.userDetails.handle}))
    
  }, [props.createTimelineSchedule])

  // const handleDrop = (card, season, year) => {
  //   setSemesterData((prev) => {
  //     const updatedData = { ...prev };

  //     // If card is from another zone, remove it from its previous position
  //     if (card.source) {
  //       const { season: sourceSeason, year: sourceYear } = card.source;
  //       if (updatedData[sourceYear] && updatedData[sourceYear][sourceSeason]) {
  //         updatedData[sourceYear][sourceSeason] = updatedData[sourceYear][sourceSeason].filter(
  //           (existingCard) => existingCard.title !== card.title
  //         );
  //       }
  //     }

  //     // Remove from available cards if dragged from there
  //     console.log(card)
      // if (!card.isDefault && !card.source) {
      //   setAvailableCards((prevCards) => {

      //       dispatch(createTimelineSchedule({
      //           data: semesterData,
      //           availableCards : prevCards.filter((availableCard) => availableCard.title !== card.title),
      //           user: props.userDetails.handle
      //       }));

      //       return prevCards.filter((availableCard) => availableCard.title !== card.title)
        
      //   });
        
      // }

  //     // Initialize year and season if not already present
  //     updatedData[year] = updatedData[year] || {};
  //     updatedData[year][season] = updatedData[year][season] || [];

  //     // Avoid duplicates in the target drop zone
  //     if (!updatedData[year][season].some((existingCard) => existingCard.title === card.title)) {
  //       // Add card to target drop zone
  //       updatedData[year][season].push({ ...card, source: { season, year } });
  //     }

  //     dispatch(createTimelineSchedule({
  //       data: updatedData,
  //       availableCards : availableCards,
  //       user: props.userDetails.handle
  //     }));

  //     return updatedData;
  //   });
  // };

  const handleDrop = (card, season, year, level) => {
    
    // Process the drop event
    setSemesterData((prev) => {
      const updatedData = { ...prev };
  
      // If moving from another drop zone, remove it from the previous location
      if (card.source) {
        const { season: sourceSeason, year: sourceYear, level: sourceLevel } = card.source;
        if (updatedData[sourceYear]?.[sourceSeason]?.[sourceLevel]) {
          updatedData[sourceYear][sourceSeason][sourceLevel] = updatedData[sourceYear][sourceSeason][sourceLevel].filter(
            (existingCard) => existingCard.title !== card.title
          );
        }
      }

      
  
      // Initialize year, season, and level if not already present
      updatedData[year] = updatedData[year] || {};
      updatedData[year][season] = updatedData[year][season] || {};
      updatedData[year][season][level] = updatedData[year][season][level] || [];
  
      // Add card to target drop zone
      if (!updatedData[year][season][level].some((existingCard) => existingCard.title === card.title)) {
        updatedData[year][season][level].push({ ...card, source: { season, year, level } });
      }

      

      if (!card.isDefault && !card.source) {
        setAvailableCards((prevCards) => {

            dispatch(createTimelineSchedule({
                data: updatedData,
                availableCards : prevCards.filter((availableCard) => availableCard.title !== card.title),
                user: props.userDetails.handle
            }));

            return prevCards.filter((availableCard) => availableCard.title !== card.title)
        
        });
        
      }
      else{
        dispatch(createTimelineSchedule({
          data: updatedData,
          availableCards : availableCards,
          user: props.userDetails.handle
        }));
      }

      
   
      return updatedData;
    });
  };
  
  
  

  const removeCard = (title, source) => {
    const { season, year, level } = source; // Include level in destructuring

    setSemesterData((prev) => {
        const updatedData = {
            ...prev,
            [year]: {
                ...prev[year],
                [season]: {
                    ...prev[year][season],
                    [level]: prev[year][season][level].filter((card) => card.title !== title) // Filter out the card within the specific level
                }
            }
        };

        dispatch(createTimelineSchedule({
            data: updatedData,
            availableCards: availableCards,
            user: props.userDetails.handle
        }));

        return updatedData;
    });
  };

  // const addYear = () => {
  //   const lastYear = years[years.length - 1];
  //   const newYear = lastYear + 1;

  //   setYears([...years, newYear]);
  //   setSemesterData((prevData) => ({
  //     ...prevData,
  //     [newYear]: {
  //       Spring: [],
  //       Summer: [],
  //       Fall: [],
  //       Winter: [],
  //     },
  //   }));
  // };


  const addYear = () => {
    const lastYear = years[years.length - 1];
    const newYear = lastYear + 1;
  
    setYears([...years, newYear]);
    setSemesterData((prevData) => ({
      ...prevData,
      [newYear]: seasons.reduce((seasonAcc, season) => {
        seasonAcc[season] = academicLevels.reduce((levelAcc, level) => {
          levelAcc[level] = [];
          return levelAcc;
        }, {});
        return seasonAcc;
      }, {})
    }));
  };


  const deleteYear = (year) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "This will delete all data for this year.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#f5365c",
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setYears((prevYears) => prevYears.filter((y) => y !== year));
        setSemesterData((prevData) => {
          const updatedData = { ...prevData };
          delete updatedData[year];

          dispatch(createTimelineSchedule({
            data: updatedData,
            availableCards : availableCards,
            user: props.userDetails.handle
          }));
          
          return updatedData;
        });
      }
    });
  };


  const addCustomCard = () => {
    if (!tempData.category || !tempData.title) {
      setErrors({
        category: !tempData.category ? 'Category is required' : '',
        title: !tempData.title ? 'Title is required' : ''
      });
      return;
    }

    const newCard = { ...tempData, isDefault: false };
    setAvailableCards((prev) => {
        
        dispatch(createTimelineSchedule({
            data: semesterData,
            availableCards : [...prev, newCard],
            user: props.userDetails.handle
        }));

        return [...prev, newCard]
    
    });
    
    toggleModal();
  };

  const timelineIcons = {
    Spring: { icon: "fas fa-leaf", color: "#2dce89" },
    Summer: { icon: "fas fa-sun", color: "#ffd600" },
    Fall: { icon: "fab fa-canadian-maple-leaf", color: "#fb6340" },
    Winter: { icon: "fas fa-snowflake", color: "#11cdef" },
  };

  const removeAvailableCard = (title) => {
    setAvailableCards((prev) => {
        
        dispatch(createTimelineSchedule({
            data: semesterData,
            availableCards : prev.filter((card) => card.title !== title),
            user: props.userDetails.handle
        }));

        return prev.filter((card) => card.title !== title)
    
    });
    
  };
  
  const [requirements, setRequirements] = useState({});

    useEffect(() => {
        if(props.fetchCourses.courses.length){
            props.fetchCourses.courses.map((value, index) => {
                if(value.reqs.length){
                    value.reqs.map((reqsValue, reqsIndex) => {
                        if(reqsValue != "default" && reqsValue != "Class not a Premed Requirement"){
                            
                            setRequirements((prevRequirements) => ({
                                ...prevRequirements,
                                [reqsValue]: true
                            }));

                           
                        }
                    })
                }
            
            });

            setPreviousData(props.fetchCourses.courses);

        }
    }, [props.fetchCourses])

  const seasons = ["Spring", "Summer", "Fall", "Winter"];
  

  // Helper function to get combined data based on the `showPreviousData` toggle
  // Deep copy utility function
  const deepCopy = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };

  // Use deep copy for combinedData
  // const getDisplayData = () => {
  //   const combinedData = deepCopy(semesterData);
  
  //   // if (showPreviousData) {
  //     previousData.forEach((prev) => {
  //       if (!combinedData[prev.year]) {
  //         combinedData[prev.year] = {};
  //       }
  //       seasons.forEach((season) => {
  //         if (prev.term === season) {
  //           combinedData[prev.year][season] = [
  //             ...(combinedData[prev.year][season] || []),
  //             ...prev.reqs.map((title) => ({
  //               title,
  //               category: "Classes",
  //               color: '#f5365c',
  //               isDefault: true,
  //               isPrevious: true, // Mark as previous data
  //             }))
  //           ];
  //         }
  //       });
  //     });
  //   // }
  
  //   // Remove previous years if showPreviousData is false
  //   Object.keys(combinedData).forEach(year => {
  //     if (!showPreviousData && parseInt(year) < currentYear) {
  //       delete combinedData[year];
  //     }
  //   });
  
  //   return combinedData;
  // };

  const getDisplayData = () => {
    const combinedData = deepCopy(semesterData);
 
    // Only include previous data if showPreviousData is true
    
    previousData.forEach((prev) => {
        // Initialize the year and season structure in combinedData
        if (!combinedData[prev.year]) {
            combinedData[prev.year] = {};
        }

        seasons.forEach((season) => {
   
            if (prev.term === season) {
                // Ensure each season contains all academic levels
                combinedData[prev.year][season] = combinedData[prev.year][season] || {};
                academicLevels.forEach((level) => {
                    combinedData[prev.year][season][level] = combinedData[prev.year][season][level] || [];
                });

                // Add previous data to each academic level within the season
                prev.reqs.forEach((title) => {
                    academicLevels.forEach((level) => {

                        if (prev.section.toLowerCase() == level.toLowerCase()){
                          combinedData[prev.year][season][level].push({
                              title,
                              category: "Classes",
                              color: '#f5365c',
                              isDefault: true,
                              isPrevious: true, // Mark as previous data
                          });
                        }
                    });
                });
            }
        });
    });
    

    // Remove previous years if showPreviousData is false
    Object.keys(combinedData).forEach((year) => {
        if (!showPreviousData && parseInt(year) < currentYear) {
            delete combinedData[year];
        }
    });
    
    return combinedData;
  };



  // Function to toggle showPreviousData and refresh displayData
  const togglePreviousDataDisplay = () => {
    setShowPreviousData((prev) => !prev);
  };


  const [inputYear, setInputYear] = useState(''); // Stores selected year for previous year addition
  const [showYearModal, setShowYearModal] = useState(false);

  // Dropdown options for previous years only
  const previousYearOptions = [];
  for (let year = currentYear - 1; year >= 1980; year--) {
    previousYearOptions.push(year);
  }

  // Toggle the modal for selecting a previous year
  const toggleYearModal = () => setShowYearModal(!showYearModal);

  // Function to add a previous year by opening the modal
  const addPreviousYear = () => {
    toggleYearModal();
  };

  // const confirmAddPreviousYear = () => {
  //   const yearToAdd = parseInt(inputYear);

  //   if (yearToAdd && !years.includes(yearToAdd)) {
  //     setYears((prevYears) => [...prevYears, yearToAdd].sort());

  //     // Update semesterData with initialized seasons for the added previous year
  //     setSemesterData((prevData) => ({
  //       ...prevData,
  //       [yearToAdd]: {
  //         Spring: [],
  //         Summer: [],
  //         Fall: [],
  //         Winter: [],
  //       },
  //     }));

  //     Swal.fire({
  //       title: "Success!",
  //       text: "Year added successfully!",
  //       icon: "success"
  //     });

  //   } else {

  //     Swal.fire({
  //       title: "Failed!",
  //       text: "This year already exists or is invalid.",
  //       icon: "error"
  //     });

  //   }

  //   setInputYear(''); // Clear the input
  //   toggleYearModal(); // Close the modal
  // };


  const confirmAddPreviousYear = () => {
    const yearToAdd = parseInt(inputYear);
  
    if (yearToAdd && !years.includes(yearToAdd)) {
      setYears((prevYears) => [...prevYears, yearToAdd].sort());
  
      setSemesterData((prevData) => ({
        ...prevData,
        [yearToAdd]: seasons.reduce((seasonAcc, season) => {
          seasonAcc[season] = academicLevels.reduce((levelAcc, level) => {
            levelAcc[level] = [];
            return levelAcc;
          }, {});
          return seasonAcc;
        }, {})
      }));
  
      Swal.fire({
        title: "Success!",
        text: "Year added successfully!",
        icon: "success"
      });

    } else {

      Swal.fire({
        title: "Failed!",
        text: "This year already exists or is invalid.",
        icon: "error"
      });

    }
  
    setInputYear('');
    toggleYearModal();
  };


  const academicLevels = ["Freshman", "Sophomore", "Junior", "Senior", "Postbac", "Graduate"];


  
  return (
    <DndProvider backend={HTML5Backend}>
      <Header />
      <Container className="mt--7 bg-info pb-9" fluid>
        <Row>
          <Col className="text-lg-center">
            <p className="display-2 text-white font-weight-bolder">
              <i className="fas fa-stream"></i> Timeline
            </p>
          </Col>
        </Row>

        <Row>
          {/* Default and Available Cards Section */}

          <Col md={4} className='mb-5 mt-5'>
            <Card  style={{position:"sticky", top:"50px" , maxHeight:"90vh", overflowY:"auto"}}>
                <CardBody>
                    <h3 className='font-weight-bolder text-default'>Default Cards :</h3>
                
                
                    {Object.entries(requirements).map(([key, value], index) => {
                        if(!value){                        
                            if(key !== "Class not a Premed Requirement"){

                                

                                return (
                                    <CardDiv key={index} title={key} category={'Classes'} color={'#8965e0'} isDefault={true} list={true} />
                                )
                            }
                        }
                    })}
                    
                
                    <h3 className="mt-4 font-weight-bolder text-default">Available Cards :</h3>
                    {availableCards.map((card, index) => (
                    <CardDiv
                        key={index}
                        title={card.title}
                        category={card.category}
                        color={card.category == "Classes" ? "#11cdef" : card.category == "Extracurriculars" ? "#2dce89" : "#172b4d"}
                        onRemove={removeAvailableCard}
                    />
                    ))}
                    <Button color="primary" className='mt-3 mb-4' onClick={toggleModal}>Add Custom Card</Button>
                </CardBody>
            </Card>
          </Col>

          {/* Timeline Section */}
          <Col md={8}>
            {/* <VerticalTimeline lineColor="#5603ad">
              {years.map((year) => (
                <div key={year}>
                  <div className='mt-4' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2>{""}</h2>
                    {year !== currentYear && (
                      <Button color="danger" onClick={() => deleteYear(year)}>
                        <i className="fas fa-trash"></i> Delete {year}
                      </Button>
                    )}
                  </div>
                  {["Spring", "Summer", "Fall", "Winter"].map((season) => (
                    <VerticalTimelineElement
                      key={`${season}-${year}`}
                      className="vertical-timeline-element--work"
                      dateClassName="text-xl font-weight-bold text-white pt-2 opacity-10 d-none d-xl-block d-md-none pl-2 pr-2"
                      date={season === "Winter" ? `Winter ${year}/${year + 1}` : `${season} ${year}`}
                      iconStyle={{ background: timelineIcons[season].color, color: '#fff', display:"flex", alignItems:"center", justifyContent:"center" }}
                      icon={<i className={timelineIcons[season].icon + " text-xl"}></i>}
                    >
                      <h3 className="vertical-timeline-element-title">{`${season === "Winter" ? `Winter ${year}/${year + 1}` : `${season} ${year}`} Schedule`}</h3>
                      <SemesterDropZone
                          season={season}
                          year={year}
                          cards={(semesterData[year]?.[season] || [])}
                          onDrop={handleDrop}
                          onRemove={removeCard}
                      />
                    </VerticalTimelineElement>
                  ))}
                </div>
              ))}
              <VerticalTimelineElement
                iconStyle={{ cursor:"pointer", background: '#5e72e4', color: '#fff', display:"flex", alignItems: "center", justifyContent: "center" }}
                icon={<i className="fas fa-plus text-xl w-100 h-100 d-flex align-items-center justify-content-center" onClick={addYear}></i>}
              />
            </VerticalTimeline> */}


        <VerticalTimeline lineColor="#5603ad">
          <VerticalTimelineElement
            iconStyle={{
              cursor: "pointer",
              background: previousData.some(item => !item.hasOwnProperty('year')) ? '#c0c0c0' : "#5e72e4",
              color: '#fff',
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            icon={<i className={(showPreviousData ? "fas fa-eye-slash" : "fas fa-eye") + " text-xl w-100 h-100 d-flex align-items-center justify-content-center"} 
            onClick={togglePreviousDataDisplay}></i>}
            date={showPreviousData ? "Hide Previous Data" : "Show Previous Data"}
            dateClassName="text-xl font-weight-bold text-white pt-2 opacity-10 d-none d-xl-block d-md-none pl-2 pr-2"
          />
            {/* {previousData.some(item => !item.hasOwnProperty('year')) &&
              <span className='font-weight-bolder text-warning text-sm text-center'>
                Please update or log your classes on the Log Classes page to view past data.
              </span>
            }
          </VerticalTimelineElement> */}
          {showPreviousData ?
            <VerticalTimelineElement
              iconStyle={{ cursor: "pointer", background: '#5e72e4', color: '#fff', display: "flex", alignItems: "center", justifyContent: "center" }}
              date="Add Previous Year"
              dateClassName="text-xl font-weight-bold text-white pt-2 opacity-10 d-none d-xl-block d-md-none pl-2 pr-2"

              icon={<i className="fas fa-plus text-xl w-100 h-100 d-flex align-items-center justify-content-center" onClick={addPreviousYear}></i>}
            /> : ""
          }


          {/* Render combined data */}
          {/* {Object.entries(getDisplayData()).map(([year, seasonsData]) => (
            <div key={year}>
              <div className='mt-4' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2></h2>
                {year != currentYear && (
                  <Button color="danger" onClick={() => deleteYear(year)}>
                    <i className="fas fa-trash"></i> Delete {year}
                  </Button>
                )}
              </div>
              {seasons.map((season) => (
                <VerticalTimelineElement
                  key={`${season}-${year}`}
                  className="vertical-timeline-element--work"
                  dateClassName="text-xl font-weight-bold text-white pt-2 opacity-10 d-none d-xl-block d-md-none pl-2 pr-2"
                  date={`${season} ${year}`}
                  iconStyle={{ background: timelineIcons[season].color, color: '#fff', display: "flex", alignItems: "center", justifyContent: "center" }}
                  icon={<i className={timelineIcons[season].icon + " text-xl"}></i>}
                >
                  <h3 className="vertical-timeline-element-title">{`${season} ${year} Schedule`}</h3>
                  <SemesterDropZone
                    season={season}
                    year={year}
                    cards={(seasonsData[season] || []).map((card) => ({
                      ...card,
                      color: card.isPrevious ? '#d3d3d3' : card.color, // Distinct color for previous data
                    }))}
                    onDrop={handleDrop}
                    onRemove={removeCard}
                  />
                </VerticalTimelineElement>
              ))}
            </div>
          ))} */}


          {Object.entries(getDisplayData()).map(([year, seasonsData]) => (
            <div key={year}>
              <div className='mt-4' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2></h2>
                {year != currentYear && (
                  <Button color="danger" onClick={() => deleteYear(year)}>
                    <i className="fas fa-trash"></i> Delete {year}
                  </Button>
                )}
              </div>
              {seasons.map((season) => (
                <VerticalTimelineElement
                  key={`${season}-${year}`}
                  className="vertical-timeline-element--work"
                  dateClassName="text-xl font-weight-bold text-white pt-2 opacity-10 d-none d-xl-block d-md-none pl-2 pr-2"
                  date={`${season} ${year}`}
                  iconStyle={{ background: timelineIcons[season].color, color: '#fff', display: "flex", alignItems: "center", justifyContent: "center" }}
                  icon={<i className={timelineIcons[season].icon + " text-xl"}></i>}
                >
                  <h3 className="vertical-timeline-element-title">{`${season} ${year} Schedule`}</h3>
              
                  {academicLevels.map((level) => (
                    <SemesterDropZone
                      key={level}
                      season={season}
                      year={year}
                      level={level}
                      cards={(seasonsData[season][level] || []).map((card) => ({
                        ...card,
                        color: card.isPrevious ? '#d3d3d3' : card.color,
                      }))}
                      onDrop={handleDrop}
                      onRemove={removeCard}
                    />
                  ))}
                </VerticalTimelineElement>
              ))}
            </div>
          ))}


          <VerticalTimelineElement
            iconStyle={{ cursor: "pointer", background: '#5e72e4', color: '#fff', display: "flex", alignItems: "center", justifyContent: "center" }}
            icon={<i className="fas fa-plus text-xl w-100 h-100 d-flex align-items-center justify-content-center" onClick={addYear}></i>}
            date="Add Next Year"
            dateClassName="text-xl font-weight-bold text-white pt-2 opacity-10 d-none d-xl-block d-md-none pl-2 pr-2"
          />
          </VerticalTimeline>


            {/* <VerticalTimeline lineColor="#5603ad"> */}
            {/* previousData.some(item => !item.hasOwnProperty('year')); */}
              {/* <VerticalTimelineElement
                iconStyle={{ cursor:"pointer", background: previousData.some(item => !item.hasOwnProperty('year')) ? '#c0c0c0' : "#5e72e4" , color: '#fff', display:"flex", alignItems: "center", justifyContent: "center" }}
                icon={<i className={(showPreviousData ? "fas fa-eye-slash" : "fas fa-eye" ) + " text-xl w-100 h-100 d-flex align-items-center justify-content-center"} 
                onClick={() => {

                  if (!previousData.some(item => !item.hasOwnProperty('year'))){
                    setShowPreviousData((prev) => !prev)
                  }
                  
                  
                }}></i>} 
                date={showPreviousData ? "Hide Previous Data" : "Show Previous Data" }
                dateClassName="text-xl font-weight-bold text-white pt-2 opacity-10 d-none d-xl-block d-md-none pl-2 pr-2"
                >
                {previousData.some(item => !item.hasOwnProperty('year')) ?
                  <span className='font-weight-bolder text-warning text-sm text-center'>Please update or log your classes on the Log Classes page to view past data.</span>
                  : ""
                }
              </VerticalTimelineElement> */}

              {/* Render previous data for each year, showing all seasons */}
              {/* {showPreviousData && previousData.map((prev) => (
                <div key={prev.year}>
                  <div className='mt-4' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2></h2>
                  </div>
                  {seasons.map((season) => {
                    const seasonData = prev.term === season ? prev.reqs : [];

                    return (
                      <VerticalTimelineElement
                        key={`${season}-${prev.year}`}
                        className="vertical-timeline-element--work"
                        dateClassName="text-xl font-weight-bold text-white pt-2 opacity-10 d-none d-xl-block d-md-none pl-2 pr-2"
                        date={`${season} ${prev.year}`}
                        iconStyle={{ background: '#c0c0c0', color: '#fff', display: "flex", alignItems: "center", justifyContent: "center" }}
                        icon={<i className="fas fa-history text-xl"></i>}
                      >
                        <h3 className="vertical-timeline-element-title">{`${season} ${prev.year} Schedule`}</h3>
                        <SemesterDropZone
                          season={season}
                          year={prev.year}
                          cards={seasonData.map((title) => ({
                            title,
                            category: "Classes",
                            color: '#f5365c',
                            isDefault: true
                          }))}
                          onDrop={null} // No drop function for previous data
                          onRemove={null} // No remove function for previous data
                          disabled={true} // Disable the zone for previous years
                        />
                      </VerticalTimelineElement>
                    );
                  })}
                </div>
              ))} */}

              {/* {showPreviousData && previousData.map((prev) => (
                <div key={prev.year}>
                  <div className='mt-4' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2>{prev.year} Academic Year</h2>
                  </div>
                  {seasons.map((season) => {
                    const seasonData = prev.term === season ? prev.reqs : [];

                    return (
                      <VerticalTimelineElement
                        key={`${season}-${prev.year}`}
                        className="vertical-timeline-element--work"
                        dateClassName="text-xl font-weight-bold text-white pt-2 opacity-10 d-none d-xl-block d-md-none pl-2 pr-2"
                        date={`${season} ${prev.year}`}
                        iconStyle={{ background: '#c0c0c0', color: '#fff', display: "flex", alignItems: "center", justifyContent: "center" }}
                        icon={<i className="fas fa-history text-xl"></i>}
                      >
                        <h3 className="vertical-timeline-element-title">{`${season} ${prev.year} Schedule`}</h3>
                        <SemesterDropZone
                          season={season}
                          year={prev.year}
                          cards={seasonData.map((title) => ({
                            title,
                            category: "Classes",
                            color: '#f5365c',
                            isDefault: true
                          }))}
                          onDrop={handleDrop} // Enable drops
                          onRemove={removeCard} // Enable removes if needed
                        />
                      </VerticalTimelineElement>
                    );
                  })}
                </div>
              ))}


              { showPreviousData &&
                

                <div className='mt-4' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2></h2>
                
                <Button color="secondary" onClick={() => setShowPreviousData((prev) => !prev)}>
                  <i className="fas fa-eye-slash"></i> Hide Previous Data
                </Button>
              
                </div>

              }


              {/* Map through years, including previous data if visible */}

              {/* {years.map((year) => (
                <div key={year}>
                  <div className='mt-4' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2></h2>
                    {year !== currentYear && (
                      <Button color="danger" onClick={() => deleteYear(year)}>
                        <i className="fas fa-trash"></i> Delete {year}
                      </Button>
                    )}
                  </div>
                  {["Spring", "Summer", "Fall", "Winter"].map((season) => (
                    <VerticalTimelineElement
                      key={`${season}-${year}`}
                      className="vertical-timeline-element--work"
                      dateClassName="text-xl font-weight-bold text-white pt-2 opacity-10 d-none d-xl-block d-md-none pl-2 pr-2"
                      date={season === "Winter" ? `Winter ${year}/${year + 1}` : `${season} ${year}`}
                      iconStyle={{ background: timelineIcons[season].color, color: '#fff', display: "flex", alignItems: "center", justifyContent: "center" }}
                      icon={<i className={timelineIcons[season].icon + " text-xl"}></i>}
                    >
                      <h3 className="vertical-timeline-element-title">{`${season === "Winter" ? `Winter ${year}/${year + 1}` : `${season} ${year}`} Schedule`}</h3>
                      <SemesterDropZone
                        season={season}
                        year={year}
                        cards={semesterData[year]?.[season] || []}
                        onDrop={year < currentYear ? null : handleDrop} // Disable onDrop for previous years
                        onRemove={year < currentYear ? null : removeCard} // Disable onRemove for previous years
                      />
                    </VerticalTimelineElement>
                  ))}
                </div>
              ))} */}

              

              {/* <VerticalTimelineElement
                iconStyle={{ cursor:"pointer", background: '#5e72e4', color: '#fff', display:"flex", alignItems: "center", justifyContent: "center" }}
                icon={<i className="fas fa-plus text-xl w-100 h-100 d-flex align-items-center justify-content-center" onClick={addYear}></i>}
              />
            </VerticalTimeline> */}

          </Col>
        </Row>

        {/* Modal for adding a custom card */}
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Add Custom Card</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="category">Category&nbsp;<span className='text-danger'>*</span></Label>
                <Input
                  type="select"
                  id="category"
                  value={tempData.category}
                  onChange={(e) => handleInputChange('category', e.target.value)}
                >
                  <option value="" disabled>Select a category</option>
                  <option value="Classes">Classes</option>
                  <option value="Extracurriculars">Extracurriculars</option>
                  <option value="Other">Other</option>
                </Input>
                {errors.category && <FormText color="danger">{errors.category}</FormText>}
              </FormGroup>
              <FormGroup>
                <Label for="title">Title <span className='text-danger'>*</span></Label>
                {tempData.category === "Classes" ? (
                  <Input
                    type="select"
                    id="title"
                    value={tempData.title}
                    onChange={(e) => handleInputChange('title', e.target.value)}
                  >
                    <option value="" disabled>Select a class</option>
                    {premedReqs.map((classTitle, index) => {
                      
                      if (classTitle != "Class not a Premed Requirement"){
                        return (
                        <option key={index} value={classTitle}>{classTitle}</option>
                        )
                      }
                    })}
                  </Input>
                ) : (
                  <Input
                    type="text"
                    id="title"
                    value={tempData.title}
                    onChange={(e) => handleInputChange('title', e.target.value)}
                  />
                )}
                {errors.title && <FormText color="danger">{errors.title}</FormText>}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={addCustomCard}>Save</Button>
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={showYearModal} toggle={toggleYearModal}>
          <ModalHeader toggle={toggleYearModal}>Select a Previous Year</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="inputYear">Year</Label>
                <Input
                  type="select"
                  id="inputYear"
                  value={inputYear}
                  onChange={(e) => setInputYear(e.target.value)}
                >
                  <option value="">Select a year</option>
                  {previousYearOptions.map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={confirmAddPreviousYear}>Add Year</Button>
            <Button color="secondary" onClick={toggleYearModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

      </Container>
    </DndProvider>
  );
};

const mapStateToProps = (state) => ({
    userDetails: state.loginAuth.userDetails,
    fetchTimelineSchedule: state.fetchTimelineSchedule,
    createTimelineSchedule : state.createTimelineSchedule,
    fetchCourses : state.fetchCourses,
});

export default connect(mapStateToProps)(Timeline);
