import { 
    FETCH_APPHOURUSED_LOGS_REQUEST,
    FETCH_APPHOURUSED_LOGS_SUCCESS,
    FETCH_APPHOURUSED_LOGS_FAILURE,

    FETCH_APP_LOG_REQUEST,
    FETCH_APP_LOG_SUCCESS,
    FETCH_APP_LOG_FAILURE,

    FETCH_PREMIUMHOUR_LOGS_REQUEST,
    FETCH_PREMIUMHOUR_LOGS_SUCCESS,
    FETCH_PREMIUMHOUR_LOGS_FAILURE,

    SEND_EMAIL_APPHOURUSED_REQUEST,
    SEND_EMAIL_APPHOURUSED_SUCCESS,
    SEND_EMAIL_APPHOURUSED_FAILURE
   
 } from "./LogsTypes";


const initialAppHourUsedLogsState = {
    logs : [],
    loading: false,

}

export const fetchAppHourUsedLogsReducer = (state = initialAppHourUsedLogsState, action) => {
    switch (action.type) {
        case FETCH_APPHOURUSED_LOGS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_APPHOURUSED_LOGS_SUCCESS:
            return {
                loading: false,
                logs: action.payload
            }
        case FETCH_APPHOURUSED_LOGS_FAILURE:
            return {
                loading: false,
                logs: [],
                error: action.payload
            }
        default: return state;
    }
}

const initialFetchAppLogState = {
    log : [],
    loading: false,

}

export const fetchAppLogReducer = (state = initialFetchAppLogState, action) => {
    switch (action.type) {
        case FETCH_APP_LOG_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_APP_LOG_SUCCESS:
            return {
                loading: false,
                log: action.payload
            }
        case FETCH_APP_LOG_FAILURE:
            return {
                loading: false,
                log: [],
                error: action.payload
            }
        default: return state;
    }
}

const initialPremiumHourLogsState = {
    logs : [],
    loading: false,

}

export const fetchPremiumHourLogsReducer = (state = initialPremiumHourLogsState, action) => {
    switch (action.type) {
        case FETCH_PREMIUMHOUR_LOGS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_PREMIUMHOUR_LOGS_SUCCESS:
            return {
                loading: false,
                logs: action.payload
            }
        case FETCH_PREMIUMHOUR_LOGS_FAILURE:
            return {
                loading: false,
                logs: [],
                error: action.payload
            }
        default: return state;
    }
}

 const initialSendEmailApplicationHourUsedState = {
    res : [],
    loading: false,

}

export const sendEmailApplicationHourUsedReducer = (state = initialSendEmailApplicationHourUsedState, action) => {
    switch (action.type) {
        case SEND_EMAIL_APPHOURUSED_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SEND_EMAIL_APPHOURUSED_SUCCESS:
            return {
                loading: false,
                res: action.payload
            }
        case SEND_EMAIL_APPHOURUSED_FAILURE:
            return {
                loading: false,
                res: [],
                error: action.payload
            }
        default: return state;
    }
}