import {
  FETCH_AVAILABLE_SCHEDULE_FAILURE,
  FETCH_AVAILABLE_SCHEDULE_REQUEST,
  FETCH_AVAILABLE_SCHEDULE_RESET,
  FETCH_AVAILABLE_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAILURE,
  FETCH_SCHEDULE_REQUEST,
  FETCH_SCHEDULE_RESET,
  FETCH_SCHEDULE_SUCCESS,
  CREATE_REQUEST_TUTOR_FAILURE,
  CREATE_REQUEST_TUTOR_REQUEST,
  CREATE_REQUEST_TUTOR_RESET,
  CREATE_REQUEST_TUTOR_SUCCESS,
  FETCH_REQUEST_TUTOR_FAILURE,
  FETCH_REQUEST_TUTOR_REQUEST,
  FETCH_REQUEST_TUTOR_RESET,
  FETCH_REQUEST_TUTOR_SUCCESS,
  FETCH_STUDENT_SCHEDULE_FAILURE,
  FETCH_STUDENT_SCHEDULE_REQUEST,
  FETCH_STUDENT_SCHEDULE_RESET,
  FETCH_STUDENT_SCHEDULE_SUCCESS,
  FETCH_REQUEST_TUTOR_HISTORY_FAILURE,
  FETCH_REQUEST_TUTOR_HISTORY_REQUEST,
  FETCH_REQUEST_TUTOR_HISTORY_RESET,
  FETCH_REQUEST_TUTOR_HISTORY_SUCCESS,
  CANCEL_REQUEST_TUTOR_FAILURE,
  CANCEL_REQUEST_TUTOR_REQUEST,
  CANCEL_REQUEST_TUTOR_RESET,
  CANCEL_REQUEST_TUTOR_SUCCESS,
  FETCH_COACHES_FAILURE,
  FETCH_COACHES_REQUEST,
  FETCH_COACHES_RESET,
  FETCH_COACHES_SUCCESS,
  CREATE_REQUEST_SCHEDTUTOR_FAILURE,
  CREATE_REQUEST_SCHEDTUTOR_REQUEST,
  CREATE_REQUEST_SCHEDTUTOR_RESET,
  CREATE_REQUEST_SCHEDTUTOR_SUCCESS,
} from "./ScheduleTutorType";

const initialFetchAvailableScheduleState = {
  fetchavailableschedule: [],
  loading: false,
};

export const fetchAvailableScheduleReducer = (
  state = initialFetchAvailableScheduleState,
  action
) => {
  switch (action.type) {
    case FETCH_AVAILABLE_SCHEDULE_RESET:
      return {
        loading: false,
        fetchavailableschedule: [],
      };
    case FETCH_AVAILABLE_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AVAILABLE_SCHEDULE_SUCCESS:
      return {
        loading: false,
        fetchavailableschedule: [action.payload],
      };
    case FETCH_AVAILABLE_SCHEDULE_FAILURE:
      return {
        loading: false,
        fetchavailableschedule: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialFetchScheduleState = {
    schedule : [],
    loading: false,
}

export const fetchScheduleReducer = (state = initialFetchScheduleState, action) => {
    switch (action.type) {
        case FETCH_SCHEDULE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_SCHEDULE_SUCCESS:
            return {
                loading: false,
                schedule: action.payload
            }
        case FETCH_SCHEDULE_FAILURE:
            return {
                loading: false,
                schedule: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialCreateRequestTutorState = {
  createrequesttutor: [],
  loading: false,
};

export const createRequestTutorReducer = (
  state = initialCreateRequestTutorState,
  action
) => {
  switch (action.type) {
    case CREATE_REQUEST_TUTOR_RESET:
      return {
        loading: false,
        createrequesttutor: [],
      };
    case CREATE_REQUEST_TUTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_REQUEST_TUTOR_SUCCESS:
      return {
        loading: false,
        createrequesttutor: [action.payload],
      };
    case CREATE_REQUEST_TUTOR_FAILURE:
      return {
        loading: false,
        createrequesttutor: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialFetchRequestTutorState = {
  fetchrequesttutor: [],
  loading: false,
};

export const fetchRequestTutorReducer = (
  state = initialFetchRequestTutorState,
  action
) => {
  switch (action.type) {
    case FETCH_REQUEST_TUTOR_RESET:
      return {
        loading: false,
        fetchrequesttutor: [],
      };
    case FETCH_REQUEST_TUTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REQUEST_TUTOR_SUCCESS:
      return {
        loading: false,
        fetchrequesttutor: [action.payload],
      };
    case FETCH_REQUEST_TUTOR_FAILURE:
      return {
        loading: false,
        fetchrequesttutor: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialFetchStudentScheduleState = {
  fetchstudentschedule: [],
  loading: false,
};

export const fetchStudentScheduleReducer = (
  state = initialFetchStudentScheduleState,
  action
) => {
  switch (action.type) {
    case FETCH_STUDENT_SCHEDULE_RESET:
      return {
        loading: false,
        fetchstudentschedule: [],
      };
    case FETCH_STUDENT_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_STUDENT_SCHEDULE_SUCCESS:
      return {
        loading: false,
        fetchstudentschedule: [action.payload],
      };
    case FETCH_STUDENT_SCHEDULE_FAILURE:
      return {
        loading: false,
        fetchstudentschedule: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialCancelRequestTutorState = {
  cancelrequesttutor: [],
  loading: false,
};

export const cancelRequestTutorReducer = (
  state = initialCancelRequestTutorState,
  action
) => {
  switch (action.type) {
    case CANCEL_REQUEST_TUTOR_RESET:
      return {
        loading: false,
        cancelrequesttutor: [],
      };
    case CANCEL_REQUEST_TUTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CANCEL_REQUEST_TUTOR_SUCCESS:
      return {
        loading: false,
        cancelrequesttutor: [action.payload],
      };
    case CANCEL_REQUEST_TUTOR_FAILURE:
      return {
        loading: false,
        cancelrequesttutor: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialFetchRequestTutorHistoryState = {
  fetchrequesttutorhistory: [],
  loading: false,
};

export const fetchRequestTutorHistoryReducer = (
  state = initialFetchRequestTutorHistoryState,
  action
) => {
  switch (action.type) {
    case FETCH_REQUEST_TUTOR_HISTORY_RESET:
      return {
        loading: false,
        fetchrequesttutorhistory: [],
      };
    case FETCH_REQUEST_TUTOR_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REQUEST_TUTOR_HISTORY_SUCCESS:
      return {
        loading: false,
        fetchrequesttutorhistory: [action.payload],
      };
    case FETCH_REQUEST_TUTOR_HISTORY_FAILURE:
      return {
        loading: false,
        fetchrequesttutorhistory: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialFetchCoachesState = {
  fetchcoaches: [],
  loading: false,
};

export const fetchCoachesReducer = (
  state = initialFetchCoachesState,
  action
) => {
  switch (action.type) {
    case FETCH_COACHES_RESET:
      return {
        loading: false,
        fetchcoaches: [],
      };
    case FETCH_COACHES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COACHES_SUCCESS:
      return {
        loading: false,
        fetchcoaches: [action.payload],
      };
    case FETCH_COACHES_FAILURE:
      return {
        loading: false,
        fetchcoaches: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialCreateRequestSchedTutorState = {
  createrequestschedtutor: [],
  loading: false,
};

export const createRequestSchedTutorReducer = (
  state = initialCreateRequestSchedTutorState,
  action
) => {
  switch (action.type) {
    case CREATE_REQUEST_SCHEDTUTOR_RESET:
      return {
        loading: false,
        createrequestschedtutor: [],
      };
    case CREATE_REQUEST_SCHEDTUTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_REQUEST_SCHEDTUTOR_SUCCESS:
      return {
        loading: false,
        createrequestschedtutor: [action.payload],
      };
    case CREATE_REQUEST_SCHEDTUTOR_FAILURE:
      return {
        loading: false,
        createrequestschedtutor: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
