
import React, { useState, useEffect } from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Modal, 
    ModalBody,
    ModalFooter,
    Badge
  } from "reactstrap";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import BuyTpcCourseForm from 'components/Payments/BuyTpcCourseForm';

// const stripePromise = loadStripe('pk_test_51Jc7T3LrYzlL9Yv87MMJ6RYPDrp3btJth6fbaZJrykBY0ePHxiMIs9jdohnTcovMW75tepSVyTx1qqdHfW5C6G2n00Qa2b7ZuR');
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const BuyTpcCourseLessonModal = (props) => {

    let modalBuyTutoringHours = props.modalBuyTpcCourseLessonModal;
    let toggleBuyTutoringHours = props.toggleBuyTpcCourseLessonModal;

    const addZeroes = num => num.toLocaleString("en",{minimumFractionDigits: 2})

    //Convert to string
    const strTypePrice = addZeroes(Number(props.course.coursePrice))

    const options = {
        mode: 'payment',
        amount: 1099,
        currency: 'usd',
        // Fully customizable with appearance API. 
        appearance: {/*...*/}
        
    };

    return (
    <div>
        
            <Modal className="modal-dialog-centered modal-xl"  isOpen={modalBuyTutoringHours}  toggle={toggleBuyTutoringHours}>
                
                <ModalBody className='modal-body p-0'>
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Buy Lesson</h3>
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                            <Row>
                                {
                                    props.course !== "undefined"  ?
                                    <>
                                        <Col lg="6" className="align-items-center">
                                            <Card className="bg-secondary shadow border-0"  >
                                                {
                                                     props.course.courseThumbnail != "" ?
                                                    <img
                                                        alt="Course Thumbnail"
                                                        src={`https://storage.googleapis.com/tpcdashboard.appspot.com/img-tpc-courses/` + props.course.courseThumbnail+``}
                                                    />

                                                    : <img
                                                        alt="Course Thumbnail"
                                                        src={require("assets/img/brand/no-image.jpg")}
                                                    />
                                                }
                                                
                                                
                                                <CardBody>
                                                    {/* <CardTitle tag="h5">Pay {props.course.courseTitle }</CardTitle> */}
                                                    <h4>{props.course.courseTitle }</h4>
                                                    <h1>${strTypePrice }</h1>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="6">
                                            <p></p>
                                            <Elements stripe={stripePromise} options={options}>
                                                <BuyTpcCourseForm course={props.course}></BuyTpcCourseForm>
                                            </Elements>    
                                        </Col>
                                    </>
                                    : ""

                                }
                               
                            </Row>
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                   
                </ModalFooter>
                
            </Modal>
        </div>
  )
}

export default BuyTpcCourseLessonModal