import { 
    FETCH_APPHOURUSED_LOGS_REQUEST,
    FETCH_APPHOURUSED_LOGS_SUCCESS,
    FETCH_APPHOURUSED_LOGS_FAILURE,
    FETCH_APP_LOG_REQUEST,
    FETCH_APP_LOG_SUCCESS,
    FETCH_APP_LOG_FAILURE,
    FETCH_PREMIUMHOUR_LOGS_REQUEST,
    FETCH_PREMIUMHOUR_LOGS_SUCCESS,
    FETCH_PREMIUMHOUR_LOGS_FAILURE,
    SEND_EMAIL_APPHOURUSED_REQUEST,
    SEND_EMAIL_APPHOURUSED_SUCCESS,
    SEND_EMAIL_APPHOURUSED_FAILURE
   

   
 } from "./LogsTypes";
import axios from "axios";

const fetchAppHourUsedLogsRequest = () => {
    return {
        type: FETCH_APPHOURUSED_LOGS_REQUEST,
    }
}

const fetchAppHourUsedLogsSuccess = success => {
    return {
        type: FETCH_APPHOURUSED_LOGS_SUCCESS,
        payload: success
    }
}

const fetchAppHourUsedLogsFailure = error => {
    return {
        type: FETCH_APPHOURUSED_LOGS_FAILURE,
        payload : error
    }
}

export const fetchAppHourUsedLogs = (data) => {
    return (dispatch) => {
        dispatch(fetchAppHourUsedLogsRequest());
        axios.post('/fetchAppHourUsedLogs' , data)
        .then(response => {
            const logs = response.data
            dispatch(fetchAppHourUsedLogsSuccess(logs)) 
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchAppHourUsedLogsFailure(errorMsg))
        })  
    }
}


const fetchAppLogRequest = () => {
    return {
        type: FETCH_APP_LOG_REQUEST,
    }
}

const fetchAppLogSuccess = success => {
    return {
        type: FETCH_APP_LOG_SUCCESS,
        payload: success
    }
}

const fetchAppLogFailure = error => {
    return {
        type: FETCH_APP_LOG_FAILURE,
        payload : error
    }
}

export const fetchAppLog = (data) => {
    return (dispatch) => {
        dispatch(fetchAppLogRequest());
        axios.post('/fetchAppLog' , data)
        .then(response => {
            const log = response.data
            dispatch(fetchAppLogSuccess(log)) 
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchAppLogFailure(errorMsg))
        })  
    }
}


const fetchPremiumHourLogsRequest = () => {
    return {
        type: FETCH_PREMIUMHOUR_LOGS_REQUEST,
    }
}

const fetchPremiumHourLogsSuccess = success => {
    return {
        type: FETCH_PREMIUMHOUR_LOGS_SUCCESS,
        payload: success
    }
}

const fetchPremiumHourLogsFailure = error => {
    return {
        type: FETCH_PREMIUMHOUR_LOGS_FAILURE,
        payload : error
    }
}

export const fetchPremiumHourLogs = (data) => {
    return (dispatch) => {
        dispatch(fetchPremiumHourLogsRequest());
        axios.post('/fetchPremiumHourLogs' , data)
        .then(response => {
            const logs = response.data
            dispatch(fetchPremiumHourLogsSuccess(logs)) 
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchPremiumHourLogsFailure(errorMsg))
        })  
    }
}



const sendEmailApplicationHourUsedRequest = () => {
    return {
        type: SEND_EMAIL_APPHOURUSED_REQUEST,
    }
}

const sendEmailApplicationHourUsedSuccess = success => {
    return {
        type: SEND_EMAIL_APPHOURUSED_SUCCESS,
        payload: success
    }
}

const sendEmailApplicationHourUsedFailure = error => {
    return {
        type: SEND_EMAIL_APPHOURUSED_FAILURE,
        payload : error
    }
}

export const sendEmailApplicationHourUsed = (data) => {
    return (dispatch) => {
        dispatch(sendEmailApplicationHourUsedRequest());
        axios.post('/sendEmailApplicationHourUsed' , data)
        .then(response => {
            const res = response.data
            dispatch(sendEmailApplicationHourUsedSuccess(res)) 
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(sendEmailApplicationHourUsedFailure(errorMsg))
        })  
    }
}
