import { 
    CREATE_PROFILE_PICTURE_FAILURE,
    CREATE_PROFILE_PICTURE_REQUEST,
    CREATE_PROFILE_PICTURE_RESET,
    CREATE_PROFILE_PICTURE_SUCCESS,
    UPDATE_USER_PROFILE_FAILURE,
    UPDATE_USER_PROFILE_REQUEST,
    UPDATE_USER_PROFILE_RESET,
    UPDATE_USER_PROFILE_SUCCESS,
    FETCH_UNIVERSITIES_REQUEST,
    FETCH_UNIVERSITIES_SUCCESS,
    FETCH_UNIVERSITIES_FAILURE,

    UPDATE_USER_PHONE_NUMBER_FAILURE,
    UPDATE_USER_PHONE_NUMBER_REQUEST,
    UPDATE_USER_PHONE_NUMBER_RESET,
    UPDATE_USER_PHONE_NUMBER_SUCCESS,
    UPDATE_USER_COMMUNITY_SETTINGS_FAILURE,
    UPDATE_USER_COMMUNITY_SETTINGS_REQUEST,
    UPDATE_USER_COMMUNITY_SETTINGS_RESET,
    UPDATE_USER_COMMUNITY_SETTINGS_SUCCESS,
     GET_USER_DETAILS_REQUEST,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILURE,
} from "./UsersTypes"


import axios from "axios"

export const createProfilePictureReset = () => {
    return {
        type: CREATE_PROFILE_PICTURE_RESET,
    }
}

const createProfilePictureRequest = () => {
    return {
        type: CREATE_PROFILE_PICTURE_REQUEST,
    }
}

const createProfilePictureSuccess = success => {
    return {
        type: CREATE_PROFILE_PICTURE_SUCCESS,
        payload: success
    }
}

const createProfilePictureFailure = error => {
    return {
        type: CREATE_PROFILE_PICTURE_FAILURE,
        payload : error
    }
}

export const createProfilePicture = (data) => {

    return (dispatch) => {

        dispatch(createProfilePictureRequest());
        
        
        
        axios.post('/storeProfilePicture', data,{headers: {
            'Content-Type': 'multipart/form-data'
            
          }})
        .then(response => {
            const profilepicture = response.data
            dispatch(createProfilePictureSuccess(profilepicture))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createProfilePictureFailure(errorMsg))
        })
        
    }

}


export const updateUserProfileReset = () => {
    return {
        type: UPDATE_USER_PROFILE_RESET,
    }
}

const updateUserProfileRequest = () => {
    return {
        type: UPDATE_USER_PROFILE_REQUEST,
    }
}

const updateUserProfileSuccess = success => {
    return {
        type: UPDATE_USER_PROFILE_SUCCESS,
        payload: success
    }
}

const updateUserProfileFailure = error => {
    return {
        type: UPDATE_USER_PROFILE_FAILURE,
        payload : error
    }
}

export const updateUserProfile = (data) => {

    return (dispatch) => {

        dispatch(updateUserProfileRequest());
        
        
        
        axios.post('/updateUserProfile', data)
        .then(response => {
            const userprofile = response.data
            dispatch(updateUserProfileSuccess(userprofile))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateUserProfileFailure(errorMsg))
        })
        
    }

}

export const updateUserCommunitySettingsReset = () => {
    return {
        type: UPDATE_USER_COMMUNITY_SETTINGS_RESET,
    }
}

const updateUserCommunitySettingsRequest = () => {
    return {
        type: UPDATE_USER_COMMUNITY_SETTINGS_REQUEST,
    }
}

const updateUserCommunitySettingsSuccess = success => {
    return {
        type: UPDATE_USER_COMMUNITY_SETTINGS_SUCCESS,
        payload: success
    }
}

const updateUserCommunitySettingsFailure = error => {
    return {
        type: UPDATE_USER_COMMUNITY_SETTINGS_FAILURE,
        payload : error
    }
}

export const updateUserCommunitySettings = (data) => {

    return (dispatch) => {

        dispatch(updateUserCommunitySettingsRequest());
        
        axios.post('/updateUserCommunitySettings', data)
        .then(response => {
            const updateusercommunitysettings = response.data
            dispatch(updateUserCommunitySettingsSuccess(updateusercommunitysettings))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateUserCommunitySettingsFailure(errorMsg))
        })
        
    }

}




const fetchUniversitiesRequest = () => {
    return {
        type: FETCH_UNIVERSITIES_REQUEST,
    }
}

const fetchUniversitiesSuccess = success => {
    return {
        type: FETCH_UNIVERSITIES_SUCCESS,
        payload: success
    }
}

const fetchUniversitiesFailure = error => {
    return {
        type: FETCH_UNIVERSITIES_FAILURE,
        payload : error
    }
}

export const fetchUniversities = (data) => {
    
    return (dispatch) => {
        
        dispatch(fetchUniversitiesRequest());
        
        
        axios.post('/getUniversities' , data)
        .then(response => {
            const universities = response.data
            dispatch(fetchUniversitiesSuccess(universities))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchUniversitiesFailure(errorMsg))
        })
        
    }

}


export const updateUserPhoneNumberReset = () => {
    return {
        type: UPDATE_USER_PHONE_NUMBER_RESET,
    }
}

const updateUserPhoneNumberRequest = () => {
    return {
        type: UPDATE_USER_PHONE_NUMBER_REQUEST,
    }
}

const updateUserPhoneNumberSuccess = success => {
    return {
        type: UPDATE_USER_PHONE_NUMBER_SUCCESS,
        payload: success
    }
}

const updateUserPhoneNumberFailure = error => {
    return {
        type: UPDATE_USER_PHONE_NUMBER_FAILURE,
        payload : error
    }
}

export const updateUserPhoneNumber = (data) => {

    return (dispatch) => {

        dispatch(updateUserPhoneNumberRequest());
        
        
        
        axios.post('/updateUserPhoneNumber', data)
        .then(response => {
            const UserPhoneNumber = response.data
            dispatch(updateUserPhoneNumberSuccess(UserPhoneNumber))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateUserPhoneNumberFailure(errorMsg))
        })
        
    }

}
const getUserDetailsRequest = () => {
  return {
    type: GET_USER_DETAILS_REQUEST,
  };
};

const getUserDetailsSuccess = (success) => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload: success,
  };
};

const getUserDetailsFailure = (error) => {
  return {
    type: GET_USER_DETAILS_FAILURE,
    payload: error,
  };
};

export const getUserDetails = (data) => {
  return (dispatch) => {
    dispatch(getUserDetailsRequest());

    axios
      .post("/getUserByHandle", data)
      .then((response) => {
        const userDetails = response.data;
        dispatch(getUserDetailsSuccess(userDetails));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(getUserDetailsFailure(errorMsg));
      });
  };
};
