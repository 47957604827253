import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { connect, useDispatch } from "react-redux";
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const Paginate = ({
	itemPerPage,
	totalItems,
	currentPage,
	paginate,
	previousPage,
	nextPage,
}) => {
	const pageNumbers = [];

	for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
		pageNumbers.push(i);
	}
	return (
         <Pagination className="align-center">
            <PaginationItem>
                <PaginationLink previous  onClick={previousPage} className="page-number" />
            </PaginationItem>
            {
                pageNumbers.map((number) => (
                    <PaginationItem key={number}>
                        <PaginationLink onClick={() => paginate(number)}>
                        {number}
                        </PaginationLink>
                    </PaginationItem>
				))
            }
            <PaginationItem>
                <PaginationLink next onClick={nextPage} />
            </PaginationItem>
         </Pagination> 
	);
};

const mapStateToProps = (state) => {
  return {
   
  };
};

export default connect(mapStateToProps)(Paginate);