import WorkActivities from "views/pages/WorkActivities";

export const premedReqs = [
    "Class not a Premed Requirement",
    "Biology 1",
    "Biology 2",
    "Biology Lab 1",
    "Biology Lab 2",
    "Chemistry 1",
    "Chemistry 2",
    "Chemistry Lab 1",
    "Chemistry Lab 2",
    "Physics 1",
    "Physics 2",
    "Physics Lab 1",
    "Physics Lab 2",
    "Organic Chemistry 1",
    "Organic Chemistry 2",
    "Organic Chemistry Lab 1",
    "English 1",
    "English 2",
    "Social Science 1",
    "Social Science 2",
    "Calculus",
    "Statistics 1",
    "Biochemistry 1",
];


export const testMcatScore = [
    { value: "Official MCAT Score", label: "Official MCAT Score" , color: '#5e72e4' },
    { value: "AAMC MCAT Official Prep Practice Exam One", label: "AAMC MCAT Official Prep Practice Exam One" , color: '#8898aa' },
    { value: "AAMC MCAT Official Prep Practice Exam Two", label: "AAMC MCAT Official Prep Practice Exam Two" , color: '#8898aa' },
    { value: "AAMC MCAT Official Prep Practice Exam Three", label: "AAMC MCAT Official Prep Practice Exam Three" , color: '#8898aa' },
    { value: "AAMC MCAT Official Prep Practice Exam Four", label: "AAMC MCAT Official Prep Practice Exam Four" , color: '#8898aa' },
    { value: "MCAT Official Prep Free Practice Exam (Scored)", label: "MCAT Official Prep Free Practice Exam (Scored)" , color: '#8898aa' },
    { value: "MCAT Official Prep Free Practice Exam (Unscored)", label: "MCAT Official Prep Free Practice Exam (Unscored)" , color: '#8898aa' },
    { value: "Other", label: "Other" , color: '#8898aa' }
];


export const schoolYear = [
    { "value": "highschool", "label": "Highschool" },
    { "value": "freshman", "label": "Freshman" },
    { "value": "sophmore", "label": "Sophmore" },
    { "value": "junior", "label": "Junior" },
    { "value": "senior", "label": "Senior" },
    { "value": "postbac", "label": "Postbac" },
    { "value": "masters", "label": "Masters" }
]


export const secondaryEssayStatus = [
    { value: "pre-submission", label: "pre-submission" , color: '#5e72e4' },
    { value: "working on secondary", label: "working on secondary", color: '#ffd600' },
    { value: "submitted", label: "submitted", color: '#2dce89' },
    { value: "pending interview", label: "pending interview", color: '#fb6340' },
    { value: "interview received", label: "interview received", color: '#5603ad' },
    { value: "interview scheduled", label: "interview scheduled", color: '#11cdef' },
    { value: "interview completed", label: "interview completed" , color: '#8965e0'},
    { value: "waitlisted", label: "waitlisted", color: '#8898aa' },
    { value: "rejected", label: "rejected", color: '#f5365c' },
    { value: "accepted", label: "accepted" , color: '#11cdef'}
];




export const optionCountries = [
{ value : "US",label:"United States"},
{ value : "AF",label:"Afghanistan"},
{ value : "AX",label:"Åland Islands"},
{ value : "AL",label:"Albania"},
{ value : "DZ",label:"Algeria"},
{ value : "AS",label:"American Samoa"},
{ value : "AD",label:"Andorra"},
{ value : "AO",label:"Angola"},
{ value : "AI",label:"Anguilla"},
{ value : "AQ",label:"Antarctica"},
{ value : "AG",label:"Antigua and Barbuda"},
{ value : "AR",label:"Argentina"},
{ value : "AM",label:"Armenia"},
{ value : "AW",label:"Aruba"},
{ value : "AU",label:"Australia"},
{ value : "AT",label:"Austria"},
{ value : "AZ",label:"Azerbaijan"},
{ value : "BS",label:"Bahamas"},
{ value : "BH",label:"Bahrain"},
{ value : "BD",label:"Bangladesh"},
{ value : "BB",label:"Barbados"},
{ value : "BY",label:"Belarus"},
{ value : "BE",label:"Belgium"},
{ value : "BZ",label:"Belize"},
{ value : "BJ",label:"Benin"},
{ value : "BM",label:"Bermuda"},
{ value : "BT",label:"Bhutan"},
{ value : "BO",label:"Bolivia"},
{ value : "BQ",label:"Bonaire, Sint Eustatius and Saba"},
{ value : "BA",label:"Bosnia and Herzegovina"},
{ value : "BW",label:"Botswana"},
{ value : "BV",label:"Bouvet Island"},
{ value : "BR",label:"Brazil"},
{ value : "IO",label:"British Indian Ocean Territory"},
{ value : "BN",label:"Brunei Darussalam"},
{ value : "BG",label:"Bulgaria"},
{ value : "BF",label:"Burkina Faso"},
{ value : "BI",label:"Burundi"},
{ value : "KH",label:"Cambodia"},
{ value : "CM",label:"Cameroon"},
{ value : "CA",label:"Canada"},
{ value : "CV",label:"Cape Verde"},
{ value : "KY",label:"Cayman Islands"},
{ value : "CF",label:"Central African Republic"},
{ value : "TD",label:"Chad"},
{ value : "CL",label:"Chile"},
{ value : "CN",label:"China"},
{ value : "CX",label:"Christmas Island"},
{ value : "CC",label:"Cocos (Keeling) Islands"},
{ value : "CO",label:"Colombia"},
{ value : "KM",label:"Comoros"},
{ value : "CG",label:"Congo, Republic of the (Brazzaville)"},
{ value : "CD",label:"Congo, the Democratic Republic of the (Kinshasa)"},
{ value : "CK",label:"Cook Islands"},
{ value : "CR",label:"Costa Rica"},
{ value : "CI",label:"Côte d'Ivoire, Republic of"},
{ value : "HR",label:"Croatia"},
{ value : "CU",label:"Cuba"},
{ value : "CW",label:"Curaçao"},
{ value : "CY",label:"Cyprus"},
{ value : "CZ",label:"Czech Republic"},
{ value : "DK",label:"Denmark"},
{ value : "DJ",label:"Djibouti"},
{ value : "DM",label:"Dominica"},
{ value : "DO",label:"Dominican Republic"},
{ value : "EC",label:"Ecuador"},
{ value : "EG",label:"Egypt"},
{ value : "SV",label:"El Salvador"},
{ value : "GQ",label:"Equatorial Guinea"},
{ value : "ER",label:"Eritrea"},
{ value : "EE",label:"Estonia"},
{ value : "ET",label:"Ethiopia"},
{ value : "FK",label:"Falkland Islands (Islas Malvinas)"},
{ value : "FO",label:"Faroe Islands"},
{ value : "FJ",label:"Fiji"},
{ value : "FI",label:"Finland"},
{ value : "FR",label:"France"},
{ value : "GF",label:"French Guiana"},
{ value : "PF",label:"French Polynesia"},
{ value : "TF",label:"French Southern and Antarctic Lands"},
{ value : "GA",label:"Gabon"},
{ value : "GM",label:"Gambia, The"},
{ value : "GE",label:"Georgia"},
{ value : "DE",label:"Germany"},
{ value : "GH",label:"Ghana"},
{ value : "GI",label:"Gibraltar"},
{ value : "GR",label:"Greece"},
{ value : "GL",label:"Greenland"},
{ value : "GD",label:"Grenada"},
{ value : "GP",label:"Guadeloupe"},
{ value : "GU",label:"Guam"},
{ value : "GT",label:"Guatemala"},
{ value : "GG",label:"Guernsey"},
{ value : "GN",label:"Guinea"},
{ value : "GW",label:"Guinea-Bissau"},
{ value : "GY",label:"Guyana"},
{ value : "HT",label:"Haiti"},
{ value : "HM",label:"Heard Island and McDonald Islands"},
{ value : "VA",label:"Holy See (Vatican City)"},
{ value : "HN",label:"Honduras"},
{ value : "HK",label:"Hong Kong"},
{ value : "HU",label:"Hungary"},
{ value : "IS",label:"Iceland"},
{ value : "IN",label:"India"},
{ value : "ID",label:"Indonesia"},
{ value : "IR",label:"Iran, Islamic Republic of"},
{ value : "IQ",label:"Iraq"},
{ value : "IE",label:"Ireland"},
{ value : "IM",label:"Isle of Man"},
{ value : "IL",label:"Israel"},
{ value : "IT",label:"Italy"},
{ value : "JM",label:"Jamaica"},
{ value : "JP",label:"Japan"},
{ value : "JE",label:"Jersey"},
{ value : "JO",label:"Jordan"},
{ value : "KZ",label:"Kazakhstan"},
{ value : "KE",label:"Kenya"},
{ value : "KI",label:"Kiribati"},
{ value : "KP",label:"Korea, Democratic People's Republic of"},
{ value : "KR",label:"Korea, Republic of"},
{ value : "XK",label:"Kosovo"},
{ value : "KW",label:"Kuwait"},
{ value : "KG",label:"Kyrgyzstan"},
{ value : "LA",label:"Laos"},
{ value : "LV",label:"Latvia"},
{ value : "LB",label:"Lebanon"},
{ value : "LS",label:"Lesotho"},
{ value : "LR",label:"Liberia"},
{ value : "LY",label:"Libya"},
{ value : "LI",label:"Liechtenstein"},
{ value : "LT",label:"Lithuania"},
{ value : "LU",label:"Luxembourg"},
{ value : "MO",label:"Macao"},
{ value : "MK",label:"Macedonia, Republic of"},
{ value : "MG",label:"Madagascar"},
{ value : "MW",label:"Malawi"},
{ value : "MY",label:"Malaysia"},
{ value : "MV",label:"Maldives"},
{ value : "ML",label:"Mali"},
{ value : "MT",label:"Malta"},
{ value : "MH",label:"Marshall Islands"},
{ value : "MQ",label:"Martinique"},
{ value : "MR",label:"Mauritania"},
{ value : "MU",label:"Mauritius"},
{ value : "YT",label:"Mayotte"},
{ value : "MX",label:"Mexico"},
{ value : "FM",label:"Micronesia, Federated States of"},
{ value : "MD",label:"Moldova"},
{ value : "MC",label:"Monaco"},
{ value : "MN",label:"Mongolia"},
{ value : "ME",label:"Montenegro"},
{ value : "MS",label:"Montserrat"},
{ value : "MA",label:"Morocco"},
{ value : "MZ",label:"Mozambique"},
{ value : "MM",label:"Myanmar"},
{ value : "NA",label:"Namibia"},
{ value : "NR",label:"Nauru"},
{ value : "NP",label:"Nepal"},
{ value : "NL",label:"Netherlands"},
{ value : "NC",label:"New Caledonia"},
{ value : "NZ",label:"New Zealand"},
{ value : "NI",label:"Nicaragua"},
{ value : "NE",label:"Niger"},
{ value : "NG",label:"Nigeria"},
{ value : "NU",label:"Niue"},
{ value : "NF",label:"Norfolk Island"},
{ value : "MP",label:"Northern Mariana Islands"},
{ value : "NO",label:"Norway"},
{ value : "OM",label:"Oman"},
{ value : "PK",label:"Pakistan"},
{ value : "PW",label:"Palau"},
{ value : "PS",label:"Palestine, State of"},
{ value : "PA",label:"Panama"},
{ value : "PG",label:"Papua New Guinea"},
{ value : "PY",label:"Paraguay"},
{ value : "PE",label:"Peru"},
{ value : "PH",label:"Philippines"},
{ value : "PN",label:"Pitcairn"},
{ value : "PL",label:"Poland"},
{ value : "PT",label:"Portugal"},
{ value : "PR",label:"Puerto Rico"},
{ value : "QA",label:"Qatar"},
{ value : "RE",label:"Réunion"},
{ value : "RO",label:"Romania"},
{ value : "RU",label:"Russian Federation"},
{ value : "RW",label:"Rwanda"},
{ value : "BL",label:"Saint Barthélemy"},
{ value : "SH",label:"Saint Helena, Ascension and Tristan da Cunha"},
{ value : "KN",label:"Saint Kitts and Nevis"},
{ value : "LC",label:"Saint Lucia"},
{ value : "MF",label:"Saint Martin"},
{ value : "PM",label:"Saint Pierre and Miquelon"},
{ value : "VC",label:"Saint Vincent and the Grenadines"},
{ value : "WS",label:"Samoa"},
{ value : "SM",label:"San Marino"},
{ value : "ST",label:"Sao Tome and Principe"},
{ value : "SA",label:"Saudi Arabia"},
{ value : "SN",label:"Senegal"},
{ value : "RS",label:"Serbia"},
{ value : "SC",label:"Seychelles"},
{ value : "SL",label:"Sierra Leone"},
{ value : "SG",label:"Singapore"},
{ value : "SX",label:"Sint Maarten (Dutch part)"},
{ value : "SK",label:"Slovakia"},
{ value : "SI",label:"Slovenia"},
{ value : "SB",label:"Solomon Islands"},
{ value : "SO",label:"Somalia"},
{ value : "ZA",label:"South Africa"},
{ value : "GS",label:"South Georgia and South Sandwich Islands"},
{ value : "SS",label:"South Sudan"},
{ value : "ES",label:"Spain"},
{ value : "LK",label:"Sri Lanka"},
{ value : "SD",label:"Sudan"},
{ value : "SR",label:"Suriname"},
{ value : "SZ",label:"Eswatini"},
{ value : "SE",label:"Sweden"},
{ value : "CH",label:"Switzerland"},
{ value : "SY",label:"Syrian Arab Republic"},
{ value : "TW",label:"Taiwan"},
{ value : "TJ",label:"Tajikistan"},
{ value : "TZ",label:"Tanzania, United Republic of"},
{ value : "TH",label:"Thailand"},
{ value : "TL",label:"Timor-Leste"},
{ value : "TG",label:"Togo"},
{ value : "TK",label:"Tokelau"},
{ value : "TO",label:"Tonga"},
{ value : "TT",label:"Trinidad and Tobago"},
{ value : "TN",label:"Tunisia"},
{ value : "TR",label:"Turkey"},
{ value : "TM",label:"Turkmenistan"},
{ value : "TC",label:"Turks and Caicos Islands"},
{ value : "TV",label:"Tuvalu"},
{ value : "UG",label:"Uganda"},
{ value : "UA",label:"Ukraine"},
{ value : "AE",label:"United Arab Emirates"},
{ value : "GB",label:"United Kingdom"},
{ value : "UM",label:"United States Minor Outlying Islands"},
{ value : "UY",label:"Uruguay"},
{ value : "UZ",label:"Uzbekistan"},
{ value : "VU",label:"Vanuatu"},
{ value : "VE",label:"Venezuela, Bolivarian Republic of"},
{ value : "VN",label:"Vietnam"},
{ value : "VG",label:"Virgin Islands, British"},
{ value : "VI",label:"Virgin Islands, U.S."},
{ value : "WF",label:"Wallis and Futuna"},
{ value : "EH",label:"Western Sahara"},
{ value : "YE",label:"Yemen"},
{ value : "ZM",label:"Zambia"},
{ value : "ZW",label:"Zimbabwe"}]



export const terms = [
    { value: "Fall", label: "Fall" },
    { value: "Spring", label: "Spring" },
    { value: "Winter", label: "Winter" },
    { value: "Summer", label: "Summer" }
]

export const grades = [
    { value: "A+", label: "A+" },
    { value: "A", label: "A" },
    { value: "A-", label: "A-" },
    { value: "AB", label: "AB" },
    { value: "B+", label: "B+" },
    { value: "B", label: "B" },
    { value: "B-", label: "B-" },
    { value: "BC", label: "BC" },
    { value: "C+", label: "C+" },
    { value: "C", label: "C" },
    { value: "C-", label: "C-" },
    { value: "CD", label: "CD" },
    { value: "D+", label: "D+" },
    { value: "D", label: "D" },
    { value: "D-", label: "D-" },
    { value: "DE", label: "DE" },
    { value: "F", label: "F" },
    { value: "Pass", label: "Pass" },
    { value: "Fail", label: "Fail" },
    { value: "Transfer", label: "Transfer" },
    { value: "Withdraw", label: "Withdraw" }
]


export const schoolYears = [
    { value: "freshman", label: "Freshman" },
    { value: "sophomore", label: "Sophomore" },
    { value: "junior", label: "Junior" },
    { value: "senior", label: "Senior" },
    { value: "postbac", label: "Postbac" },
    { value: "graduate", label: "Graduate" }
]


export const experienceType = [
    { value: "Artistic Endeavors", label: "Artistic Endeavors" },
    { value: "Community Service/Volunteer - Medical/Clinical", label: "Community Service/Volunteer - Medical/Clinical" },
    { value: "Community Service/Volunteer - Not Medical/Clinical", label: "Community Service/Volunteer - Not Medical/Clinical" },
    { value: "Conferences Attended", label: "Conferences Attended" },
    { value: "Extracurricular Activities", label: "Extracurricular Activities" },
    { value: "Hobbies", label: "Hobbies" },
    { value: "Honors/Awards/Recognitions", label: "Honors/Awards/Recognitions" },
    { value: "Intercollegiate Athletics", label: "Intercollegiate Athletics" },
    { value: "Leadership - Not Listed Elsewhere", label: "Leadership - Not Listed Elsewhere" },
    { value: "Military Service", label: "Military Service" },
    { value: "Paid Employment - Medical/Clinical", label: "Paid Employment - Medical/Clinical" },
    { value: "Paid Employment - Not Medical/Clinical", label: "Paid Employment - Not Medical/Clinical" },
    { value: "Physician Shadowing/Clinical Observation", label: "Physician Shadowing/Clinical Observation" },
    { value: "Presentations/Posters", label: "Presentations/Posters" },
    { value: "Publications", label: "Publications" },
    { value: "Research/La", label: "Research/Lab" },
    { value: "Teaching/Tutoring/Teaching Assistant", label: "Teaching/Tutoring/Teaching Assistant" },
    { value: "Social Justice/Advocacy", label: "Social Justice/Advocacy" },
    { value: "Other", label: "Other" }
  ]


export const workActivitiesType = [
    { value: "Activity", label: "Activity" },
    { value: "Clinical", label: "Clinical" },
    { value: "Shadowing", label: "Shadowing" },
    { value: "Volunteering", label: "Volunteering" }
]


export const monthsList = [
    { "value": "1", "label": "January" },
    { "value": "2", "label": "February" },
    { "value": "3", "label": "March" },
    { "value": "4", "label": "April" },
    { "value": "5", "label": "May" },
    { "value": "6", "label": "June" },
    { "value": "7", "label": "July" },
    { "value": "8", "label": "August" },
    { "value": "9", "label": "September" },
    { "value": "10", "label": "October" },
    { "value": "11", "label": "November" },
    { "value": "12", "label": "December" }
  ]



export const yearsList = () => {
    const year = new Date().getFullYear();
	const yearsArray = Array.from(new Array(100), (v, i) => ({
		value: year - i,
		label: String(year - i), // Convert the value to a string to be used as the label
	}));

	return yearsArray;
}

export const studentsLifetimePlan = [
    "manalibrahim456@gmail.com",
    "yassin.issa08@gmail.com",
    "chidoziedeclan@gmail.com",
    "mayasarkis2018@gmail.com",
    "sarah.rahmoun12@icloud.com",
    "atassir2001@gmail.com",
    "maryamsiddiqui13@yahoo.com",
    "tueminhmary2105@gmail.com",
    "alaskanfiremedic@gmail.com",
    "dmendez23@ucmerced.edu",
    "ktorabzadeh02@icloud.com",
    "jessicaannlobb@gmail.com",
    "senevm1@unlv.nevada.edu",
    "tahirajaved600@gmail.com",
    "nithyashankar.03@gmail.com",
    "nrobertson081@gmail.com",
    "karlee.waugh10@gmail.com",
    "kgomez4208@berkeley.edu",
    "tzanouangeliki@gmail.com",
    "jhajjmahek@gmail.com",
    "jerrymend04@gmail.com",
    "nurkoandrea3@gmail.com",
    "thompsonlydia38@gmail.com",
    "kennyweng8@gmail.com",
    "gianellamv721@hotmail.com",
    "nicolemanelis1@gmail.com",
    "tuazonpred@gmail.com",
    "adn34@cornell.edu",	

    "ringor71@gmail.com"
    
    
    
]