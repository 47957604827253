import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import moment from 'moment-timezone';

import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Input,
    Image,
    Form,
} from "reactstrap";


import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { app, db } from "../../firebase";
import { getDocs, getDoc, setDoc, deleteDoc, addDoc, collection, query, where, onSnapshot, doc, orderBy, limit } from "firebase/firestore";
const LikeUasComment = (props) => {

    const dispatch = useDispatch()
 
  
    const [likeDetails, setLikeDetails] = useState('')
    
    useEffect(() => {
        if (props.item && typeof props.userDetails.handle != "undefined") {
            const q = query(collection(db, "communityUasLikeComment"), where("commentid", "==", props.item.commentDocId), where("userHandle", "==", props.userDetails.handle));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let likeInFo = doc.data()
                    if (likeInFo) {
                        likeInFo['docid'] = doc.id
                    }
                    setLikeDetails(likeInFo)
                });
            });
        
        }
    }, [props])


    //Like the comment
    const likeComment =  (e, data) => {
        if (data.likeid) {
            deleteDoc(doc(db, 'communityUasLikeComment', data.likeid));  
            setLikeDetails('')
        } else {
            const param = {
                userId: props.userDetails.userId,
                userHandle: props.userDetails.handle,
                commentid: data.commentid,
                createdAt:  new Date().toISOString(),
                updatedAt: new Date().toISOString()
            }
            const createdLike =  doc(collection(db, "communityUasLikeComment"));
            setDoc(createdLike, param);
        }
    }

    return (
         
        <>
            {
                likeDetails  ? 
                    <a  onClick={e => likeComment(e,{commentid:props.item.commentDocId, likeid: likeDetails.docid })} className="text-primary mr-3" style={{ fontSize: '12px', cursor: 'pointer'}}>Like </a>
                :   
                <a  onClick={ e => likeComment(e,{commentid:props.item.commentDocId})} className="text-dark mr-3" style={{ fontSize: '12px',cursor: 'pointer'  }}>Like </a> 
                  
            }
          
        </>

    )

}

const mapStateToProps = state  => {
    return {
        userDetails: state.loginAuth.userDetails,
    }  
}

export default connect(mapStateToProps)(LikeUasComment)
