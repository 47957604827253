import React, { useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    CardImg,
    TabContent, TabPane, Nav, NavItem, NavLink, 
} from "reactstrap";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import Header from 'components/Headers/Header';

import {arrayMoveImmutable} from 'array-move';

import AddTpcCourseCategoryModal from 'components/Modal/AddTpcCourseCategoryModal';
import AddTpcCourseCategoryLessonsModal from 'components/Modal/AddTpcCourseCategoryLessonsModal';

import { connect, useDispatch } from 'react-redux';

import { updateTpcCourseCategoryOrder, deleteTpcCourseLessons, deleteTpcCourseCategory, deleteTpcCourseLessonsReset, deleteTpcCourseCategoryReset, fetchTpcCourses } from 'redux/ManageTpcCourses/ManageTpcCoursesActions';
import Swal from 'sweetalert2'

const ManageTpcCoursesLessons = (props) => {

    const [modalAddTpcCourseCategoryModal, setModalAddTpcCourseCategoryModal] = useState(false);
    const [modalAddTpcCourseCategoryLessonsModal, setModalAddTpcCourseCategoryLessonsModal] = useState(false);

    const [editCourseCategory, setEditCourseCategory] = useState(false);
    const [editCourseCategoryLessons, setEditCourseCategoryLessons] = useState(false);
    
    const [categoryId, setCategoryId] = useState("");

    const dispatch = useDispatch()

    const [courseProps, setCourseProps] = useState(props.courseData)

    const toggleAddTpcCourseCategoryModal = () =>{
        
        setModalAddTpcCourseCategoryModal(!modalAddTpcCourseCategoryModal);
        
    } 

    

    const toggleAddTpcCourseCategoryLessonsModal = () =>{
        
        setModalAddTpcCourseCategoryLessonsModal(!modalAddTpcCourseCategoryLessonsModal);
        

        
        // let set = fetchTpcCourses.find(category => category.id == fields.oldCategory);
    } 

    useEffect(() => {
        
        if(props.createTpcCourseCategory.tpccourse.length){
            setItems(props.createTpcCourseCategory.tpccourse[0].category)
        }
        
        

    }, [props.createTpcCourseCategory])

    
    

    useEffect(() => {
        if(props.fetchTpcCourses.tpccourses.length){
            
            if(typeof props.courseData.category != "undefined"){
                setItems(props.fetchTpcCourses.tpccourses.find(course => course.tpcCourseId == props.courseData.tpcCourseId).category)
                
            }
            else{
                setItems([])
            }
            
        }
    },[props.fetchTpcCourses])
    
    const [items, setItems]= useState(props.fetchTpcCourses.tpccourses.length && typeof props.courseData.category !== "undefined" ? props.fetchTpcCourses.tpccourses.find(course => course.tpcCourseId == props.courseData.tpcCourseId).category : []);

    const DragHandle = SortableHandle(() => <span style={{ cursor: 'grab' }} className='text-info'><i className="fas fa-bars"></i>&nbsp;</span>);
    

    const [selectedCategory, setSelectedCategory] = useState(false);
    
    const handleCreateLesson = (e,value, categoryId) => {
        e.preventDefault()
        setCategoryId(categoryId)
        setEditCourseCategoryLessons(false)
        setEditCourseCategory(false)
        setSelectedCategory(value)
        setModalAddTpcCourseCategoryLessonsModal(!modalAddTpcCourseCategoryLessonsModal);

    }


    const [categoryToUpdate, setCategoryToUpdate] = useState({})
    const [lessonToUpdate, setLessonToUpdate] = useState({})

    

    const handleUpdateCategory = (e,value) => {
        e.preventDefault()
        toggleAddTpcCourseCategoryModal()

        setEditCourseCategory(true)

        setCategoryToUpdate(value)
    }


    const handleUpdateLesson = (e, value, categoryId) => {
        e.preventDefault()
        
        value.categoryId = categoryId
        setCategoryId(categoryId)

        
        setEditCourseCategoryLessons(true)
        // setModalAddTpcCourseCategoryLessonsModal(!modalAddTpcCourseCategoryLessonsModal);
        setLessonToUpdate(value)
        setModalAddTpcCourseCategoryLessonsModal(!modalAddTpcCourseCategoryLessonsModal);

    }
    

    const SortableItem = SortableElement(({ value , categoryId}) => (
        
        <Row className='mb-3'>
            <Col>
                <Card className="shadow">
                    <CardHeader className="border-0">
                        
                            
                            {/* {value.text} */}

                            <div className="row mb-3">
                                <div className='col-lg-7'>
                                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                                        Category {value.id}
                                    </h6>
                                    <h2 className="mb-0"><DragHandle /> {value.text}</h2>
                                </div>
                                <div className='col-lg-5 text-lg-right mt-lg-0 mt-3 mb-lg-0 mb-3 text-center'>
                                    <Button color="info" onClick={e => handleCreateLesson(e, value , categoryId)}><i className="fas fa-plus-circle"></i></Button>
                                    <Button color="warning" onClick={e => handleUpdateCategory(e, value)}><i className="fas fa-pen"></i></Button>
                                    <Button color="danger" onClick={e => handleDeleteTpcCourseCategory(e, categoryId )}><i className="fas fa-trash"></i></Button>
                                </div>
                            </div>
                            
                            {value.lessons && (
                                <SortableLessonList
                                categoryId ={value.id}
                                items={value.lessons}
                                onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex, true, value.id)}
                                isChild
                                />
                            )}
                            
                        
                    </CardHeader>
                    {/* <CardBody>
                        
                        
                    </CardBody> */}
                </Card>
                
            </Col> 
        </Row>

    ));

    const SortableLessonItem = SortableElement(({ value, categoryId, items }) => (
        
        <Row className='mb-2'>
            <Col>
                <Card className="shadow">
                    <CardHeader className="border-0">

                            {/* {value.text} */}

                            <div class="row">
                                <div className='col-lg-8'>
                                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                                        Lesson {value.id} - {value.visibility == "draft" ? <label className='text-warning'>{value.visibility}</label> : <label className='text-success'>{value.visibility}</label>}
                                    </h6>
                                    <h2 className="mb-0"><DragHandle /> {value.title}</h2>
                                </div>
                                <div className='col-lg-4 text-lg-right mt-lg-0 mt-3 text-center'>
                                    {/* <Button color="info" onClick={e => handleCreateLesson(value)}><i className="fas fa-plus-circle"></i></Button> */}
                                    <Button color="primary" onClick={e => handleUpdateLesson(e,value, categoryId)}><i className="fas fa-pen"></i></Button>
                                    <Button color="danger" onClick={e => handleDeleteTpcCourseLesson(e,value, items, categoryId)}><i className="fas fa-trash"></i></Button>
                                </div>
                            </div>   
                        
                    </CardHeader>
                    {/* <CardBody>
                        
                        
                    </CardBody> */}
                </Card>
                
            </Col> 
        </Row>

    ));
    
    
    const SortableList = SortableContainer(({ items, isChild }) => {
        
        const onSortEndHandler = ({ oldIndex, newIndex }) => {
            onSortEnd(oldIndex, newIndex, isChild);
            
        };
    
        return (
        <ul className="list-unstyled">
            {items.map((value, index) => (
            <SortableItem key={`item-${value.id}`} index={index} categoryId={value.id} value={value} onSortEnd={onSortEndHandler} />
            ))}
        </ul>
        );
    });
    
    const DraggableSortList = () => {
    
        return <SortableList items={items} onSortEnd={onSortEnd} />;
    };

    const SortableLessonList = SortableContainer(({ items, isChild, categoryId }) => {
        
        const onSortEndHandler = ({ oldIndex, newIndex }) => {
            onSortEnd(oldIndex, newIndex, isChild);
        };
    
        return (
        <ul className="list-unstyled">
            {items.map((value, index) => (
            <SortableLessonItem key={`item-${value.id}`} categoryId={categoryId} index={index} value={value} onSortEnd={onSortEndHandler} items={items} />
            ))}
        </ul>
        );
    });
    
    const DraggableSortLessonList = () => {
    
        return <SortableLessonList items={items} onSortEnd={onSortEnd} />;
    };

    // const [items, setItems] = useState(initialItems);

    const onSortEnd = (oldIndex, newIndex, isChild, parentId) => {
        
        if (isChild) {
            const updatedItems = [...items];
            const parentIndex = updatedItems.findIndex(item => item.id == parentId);
            
            const parentItem = updatedItems[parentIndex];
            
            parentItem.lessons = arrayMoveImmutable(parentItem.lessons, oldIndex.oldIndex, oldIndex.newIndex);
            
            parentItem.lessons.forEach((lesson, index) => {
                lesson.id = index + 1;
            });
            
            setItems(updatedItems);

            dispatch(updateTpcCourseCategoryOrder({category : updatedItems, tpcCourseId : props.courseData.tpcCourseId, handle : props.userDetails.handle}))

            
        } else {

            let newOrderCategories = arrayMoveImmutable(items, oldIndex.oldIndex, oldIndex.newIndex)
            newOrderCategories.forEach((category, index) => {
                category.id = index + 1;
            });

            
            setItems(newOrderCategories);

            dispatch(updateTpcCourseCategoryOrder({category : newOrderCategories, tpcCourseId : props.courseData.tpcCourseId, handle : props.userDetails.handle}))
        }
    };

    
    const handleDeleteTpcCourseLesson = (e, value, lessonsList, categoryId) => {
        e.preventDefault()

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            
            if (result.isConfirmed) {
                Swal.showLoading()
                let lessons = lessonsList;
        
                lessons = lessons.filter(lesson => lesson.id != value.id)
                lessons.forEach((lesson, index) => {
                    lesson.id = index + 1;
                });

                let categories = items

                let categorySelected = categories.find(category => category.id == categoryId)
                categorySelected.lessons = lessons
                
                
                
                dispatch(deleteTpcCourseLessons({handle: props.userDetails.handle, categories : categories, tpcCourseId: props.courseData.tpcCourseId}))
        
            }
        })
    }

    const handleDeleteTpcCourseCategory = (e, categoryId) => {
        e.preventDefault()
        
        
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            
            if (result.isConfirmed) {
                
                let categories = items
                let categorySelected = categories.filter(category => category.id != categoryId)
                categorySelected.forEach((category, index) => {
                    category.id = index + 1;
                });
                        
                
                dispatch(deleteTpcCourseCategory({handle: props.userDetails.handle, categories : categorySelected, tpcCourseId: props.courseData.tpcCourseId}))
        
            }
        })
    }

    useEffect(() => {
    
  
        if(props.deleteTpcCourseLessons.tpccourse.length && typeof props.deleteTpcCourseLessons.error === "undefined"){
          
            Swal.fire({
                title: 'Success!',
                text: 'Successfully deleted TPC course lesson!',
                icon : 'success',
                timer: 3000
                
                }
            ).then((result) => {
                dispatch(fetchTpcCourses({}))
                dispatch(deleteTpcCourseLessonsReset());
                
            })
          
        }
      
        else if(typeof props.deleteTpcCourseLessons.error !== "undefined"){
       
            Swal.fire({
                title:'Failed!',
                text:'Failed to delete TPC course lesson!',
                icon :'error',
                timer: 3000
            
            }
          ).then((result) => {
            dispatch(fetchTpcCourses({}))
            dispatch(deleteTpcCourseLessonsReset());
            
          })    
                
        }
      
    }, [props.deleteTpcCourseLessons])

    useEffect(() => {
    
  
        if(props.deleteTpcCourseCategory.tpccourse.length && typeof props.deleteTpcCourseCategory.error === "undefined"){
          
            Swal.fire({
                title: 'Success!',
                text: 'Successfully deleted TPC course category!',
                icon : 'success',
                timer: 3000
              
            }
          ).then((result) => {
            dispatch(fetchTpcCourses({}))
            dispatch(deleteTpcCourseCategoryReset());
            
          })
          
        }
      
        else if(typeof props.deleteTpcCourseCategory.error !== "undefined"){
       
          Swal.fire({
              title:'Failed!',
              text:'Failed to delete TPC course category!',
              icon :'error',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(fetchTpcCourses({}))
            dispatch(deleteTpcCourseCategoryReset());
            
          })    
                
        }
      
    }, [props.deleteTpcCourseCategory])

    const handleCourseReset = (data) =>{
       setItems(data)
        // (props.fetchTpcCourses.tpccourses.find(course => course.tpcCourseId == props.courseData.tpcCourseId).category)
    }

    return (
        <>
            
            
                <Row className='mb-3'>

                    <Col md="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div className='float-left'>
                                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                                        TPC Course
                                    </h6>
                                    <h2 className="mb-0">{props.courseData.courseTitle}</h2>
                                </div>
                                <div className='float-right'>
                                    <Button color="primary" onClick={toggleAddTpcCourseCategoryModal}>Create Category</Button>
                                </div>
                            </CardHeader>
                            {/* <CardBody>
                                
                                
                            </CardBody> */}
                        </Card>
                        
                    </Col> 

                    <Col md="12">
                        
                    </Col>

                </Row>
                <DraggableSortList/>
                
           
            
            <AddTpcCourseCategoryModal modalAddTpcCourseCategoryModal={modalAddTpcCourseCategoryModal} toggleAddTpcCourseCategoryModal={toggleAddTpcCourseCategoryModal} editCourseCategory={editCourseCategory} courseData={courseProps} categoryToUpdate={categoryToUpdate} items={items}/> 
            <AddTpcCourseCategoryLessonsModal modalAddTpcCourseCategoryLessonsModal={modalAddTpcCourseCategoryLessonsModal} toggleAddTpcCourseCategoryLessonsModal={toggleAddTpcCourseCategoryLessonsModal} editCourseCategoryLessons={editCourseCategoryLessons} items={items} selectedCategory={selectedCategory} courseData={courseProps} lessonToUpdate={lessonToUpdate} categoryId={categoryId} handleCourseResetCallBack={handleCourseReset}/>              
        </>
      );
};

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        createTpcCourseCategory : state.createTpcCourseCategory,
        fetchTpcCourses : state.fetchTpcCourses,
        deleteTpcCourseLessons : state.deleteTpcCourseLessons,
        deleteTpcCourseCategory : state.deleteTpcCourseCategory
        
    }
      
}

export default connect(mapStateToProps)(ManageTpcCoursesLessons);