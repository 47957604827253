import React, {useState, useEffect} from 'react'
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Badge,
    CardImg,
    CardImgOverlay,
    TabContent, TabPane, Nav, NavItem, NavLink, 
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Form,
    Alert,
    Spinner,
    Media,
    Table
} from "reactstrap";
import { connect, useDispatch } from 'react-redux';
import classnames from "classnames";
import DataTable from "react-data-table-component";
import {fetchPremiumHourLogs} from "redux/Logs/LogsAction";
import moment from "moment-timezone";

const StudentFreeHourLogs = (props) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
   
	const [search, setSearch] = useState("");
  	const [data, setData] = useState([]);
  	const [filter, setFilter] = useState([]);
 
    useEffect(() => {
        if(props.studenthandle){
            dispatch(fetchPremiumHourLogs({handle: props.studenthandle}))
        }
	}, [props.studenthandle]);

    useEffect(() => {
		if (props.fetchPremiumHourLogs.loading) {
           setLoading(true)
		} else {
			setLoading(false)
			if (props.fetchPremiumHourLogs.logs.length) {
                setData(props.fetchPremiumHourLogs.logs)
                setFilter(props.fetchPremiumHourLogs.logs)
              
			}else{
                setData([])
                setFilter([])
            }
		}
	}, [props.fetchPremiumHourLogs]);

    useEffect(() => {
		const result = data.filter((item) => {
    
			if(item){
				return (

					modifiedSearch(item.coach.name).match(search.toLocaleLowerCase()) ||
                    modifiedSearch(item.item.comment).match(search.toLocaleLowerCase()) 
                   
				)
			}
		});
       
    	setFilter(result);
		
	}, [search]);

	const modifiedSearch = (item) =>  {
        console.log(item)
		if(item !== undefined){
			if(item !== undefined){
				return  item.toLowerCase()
			} 
			return  item.toLowerCase()
		}else{
			return '';
		}
	}

    const convertDate = (date) => {
        return moment(date).utcOffset(0, true).format('LL');
    }

    const convertTimeToLocal = (time) => {
        return moment(moment(time).utcOffset(0, true), "HH:mm")
    }

    const convertDateWithTime = (date, itemFrom,itemTo) => {
        let localdate = convertDate(date)
        let start = convertTimeToLocal(itemFrom).format("h:mm A")
        let end = convertTimeToLocal(itemTo).format("h:mm A")
        return localdate + ", "+start+"-"+end
    }

    const loadHistory = () =>{
        dispatch(fetchPremiumHourLogs({handle: props.studenthandle}))
    }

    const columns = [
        {
            name: "Coach",
            sortable: true,
            wrap: true,
            selector: (row) => {
                return row.coach.name
            }
        },
        {
            name: "Current",
            sortable: true,
            wrap: true,
            selector: (row) => {
                return  <h4 className="text-primary ml-2"> {Number(row.item.currentTotal) }</h4>    
                
            }       
        },
        {
            name: "Hour",
            sortable: true,
            wrap: true,
            selector: (row) => {
                return  row.item.mode == "add" ? <h4 className="text-success ml-2"> + {row.item.hour}</h4> :  <h4 className="text-danger ml-2"> - {row.item.hour}</h4>    
                
            }       
        },
        {
            name: "Comment",
            selector: (row) => {
                return  row.item.comment
                
            }       
        },
        {
            name: "Date",
            center: "true",
            sortable: true,
            wrap: true,
            selector: (row) => {
                return convertDateWithTime(row.item.date, row.item.startTime,  row.item.endTime)
            }
        },

    ];
    
    const customTableStyle = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px",
                // backgroundColor: "#ccc",
            },
        },
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };

    const handleRowClicked = (item) => {  
       

    }


    return (
        <Card className="shadow">
            <CardBody>
                
                <CardTitle>
                   <h4>Premium Hour History <span className="fa fa-sync-alt ml-2 text-primary" onClick={loadHistory} role="button" title="Reload History"></span></h4>  
                </CardTitle>
                { 							
                    loading ? 
                        <div className="m-5">
                            <Alert color="secondary" className="text-center">
                                Collecting history.. Please wait while loading {" "}
                                <Spinner size="sm" color="primary" />{" "}
                            </Alert>
                        </div>
                    : data.length ? 
                            <DataTable
                                onRowClicked={handleRowClicked}
                                progressPending={loading}
                                defaultSortFieldId="3"
                                defaultSortAsc="false"
                                paginationPerPage="5"
                                customStyles={customTableStyle}
                                columns={columns}
                                data={filter}
                                pagination
                                wrap
                                // selectableRows
                                // fixedHeader
                                selectableRowsHighlight
                                highlightOnHover
                                subHeader
                                subHeaderComponent={
                                <input
                                    type="text"
                                    className="w-50 form-control"
                                    placeholder="Search..."
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                }
                                subHeaderAlign="right"
                            />
                    : 
                    <div className="m-5">
                        <Alert color="secondary" className="text-center">
                            No data available..
                        </Alert>
                    </div>
                
                }

        </CardBody>
       </Card>
    )

}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        fetchPremiumHourLogs: state.fetchPremiumHourLogs
    }
      
}

export default connect(mapStateToProps)(StudentFreeHourLogs)
