
import { initializeApp } from "firebase/app";
// import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
// import { initializeApp } from "firebase-admin/app";
// const { initializeApp } = require("firebase-admin/app");



const firebaseConfig = {
    apiKey: "AIzaSyCEWZprIcbgpCWbbZLRvStFH6ZYia1rbP8",
    authDomain: "tpcdashboard.firebaseapp.com",
    databaseURL: "https://tpcdashboard.firebaseio.com",
    projectId: "tpcdashboard",
    storageBucket: "tpcdashboard.appspot.com",
    messagingSenderId: "449085225291",
    appId: "1:449085225291:web:3acb6e50d58454e33ad0ce",
    measurementId: "G-YSPM3PXDPJ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);




