import React, { useState} from 'react'
import AuthNavbar from "components/Navbars/AuthNavbar";

import { 
  Row, 
  Col, 
  Card, 
  CardBody,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import Vimeo from '@u-wave/react-vimeo';
import {useForm} from 'react-hook-form';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import HomeSubscriptionForm from 'components/Payments/HomeSubscriptionForm'
import { connect } from 'react-redux';
// const stripePromise = loadStripe('pk_test_51Jc7T3LrYzlL9Yv87MMJ6RYPDrp3btJth6fbaZJrykBY0ePHxiMIs9jdohnTcovMW75tepSVyTx1qqdHfW5C6G2n00Qa2b7ZuR');
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Home = (props) => {
  const responsiveTextStyle = {
    fontSize: 'calc(2rem + 1vw)',
  };

  const imageStyle = {
    position: 'absolute',
    top: -80,
    left: '50%',
    transform: 'translateX(-50%)',
    height: '180px',
  };

  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-secondary");
    return () => {
      document.body.classList.remove("bg-secondary");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);


  const elementsOptions = {
    mode: 'subscription',
    amount: 1099,
    currency: 'usd',
    // Fully customizable with appearance API. 
  
    appearance: {/*...*/}
    
  };


  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar/>
        <div className="header bg-gradient-default py-7 pt-lg-8 pb-lg-8">
          <div className=" px-4 px-md-4 px-lg-4 px-xl-8 mt-5">
            <div className="header-body text-center mt-5">
              <Row className="justify-content-center">
                
                <Col lg={props.authenticated ? "12" : "7"} className='mb-5 d-flex align-items-center'>
                  <div>
                    <h1 className="text-white text-sm mb-4 font-weight-bolder">THE PREMED CONSULTANTS</h1>
                    <span className="display-1 text-success font-weight-bolder lh-120" style={responsiveTextStyle}>3.2X </span>
                    <span className="display-1 text-white lh-120" style={responsiveTextStyle}>
                        Your Medical
                        School Acceptance Chances with a </span>
                    <span className="display-1 text-info font-weight-bolder lh-120" style={responsiveTextStyle}>TPC Premed Tracker Pro Account</span>
                    <p className="text-gray text-sm font-weight-bolder mt-3 mb-2">Become a 4.0 student, track your premed progress, gain exclusive premed insights</p>
                    {/* <Row className='mt-5 mb-3'>
                      <Col md="1"></Col>
                      <Col md="10">
                        <Vimeo
                          video="954619954" 
                          className='shadow'
                          responsive
                          width="100%"
                        />
                      </Col>
                      <Col md="1"></Col>
                    </Row> */}
                    <Row>
                      <Col md="2"></Col>
                      <Col md="8">
                        <img className='img-thumbnail shadow img-fluid mb-5 mt-3' src={require("assets/img/landing_page/login.png")}></img>
                      </Col>
                      <Col md="2"></Col>
                    </Row>
                    <i className="display-4 text-white font-weight-bolder lh-120 ">Gain exclusive access to <span className='text-warning d-inline font-weight-bolder'>Dr. Welch</span>, a <span className='text-yellow d-inline font-weight-bolder'>former Dean of Admissions</span>, who will personally <span className='text-info d-inline font-weight-bolder'>answer your questions</span> and <span className='text-info d-inline font-weight-bolder'>provide invaluable insights</span> to <span className='text-success d-inline font-weight-bolder'>help you succeed</span>.</i>
                    
                    
                  </div>
                  
                </Col>
                <Col lg="5" className={props.authenticated ? "d-none" : "mt-5"} >
                  <Card className='shadow '>
                    <CardBody className='pt-7'>
                      <img className='' src={require("assets/img/landing_page/money-back.png")} style={imageStyle}></img>

                      <Elements stripe={stripePromise} options={elementsOptions}>
                        <HomeSubscriptionForm></HomeSubscriptionForm>
                      </Elements>

              

                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-info"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        
        
        {/* Page content */}
        
      </div>
      <div className="py-4 container-fluid px-4 px-md-4 px-lg-4 px-xl-8 mt-5">
          <Row>

            <Col md="2"></Col>
            <Col md="8" className='text-center text-default mb-4'>
              <p className='font-weight-900 mb-1 text-warning display-4'>WHAT YOU'LL GET WHEN YOU SIGN UP</p>
              <p className='text-lg'>Keep track of your premed progress and get real-time feedback on where you are as an applicant, how you are improving, and finishing your requirements.</p>
            </Col>
            <Col md="2"></Col>


            <Col md="1"></Col>
            <Col md="10" className='text-center'>
              <img className='img-thumbnail shadow img-fluid mb-5' src={require("assets/img/landing_page/dashboard.png")}></img>
            </Col>
            <Col md="1"></Col>

            <Col xl="1" lg="0" md="0"></Col>
            <Col xl="5" lg="6" md="6">
              <ul className="list-unstyled mb-0">
                <li>
                    <p className='text-lg text-default font-weight-600 mb-0 d-flex align-items-center justify-content-center lh-130'>
                        <i className="display-4 fas fa-check-circle text-success mr-1"></i>Shadowing and Clinical Hours Tracker
                    </p>
                </li>
                <li>
                    <p className='text-lg text-default font-weight-600 mb-0 d-flex align-items-center justify-content-center lh-130'>
                        <i className="display-4 fas fa-check-circle text-info mr-1"></i>Work/Activities Section Log
                    </p>
                </li>
                <li>
                    <p className='text-lg text-default font-weight-600 mb-0 d-flex align-items-center justify-content-center lh-130'>
                        <i className="display-4 fas fa-check-circle text-warning mr-1"></i>GPA Trend Tracker
                    </p>
                </li>
                <li>
                    <p className='text-lg text-default font-weight-600 mb-0 d-flex align-items-center justify-content-center lh-130'>
                        <i className="display-4 fas fa-check-circle text-danger mr-1"></i>Science GPA Calculator
                    </p>
                </li>
                <li>
                    <p className='text-lg text-default font-weight-600 mb-0 d-flex align-items-center justify-content-center lh-130'>
                        <i className="display-4 fas fa-check-circle text-primary mr-1"></i>MCAT Section Score Tracker
                    </p>
                </li>
                <li>
                    <p className='text-lg text-default font-weight-600 mb-0 d-flex align-items-center justify-content-center lh-130'>
                        <i className="display-4 fas fa-check-circle text-success mr-1"></i>Premed Requirements Progress Tracker
                    </p>
                </li>

              </ul>
            </Col>
            <Col xl="5" lg="6" md="6">
              <ul className="list-unstyled">
                <li>
                    <p className='text-lg text-default font-weight-600 mb-0 d-flex align-items-center justify-content-center'>
                        <i className="display-4 fas fa-check-circle text-info mr-1"></i>Premed AI Chatbot (Tutor/Coaching 24/7)
                    </p>
                </li>
                <li>
                    <p className='text-lg text-default font-weight-600 mb-0 d-flex align-items-center justify-content-center'>
                        <i className="display-4 fas fa-check-circle text-warning mr-1"></i>Medical School Search Tool (MD & DO)
                    </p>
                </li>
                <li>
                    <p className='text-lg text-default font-weight-600 mb-0 d-flex align-items-center justify-content-center'>
                        <i className="display-4 fas fa-check-circle text-danger mr-1"></i>Secondary Essay Writing Help
                    </p>
                </li>
                <li>
                    <p className='text-lg text-default font-weight-600 mb-0 d-flex align-items-center justify-content-center'>
                        <i className="display-4 fas fa-check-circle text-primary mr-1"></i>CASPER Prep (Coming Soon)
                    </p>
                </li>
                <li>
                    <p className='text-lg text-default font-weight-600 mb-0 d-flex align-items-center justify-content-center'>
                        <i className="display-4 fas fa-check-circle text-success mr-1"></i>Application Analysis (Coming Soon)
                    </p>
                </li>
                <li>
                    <p className='text-lg text-default font-weight-600 mb-0 d-flex align-items-center justify-content-center'>
                        <i className="display-4 fas fa-check-circle text-info mr-1"></i>Exclusive Premed Group Access
                    </p>
                </li>

              </ul>
            </Col>
            <Col xl="1" lg="0" md="0"></Col>


          </Row>
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              
            </Col>
          </Row>
      </div>
      <div className="py-5 container-fluid bg-default px-4 px-md-4 px-lg-4 px-xl-8 mt-5">
        <Row className='mt-3'>
          <Col md="2"></Col>
          <Col md="8" className='text-center text-white mb-6 '>
            <p className='font-weight-900 mb-1 text-info display-4'>CHECK OUT THESE AWESOME FEATURES</p>
           
          </Col>
          <Col md="2"></Col>

          <Col md="1"></Col>
          <Col md="10" className='text-center text-white mb-3'>
            <Row className='mb-4'>
              <Col lg="6" md="12" className='d-flex justify-content-center align-items-center order-last order-md-first'>
                <div>
                  <p className='text-xl font-weight-900 mb-1 text-success'>School Search</p>
                  <p className='text-lg lh-140'>Advanced school search that lets you find all MD and DO schools that would be the best fit for you. Think MSAR meets DOexplorer but better! Our advanced filters let you make custom lists so that you ensure you are applying to the right medical schools.<br/><br/>Pro members will also get access to our application analysis which matches a students app with schools they may be a good fit for and determines if all requirements have been fulfilled.</p>
                </div>
              </Col>
              <Col lg="6" md="12" className='order-first order-md-last d-flex justify-content-center align-items-center'>
                <img className='img-thumbnail shadow img-fluid mb-3' src={require("assets/img/landing_page/school-search.png")}></img>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col lg="6" md="12" className='d-flex justify-content-center align-items-center order-last order-md-first'>
                <div>
                  <p className='text-xl font-weight-900 mb-1 text-warning'>My Application</p>
                  <p className='text-lg lh-140'>The my applications tab lets you track what stage you are at for each school you apply to. Every med student can attest this was one of the hardest parts about the application, keeping it all organized!<br/><br/>You can also start pre-writing all the secondary essays which is extremely important to get your apps in on time.</p>
                </div>
              </Col>
              <Col lg="6" md="12" className='order-first order-md-last d-flex justify-content-center align-items-center'>
                <img className='img-thumbnail shadow img-fluid mb-3' src={require("assets/img/landing_page/my-application.png")}></img>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col lg="6" md="12" className='d-flex justify-content-center align-items-center order-last order-md-first'>
                <div>
                  <p className='text-xl font-weight-900 mb-1 text-danger'>Ideas Feature (PRO)</p>
                  <p className='text-lg lh-140'>As a PRO member you get priority feature requests!!! You get access to our ideas board to vote on future features and even recommend your own. Our development team will roll out features based on upvotes from our pro members.</p>
                </div>
              </Col>
              <Col lg="6" md="12" className='order-first order-md-last d-flex justify-content-center align-items-center'>
                <img className='img-thumbnail shadow img-fluid mb-3' src={require("assets/img/landing_page/ideas.png")}></img>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col lg="6" md="12" className='d-flex justify-content-center align-items-center order-last order-md-first'>
                <div>
                  <p className='text-xl font-weight-900 mb-1 text-primary'>MCAT Powerbook</p>
                  <p className='text-lg lh-140'>Get an online copy of The MCAT Powerbook! This is the one stop shop high-yield review textbook for all the content you need to know for the MCAT. This book is an extremely effective way to assess if you know everything you need for the MCAT</p>
                </div>
              </Col>
              <Col lg="6" md="12" className='order-first order-md-last d-flex justify-content-center align-items-center'>
                <img className='img-thumbnail shadow img-fluid mb-3' src={require("assets/img/landing_page/mcat-powerbook.png")}></img>
              </Col>
            </Row>
          </Col>
          <Col md="1"></Col>


        </Row>

        
      </div>

      <div className="py-5  px-4 px-md-4 px-lg-4 px-xl-8">
        <Row className='mt-3'>
          <Col md="2"></Col>
          <Col md="8" className='text-center text-white mb-6'>
            <p className='font-weight-900 mb-1 text-default display-4'>ADDITIONAL BONUS IF YOU <i className='text-warning text-underline'>PURCHASE NOW!</i></p>
           
          </Col>
          <Col md="2"></Col>

          <Col md="1"></Col>
          <Col md="10" className='text-center text-default mb-3'>
            <Row className='mb-4'>
              <Col lg="6" md="12" className='mb-4'>
                
                <div>
                  <p className='text-xl font-weight-900 mb-1 text-info py-2 bg-default d-flex align-items-center justify-content-center'><i class="fas fa-medal display-3 text-success"></i>&nbsp;&nbsp;Bonus 1 : Anki Course</p>
                  <img className='shadow img-fluid mb-3' src={require("assets/img/landing_page/bonus1.png")}></img>
                  <p className='text-md font-weight-500 lh-140'>Many premed students have heard of anki, but most do not know how to use it correctly. By the end of this course you will unlock the full potential of anki and be able to fully commit any information to long term memory.<br/><br/>This is the method many top medical students study for their exams and boards and now premeds are using it to crush their classes and the MCAT.</p>
                </div>
              </Col>

              <Col lg="6" md="12" className='mb-4'>
                
                <div>
                  <p className='text-xl font-weight-900 mb-1 text-info py-2 bg-default d-flex align-items-center justify-content-center'><i class="fas fa-medal display-3 text-warning"></i>&nbsp;&nbsp;Bonus 2 : MCAT Powerbook</p>
                  <img className='shadow img-fluid mb-3' src={require("assets/img/landing_page/bonus2.png")}></img>
                  <p className='text-md font-weight-500 lh-140'>The MCAT Powerbook is a high-yield review of all the content you need for the MCAT. Topics are broken down into easy-to-understand points with clear illustrations to aid comprehension.<br/><br/>Knowing the content in this book ensures you'll be able to answer any MCAT question. It's also great for identifying high-yield topics as you work through premed prerequisite classes.</p>
                </div>
              </Col>

              <Col lg="6" md="12" className='mb-4'>
                
                <div>
                  <p className='text-xl font-weight-900 mb-1 text-info py-2 bg-default d-flex align-items-center justify-content-center'><i class="fas fa-medal display-3 text-primary"></i>&nbsp;&nbsp;Bonus 3 : TPC Elite Study Skills</p>
                  <img className='shadow img-fluid mb-3' src={require("assets/img/landing_page/bonus3.png")}></img>
                  <p className='text-md font-weight-500 lh-140'>Sick and tired of putting in countless hours of studying, skipping social events, and still not getting the grades you feel you deserve?<br/><br/>This course will show you exactly how top premed and medical students study using PROVEN evidence-based learning strategies.<br/><br/>Not only will you start getting better grades, but you will do it with more free time to spare.</p>
                </div>
              </Col>

              <Col lg="6" md="12" className='mb-4'>
                
                <div>
                  <p className='text-xl font-weight-900 mb-1 text-info py-2 bg-default d-flex align-items-center justify-content-center'><i class="fas fa-medal display-3 text-yellow"></i>&nbsp;&nbsp;Bonus 4 : MCAT Self Studier</p>
                  <img className='shadow img-fluid mb-3' src={require("assets/img/landing_page/bonus4.png")}></img>
                  <p className='text-md font-weight-700 lh-140'>Everything you need to score well on the MCAT without wasting time</p>
                  <ul className="list-unstyled">
                    <li className='d-flex align-items-center justify-content-center mb-2'><i class="fas fa-check-circle text-success text-xl"></i>&nbsp;MCAT Science Strategy</li>
                    <li className='d-flex align-items-center justify-content-center mb-2'><i class="fas fa-check-circle text-success text-xl"></i>&nbsp;MCAT CARS Strategy</li>
                    <li className='d-flex align-items-center justify-content-center mb-2'><i class="fas fa-check-circle text-success text-xl"></i>&nbsp;AAMC Material Breakdown</li>
                    <li className='d-flex align-items-center justify-content-center mb-2'><i class="fas fa-check-circle text-success text-xl"></i>&nbsp;Recordings of Live High Yield MCAT</li>
                    <li className='d-flex align-items-center justify-content-center mb-2'><i class="fas fa-check-circle text-success text-xl"></i>&nbsp;High Yield Content Review Videos</li>
                    <li className='d-flex align-items-center justify-content-center mb-2'><i class="fas fa-check-circle text-success text-xl"></i>&nbsp;Full Qbank Question Calculator & Schedule Template</li>
                  </ul>
                </div>
              </Col>
            </Row>

      
          </Col>
          <Col md="1"></Col>


        </Row>

        
      </div>
      
      <div className="py-5 bg-info px-4 px-md-4 px-lg-4 px-xl-8">
        <Row className='mt-3 mb-5'>
            
            <Col md="12" className='text-center text-white mb-5'>
              <p className='font-weight-900 mb-1 text-white text-lg'>REASONS WHY WE LOVE WHAT WE DO</p>
              <p className='font-weight-900 mb-1 text-white display-4'>HEAR FROM OUR VALUED STUDENTS</p>
            </Col>
            <Col md="12">
                <Row>
                  <Col md="4" className='mb-4'>
                    <Vimeo
                      video="954619954" 
                      className='shadow'
                      responsive
                      width="100%"
                    />

                  </Col>
                  <Col md="4" className='mb-4'>
                    <Vimeo
                      video="954618863" 
                      className='shadow'
                      responsive
                      width="100%"
                    />

                  </Col>

                  <Col md="4" className='mb-4'>
                    <Vimeo
                      video="https://vimeo.com/954618950/dadae5c17c?share=copy" 
                      className='shadow'
                      responsive
                      width="100%"
                    />

                  </Col>

                  <Col md="4" className='mb-4'>
                    <Vimeo
                      video="954618139" 
                      className='shadow'
                      responsive
                      width="100%"
                    />

                  </Col>

                  <Col md="4" className='mb-4'>
                    <Vimeo
                      video="https://vimeo.com/954618322/701dfffe8a?share=copy" 
                      className='shadow'
                      responsive
                      width="100%"
                    />

                  </Col>

                  <Col md="4" className='mb-4'>
                    <Vimeo
                      video="954618208" 
                      className='shadow'
                      responsive
                      width="100%"
                    />

                  </Col>


                </Row>
            </Col>
        </Row>
      </div>
      <div className="py-3 bg-default px-4 px-md-4 px-lg-4 px-xl-8">
        <p className='text-center mb-0 font-weight-bolder text-muted'>Copyright ©2024 All rights reserved</p>
      </div>
   
    </>
    
  )
}

const mapStateToProps = state  => {
  return {
      authenticated: state.loginAuth.authenticated,
  }
    
}

export default connect(mapStateToProps)(Home)