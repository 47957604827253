import React, {useState, useEffect} from 'react'
import Header from 'components/Headers/Header';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    Button,
    Row,
    Col,
    CardImg,
    Media,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Badge
} from "reactstrap";
import Swal from 'sweetalert2'
import { connect, useDispatch } from 'react-redux';
import { fetchTpcCourses, deleteTpcCourse, deleteTpcCourseReset } from 'redux/ManageTpcCourses/ManageTpcCoursesActions'
import { fetchPaymentRecord } from 'redux/Payments/PaymentsActions';
import TpcCoursesLessons from 'components/Pages/TpcCoursesLessons';
import Paginate from 'components/Pagination/TpcCoursesPagination';
import CourseItemList from 'components/Courses/CourseItemList';
import { studentsLifetimePlan } from "util/DataList";
import { useHistory } from 'react-router-dom';
const TpcCourses = (props) => {

     const addZeroes = num => num.toLocaleString("en",{minimumFractionDigits: 2})

    const [modalAddTpcCourseModal, setModalAddTpcCourseModal] = useState(false);
    const [initialCourseList, setInitialCourseList] = useState([]);
    const [tpcCoursesLists, setTpcCoursesLists] = useState([]);

    const [tpcPaymentRecord,setTpcPaymentRecord] = useState([]);

    const history = useHistory();
    
    const toggleAddTpcCourseModal = () =>{
        
        setModalAddTpcCourseModal(!modalAddTpcCourseModal);
        
    } 

    const dispatch = useDispatch()
    
    useEffect(() => {
        dispatch(fetchPaymentRecord({table: 'tpcCourses', user: props.userDetails.handle}))
        dispatch(fetchTpcCourses({visibility: "published" }))
        fetchSubscriptionDetails()
    }, [])
    
    useEffect(() => {
        
        if(props.fetchTpcCourses.tpccourses.length){
            setInitialCourseList(props.fetchTpcCourses.tpccourses)
            // const res = props.fetchTpcCourses.tpccourses.map((obj,i) => ({
            //     ...obj,
            //     payment: checkIfPaid(obj.tpcCourseId)
               
            // }))
            // setInitialCourseList(res)
        }
        
    }, [props.fetchTpcCourses ])

    useEffect(() => {
        if(props.fetchPaymentRecord.fetchpaymentrecord.length){
            setTpcPaymentRecord(props.fetchPaymentRecord.fetchpaymentrecord[0])
        }
    }, [props.fetchPaymentRecord ])

    useEffect(() => {
        if(initialCourseList){
            let collectData = []
            
            initialCourseList.map((obj,i) => {
                for (let i = 0; i < tpcPaymentRecord.length; i++) {
                    if(obj.tpcCourseId == tpcPaymentRecord[i].collectionDocId){
                        obj.payment = tpcPaymentRecord[i]
                    }
                    
                }
                collectData.push(obj)
            })
           setTpcCoursesLists(collectData)

        }
    }, [tpcPaymentRecord, initialCourseList])

    // const checkIfPaid = (cid) => {
    //     const result = []
    //     if(tpcPaymentRecord.length > 0){
    //         tpcPaymentRecord.map((pay,i) => (
    //             pay ?  cid == pay.collectionDocId ? result.push(pay) : "" : ""
                
    //         ))
    //     }
    //     console.log(result)
    //     return result        
    // }

    
    const [startCourseData, setStartCourseData] = useState({});

    const handleCourseItemCallback =  (course) => {
        const lessonCount = typeof course.category != "undefined" ? course.category.reduce((totalPublishedLessons, category) =>
        totalPublishedLessons + (category.lessons && category.lessons.filter(lesson => lesson.visibility === "published").length),
            0
        ): 0
        
        if(lessonCount == 0){
            Swal.fire({
                title:'Sorry!',
                text:'This course currently has no lessons.',
                icon :'warning',
                timer: 3000
                
              }
            )
        }
        else{
            setStartCourseData(course)
        }
       
    };

     const handleShowCourseItem =  (e,course) => {
      
        const lessonCount = typeof course.category != "undefined" ? course.category.reduce((totalPublishedLessons, category) =>
        totalPublishedLessons + (category.lessons && category.lessons.filter(lesson => lesson.visibility === "published").length),
            0
        ): 0
        
        if(lessonCount == 0){
            Swal.fire({
                title:'Sorry!',
                text:'This course currently has no lessons.',
                icon :'warning',
                timer: 3000
                
              }
            )
        }
        else{
            setStartCourseData(course)
        }
       
    };

    const handleBackToTpcCourses = (e) => {
        e.preventDefault()
        setStartCourseData({})
    }

    const [subscribe, setSubscribe] = useState('lock');
    const [ultimateSubscribe, setUltimateSubscribe] = useState('lock');
    const fetchSubscriptionDetails = () => {
        // if(props.userDetails.ultimateAdvisingStudent == true || props.userDetails.ultimateAdvisingStudent == "true" || studentsLifetimePlan.includes(props.userDetails.email)){
        if(props.userDetails.ultimateAdvisingStudent == true || props.userDetails.ultimateAdvisingStudent == "true"){
            // setSubscribe('unlock')
            setUltimateSubscribe('unlock')
        }
        else{   
            if(props.fetchDetailsSubscription.fetchdetailssubscription.length){

                const subsDetails = props.fetchDetailsSubscription.fetchdetailssubscription[0]

                if(subsDetails.status == "active" || subsDetails.status == "trialing"){
                    setSubscribe('unlock')
                }
                else{

                    const date = new Date();
                    const secondsSinceEpoch = Math.floor(date.getTime() / 1000);
                    
                    if(subsDetails.cancel_at_period_end){
                        if(secondsSinceEpoch > subsDetails.canceled_at){
                            setSubscribe('lock')
                        }
                        else{
                            setSubscribe('unlock')
                        }
                    }
                    else{
                        setSubscribe('lock')
                    }
                
                }

            

            }
            else{
                setSubscribe('lock')
            }
        }
            
    }
    useEffect(() => {

        fetchSubscriptionDetails()
    
    }, [props.fetchDetailsSubscription])


    const [searchCourse, setSearchCourse] = useState("");
    const [searchedCourse, setSearchedCourse] = useState([]);

    const searchCourses = async (value) => {
        let search = await arraySearch(tpcCoursesLists, value);
        setSearchedCourse(search);
        setSearchCourse(value);
    };
    const arraySearch = (array, keyword) => {
        const searchTerm = keyword.toLowerCase();
        return array.filter((value) => {
            return (
                value.courseDescription.toLowerCase().match(new RegExp(searchTerm, "g")) ||
                value.courseTitle.toLowerCase().match(new RegExp(searchTerm, "g")) ||
                value.instructorName.toLowerCase().match(new RegExp(searchTerm, "g"))
            );
        });
    };


    const [currentPage, setCurrentPage] = useState(1);
	const [coursesPerPage] = useState(5);

    const indexOfLastPost = currentPage * coursesPerPage;
	const indexOfFirstPost = indexOfLastPost - coursesPerPage;
	const currentCourses = tpcCoursesLists.slice(indexOfFirstPost, indexOfLastPost);

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const previousPage = () => {
		if (currentPage !== 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	const nextPage = () => {
		if (currentPage !== Math.ceil(tpcCoursesLists.length / coursesPerPage)) {
			setCurrentPage(currentPage + 1);
		}
	};

    

    return (
        <>
            
             <div className="header bg-gradient-info pb-5 pt-5 pt-md-8">
                <Container className="" fluid>
                    <Row>
                        <Col lg="8" md="11">
                        {Object.keys(startCourseData).length !== 0 ?<>
                            <h1 className="display-2 text-white mb-4 lh-100 font-weight-bolder">{startCourseData.courseTitle}</h1>
                            
                            <Button className="shadow" color="default" onClick={(e) => handleBackToTpcCourses(e)}><i className="fas fa-arrow-left"></i>&nbsp;&nbsp;Back to TPC Courses</Button>
                            </>
                         : <h1 className="display-2 text-white mb-4 lh-100 font-weight-bolder">TPC Courses</h1> }
                        </Col>
                    </Row>
                </Container>
            </div>
            {Object.keys(startCourseData).length === 0? <>
            
            
            
           
            <Container className="mt--3" fluid>
                <Row >
                    <Col lg="8">
                        <FormGroup>
                            <InputGroup className="mb-4 input-group-lg shadow">
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fas fa-search" />
                                </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    placeholder="Search.."
                                    type="text"
                                    className=''
                                    value={searchCourse}
                                    onChange={(e) => searchCourses(e.target.value)}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                  
                </Row>
               
                <Row>
                    <Col lg="8" md="12" className='mt-3'>
                    { 
                        searchCourse ? 
                            searchedCourse.length ? 
                            <>
                                <>
                                {
                                    searchedCourse.map((c, i) => {
                                        return (<CourseItemList key={c.tpcCourseId} item={c} courseCallback={handleCourseItemCallback} />)
                                    })
                                }
                                </>
                                
                                <div >
                                    {
                                    searchedCourse.length > coursesPerPage ?
                                        <div className="d-flex justify-content-center mt-3">
                                            <Paginate
                                                itemPerPage={coursesPerPage}
                                                totalItems={searchedCourse.length}
                                                currentPage={currentPage}
                                                paginate={paginate}
                                                previousPage={previousPage}
                                                nextPage={nextPage}
                                            />
                                        </div>
                                    : ""

                                    }
                                    
                                </div>
                            </>

                                
                                
                            : ("No Result")  
                        : 
                         <>
                        
                            { !tpcCoursesLists.length ? 
                                <Card className='shadow'>
                                    <CardBody className='text-center'>
                                        <h2 className='mb-0'>No courses listed.</h2>
                                    </CardBody>
                                </Card>
                                :
                                <Row>   
                                    {

                                        currentCourses.map((c, i) => (
                                        
                                            <CourseItemList key={c.tpcCourseId} item={c} courseCallback={handleCourseItemCallback} />
                                            
                                            
                                        ))
                                    }
                                    <div >
                                    {
                                        tpcCoursesLists.length > coursesPerPage ?
                                        <div className="d-flex justify-content-center mt-3">
                                            <Paginate
                                                itemPerPage={coursesPerPage}
                                                totalItems={tpcCoursesLists.length}
                                                currentPage={currentPage}
                                                paginate={paginate}
                                                previousPage={previousPage}
                                                nextPage={nextPage}
                                            />
                                        </div>
                                        :""
                                    }
                                    </div>
                                </Row>
                            } 
                       
                      
                        

                        </>
                      
                        
                            
                            
                              
                            

                        // tpcCoursesLists.map((c, i) => {
                        //     return (
                        //         <Col lg="6" xl="4" md="12" key={c.tpcCourseId}  className='mb-3 d-flex justify-content-center'>
                        //             <Card className='shadow '>
                        //                 {c.courseThumbnail != "" ? 
                                        
                        //                     <CardImg
                        //                         alt="..."
                        //                         src={`https://storage.googleapis.com/tpcdashboard.appspot.com/img-tpc-courses/` + c.courseThumbnail+``}
                                                
                        //                         top
                        //                         style={{height : "15em", objectFit: "fill", width:"100%"}}
                        //                     />
                                            
                        //                     :

                        //                     <CardImg
                        //                         alt="..."
                                           
                        //                         src={require("assets/img/brand/no-image.jpg")}
                        //                         top
                        //                         style={{height : "15em", objectFit: "fill", width:"100%"}}
                        //                     />

                        //                 }
                        //                 <CardBody>
                        //                     <CardTitle className='text-truncate text-center mb-0'><h2 className='mb-0 text-default font-weight-bolder'>{c.courseTitle}</h2></CardTitle>
                        //                     <div className='text-center mt-3'>
                        //                         <div className=' d-flex justify-content-center align-items-center'>
                        //                             <Media className="align-items-center">
                        //                                 {c.instructorHeadshot ? <div className="card-profile-div-sm card-profile-div-md">
                        //                                     <div className="card-profile-image">
                                                            
                        //                                         <div className="profile-picture-sm profile-letter-md profile-picture-md" style={{backgroundImage: `url(https://storage.googleapis.com/tpcdashboard.appspot.com/img-tpc-courses/` + c.instructorHeadshot+`)`}}></div>
                                                            
                        //                                     </div>
                        //                                 </div> :
                        //                                 <div className="card-profile-image ">
                        //                                     {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                                                            
                        //                                     <div className="rounded-circle profile-letter-sm-primary profile-picture-md">{c.instructorName.trim()[0]}</div>
                        //                                     {/* </a> */}
                        //                                 </div>}
                        //                                 &nbsp;&nbsp;&nbsp;&nbsp;
                        //                                 <Media className="ml-2 d-block text-left">
                        //                                     {/* <span className="mb-0 text-sm font-weight-bold">
                        //                                     {c.instructorName}
                        //                                     </span> */}
                        //                                     <p className='mb-0 text-primary'>Instructor</p>
                        //                                     <h4 className='text-default font-weight-bolder'>{c.instructorName}</h4>
                        //                                 </Media>
                        //                             </Media>
                        //                         </div>
                        //                     </div>
                        //                     <hr className='mb-3 mt-3'></hr>
                        //                     <Row >
                        //                         <div className='col-4 d-flex justify-content-center align-items-center'>
                        //                             <div className='text-center'>
                                                    
                        //                             <label className="font-weight-bold mb-0 text-warning">{typeof c.category != "undefined" ? c.category.reduce((totalPublishedLessons, category) =>
                        //                                 totalPublishedLessons + (category.lessons && category.lessons.filter(lesson => lesson.visibility === "published").length),
                        //                                 0
                        //                             ) : "0"}</label>&nbsp;
                        //                             <h4 className='mb-0'>Lessons</h4>
                        //                             </div>
                        //                         </div>
                        //                         <div  className='text-center mb-2 col-8'>

                        //                             {c.members == "free" || ultimateSubscribe == "unlock" || subscribe == "unlock"?
                                                    
                        //                                 <Button
                        //                                     color="default"
                                                            
                        //                                     className='w-100  font-weight-bolder'
                        //                                     onClick={e => handleStartCourse(e, c)}
                        //                                     >
                        //                                     <i className="fas fa-play-circle"></i> &nbsp;Start Course
                        //                                 </Button>
                        //                             :
                        //                                 c.members == "ultimate" ? 

                        //                                 <Button
                        //                                     color="info"
                                                            
                        //                                     className={ props.adminUser.admin ? "w-100  font-weight-bolder disabled" : "w-100  font-weight-bolder"}
                        //                                     onClick={(e) => {e.preventDefault(); history.push('/client/upgrade-uas')}}
                        //                                     >
                        //                                     <i className="fas fa-lock text-yellow"></i> &nbsp;Ultimate
                        //                                 </Button>

                        //                                 :

                        //                                 <Button
                        //                                     color="info"
                                                            
                        //                                     className={ props.adminUser.admin ? "w-100  font-weight-bolder disabled" : "w-100  font-weight-bolder"}
                        //                                     onClick={(e) => {e.preventDefault(); history.push('/client/plans')}}
                        //                                     >
                        //                                     <i className="fas fa-lock text-yellow"></i> &nbsp;Pro
                        //                                 </Button>
                        //                             }
                        //                         </div>
                                                
                        //                     </Row>
                        //                 </CardBody>
                        //             </Card>
                        //         </Col>
                        //     )
                        // }) 

                   
                    }

                    </Col> 
                    <Col lg="4" md="12" className='mt-3'>
                        <Card className="shadow-lg align-center bg-white rounded mb-5">
                            <CardHeader>
                                <CardTitle className=" font-weight-bold">My Purchases </CardTitle>
                                <CardSubtitle className="">({ tpcPaymentRecord.length }) Items</CardSubtitle>
                            </CardHeader>
                            <CardBody>
                                {
                                    tpcPaymentRecord.length ? 
                                        tpcPaymentRecord.map((p,i) => {
                                            return( 
                                                <Card  role="button" className="m-2 shadow-lg bg-white rounded"  onClick={e => handleShowCourseItem(e, p.documentItem)}>
                                                    <div class="media">
                                                        <img 
                                                        style={{ width: '110px', height: '90px' }}
                                                        src={ p.documentItem.courseThumbnail != "" 
                                                        ?  `https://storage.googleapis.com/tpcdashboard.appspot.com/img-tpc-courses/` + p.documentItem.courseThumbnail+`` 
                                                        : require("assets/img/brand/no-image.jpg")} className="mr-3" alt="..." 
                                                        />
                                                        <div className="media-body p-2">
                                                            <h5 className="m-1 ">{p.documentItem.courseTitle}</h5>
                                                            <Badge color="success" className="mr-2" pill >Purchased</Badge>
                                                            <Badge color="info" className="mr-2 text-dark" pill >${ addZeroes(Number(p.documentItem.coursePrice))}</Badge>
                                                        </div>
                                                    </div>
                                                </Card>
                                            )
                                        
                                        })
                                    : "No purchased yet."
                                }
                                
                            </CardBody>
                        </Card>
                    </Col>
                   
                </Row>
                
            </Container>
            </>
            : <Container className="mt-4" fluid><TpcCoursesLessons courseData={startCourseData} ></TpcCoursesLessons></Container>}
            
        
        </>
    )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        fetchTpcCourses : state.fetchTpcCourses,
        createTpcCourse : state.createTpcCourse,
        updateTpcCourse : state.updateTpcCourse,
        deleteTpcCourse : state.deleteTpcCourse,
        fetchDetailsSubscription : state.fetchDetailsSubscription,
        fetchPaymentRecord: state.fetchPaymentRecord,
        adminUser : state.adminUser
    }
      
}

export default connect(mapStateToProps)(TpcCourses)
