/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media
} from "reactstrap";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { studentsLifetimePlan } from "../../util/DataList";
import { useDispatch } from "react-redux";
import { logoutUser } from "redux/UserAuthentication/LoginActions";
const AdminNavbar = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [profilePictureFilename, setProfilePictureFilename] = useState('')
  const [name, setName] = useState('');
  const [isStudent, setStudent] = useState(true)
  const [isUAS, setUAS] = useState(true)

  useEffect(() => {
		if(typeof props.userDetails.profilePicture != 'undefined'){
			setProfilePictureFilename(props.userDetails.profilePicture)
		}
    console.log(props.authenticated)
	}, [])

	useEffect(() => {
		if(props.createProfilePicture.profilepicture.length){
			setProfilePictureFilename(props.createProfilePicture.profilepicture[0].profilePicture);
		}
	}, [props.createProfilePicture])

  useEffect(() => {
		if(typeof props.userDetails.name != "undefined"){
			setName(props.userDetails.name);
			// setProfilePictureFilename(props.userDetails.profilePicture)
		}
    

    
    if(props.userDetails.coach === true || props.userDetails.coach == "true" ){
      setStudent(false)
  
    }
    if(props.userDetails.admin === true || props.userDetails.admin == "true" ){
      setStudent(false)

    }

    if(props.userDetails.ultimateAdvisingStudent !== undefined){
      if(props.userDetails.ultimateAdvisingStudent == "true" || props.userDetails.ultimateAdvisingStudent === true || studentsLifetimePlan.includes(props.userDetails.email)){
        setUAS(true)

          
      }else{
        setUAS(false)

      }
    }
   

	}, [props.userDetails])

  const logoutSubmit = (e) =>{
    e.preventDefault();
    dispatch(logoutUser());
  }

  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container className="px-4">
          <NavbarBrand to="/" tag={Link}>
            <img
              alt="..."
              src={require("../../assets/img/brand/tpc-premed-logo.png")}
            />
          </NavbarBrand>
          

          {props.authenticated === false? 
            <>
              <button className="navbar-toggler" id="navbar-collapse-main">
                <span className="navbar-toggler-icon" />
              </button>
              
              
              <UncontrolledCollapse navbar toggler="#navbar-collapse-main"> 
                <div className="navbar-collapse-header d-md-none">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("../../assets/img/brand/tpc-premed-logo-white.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar-collapse-main">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="ml-auto" navbar>
                  {/* <NavItem>
                    <NavLink className="nav-link-icon" to="/" tag={Link}>
                      <i className="ni ni-planet" />
                      <span className="nav-link-inner--text">Dashboard</span>
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      to="/auth/register"
                      tag={Link}
                    >
                      <i className="ni ni-circle-08" />
                      <span className="nav-link-inner--text">Register</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="nav-link-icon" to="/auth/login" tag={Link}>
                      <i className="ni ni-key-25" />
                      <span className="nav-link-inner--text">Login</span>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      to="/admin/user-profile"
                      tag={Link}
                    >
                      <i className="ni ni-single-02" />
                      <span className="nav-link-inner--text">Profile</span>
                    </NavLink>
                  </NavItem> */}

                  


                </Nav>
              </UncontrolledCollapse>
            </>
          :
          <UncontrolledDropdown nav>
              <DropdownToggle nav>
              <Media className="align-items-center">
                {profilePictureFilename ? 
                
                <div className="card-profile-div-sm">
                  <div className="card-profile-image">
                  
                    <div className="profile-picture-sm" style={{backgroundImage: `url(https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/` + profilePictureFilename+`)`}}></div>
                  
                  </div>
                </div>
                
                :

                <div className="card-profile-image ">
                {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  
                  <div className="rounded-circle profile-letter-sm-primary ">{name.trim()[0]}</div>
                {/* </a> */}
                </div>}
              </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>

              {props.userDetails.admin == "true" ?
                <>
                  <DropdownItem to="/admin/dashboard" tag={Link}>
                    <i className="ni ni-tv-2" />
                    <span>TPC Tracker</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/my-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                </>
                :

                <>
                  <DropdownItem to="/client/dashboard" tag={Link}>
                    <i className="ni ni-tv-2" />
                    <span>TPC Tracker</span>
                  </DropdownItem>
                  <DropdownItem to="/client/my-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                </>
              }
              
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={(e) => logoutSubmit(e)}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
              </DropdownMenu>
          </UncontrolledDropdown>
          }
        </Container>
      </Navbar>
    </>
  );
};
const mapStateToProps = state  => {
  return {
      userDetails : state.loginAuth.userDetails,
      createProfilePicture : state.createProfilePicture,
      fetchDetailsSubscription : state.fetchDetailsSubscription,
      adminUser : state.adminUser,
      authenticated: state.loginAuth.authenticated,
  }
    
}


export default connect(mapStateToProps)(AdminNavbar);
